<template>
    <div class="pageContent">
        <detailsHeader
            v-bind:title="$t('seriesDetails')"
            v-bind:name="series ? series.name : ''"
            v-bind:nameSub="series ? series.seriesType.name : ''"
            v-bind:imagePath="series ? series.logo : null"
            v-bind:details="detailData"
            v-bind:moreDetails="moreDetailData"
            v-bind:waitFor="apiData.series"
            v-bind:waitForRetry="reloadSeries"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="danger"
                    v-bind:permission="permissions.declarations.delete"
                    v-b-modal.confirmDeleteDeclaration
                    v-if="hasSeriesDeclaration"
                >
                    {{ $t('deleteDeclaration') }}
                </actionButton>
                <actionButton
                    variant="warning"
                    v-bind:permission="permissions.series.publishStatus"
                    v-b-modal.modal-changePublishSeriesStatus
                >
                    {{ $t('changeStatus') }}
                </actionButton>
                <actionButton
                    variant="secondary"
                    v-bind:permission="permissions.series.disciplinaries.download"
                    v-on:click="downloadDisciplinary"
                    target="_blank"
                >
                    {{ $t('disciplinarySummary') }}
                </actionButton>
                <actionButton
                    variant="secondary"
                    v-bind:to="editSeriesLink()"
                >
                    {{ $t('edit') }}
                </actionButton>
            </template>
        </detailsHeader>

        <subNavigation v-bind:data-config="subnavigation" />

        <!-- series tournaments -->
        <list
            v-bind:items="orderedSeriesTournaments"
            v-bind:title="$t('tournamentsInThisSeries')"
            v-bind:waitFor="apiData.seriesTournaments"
            v-bind:waitForRetry="reloadSeriesTournaments"
            v-bind:hide="hideElement(1)"
        >
            <template v-slot:buttons>
                <actionButton
                    class="d-none d-lg-block"
                    variant="outline-primary"
                    v-bind:permission="permissions.series.tournaments.create"
                    v-b-modal.tournamentPick
                >
                    {{ $t('addTournament') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <tournamentRow
                    showSport showEventType showDateRange showDetail showRemove
                    isHeadline
                />
            </template>
            <template v-slot="{ item: tournament, role }">
                <tournamentRow
                    showSport showEventType showDateRange showDetail showRemove
                    v-bind:tournament="tournament"
                    v-bind:role="role"
                    v-bind:removePermission="permissions.series.tournaments.delete"
                    v-bind:removeHandler="removeSeriesTournament"
                />
            </template>
        </list>
        <!-- END series tournaments -->

        <!-- series teams -->
        <list
            v-bind:items="seriesTeams"
            v-bind:title="$t('seriesTeams')"
            v-bind:waitFor="apiData.series"
            v-bind:waitForRetry="reloadSeries"
            v-bind:hide="hideElement(2)"
        >
            <template v-slot:buttons>
                <actionButton
                    class="d-none d-lg-block"
                    variant="outline-primary"
                    :to="createSeriesTeamLink()"
                >
                    {{ $tc('addTeam', 1) }}
                </actionButton>
            </template>
            <template v-slot:header>
                <teamRow
                    showName showCoreTeamCheckbox showPoints showRemove showDetail
                    isHeadline
                />
            </template>
            <template v-slot="{ item: team, role }">
                <teamRow
                    showName showCoreTeamCheckbox showPoints showRemove showDetail
                    v-bind:team="team"
                    v-bind:role="role"
                    v-bind:removePermission="permissions.series.teams.delete"
                    detailLinkRoute="commonSeriesTeamDetail"
                    v-bind:detailLinkParams="{ dataSeriesId: series.id }"
                    v-bind:removeHandler="removeSeriesTeam"
                />
            </template>
        </list>
        <!-- END series teams -->

        <!-- standings -->
        <wrapper
            v-bind:title="$t('standings')"
            v-bind:waitFor="apiData.standings"
            v-bind:waitForRetry="reloadStandings"
            noPadding
            noShadow
            noLine
            v-bind:hide="hideElement(3)"
        >
            <template v-slot:buttons>
                <actionButton
                    variant="secondary"
                    v-bind:permission="permissions.series.standings.generate"
                    v-bind:loadingWhile="apiData.standingsGeneration"
                    loadingThrobber
                    v-on:click="generateStanding"
                >
                    {{ $t('generateStandings') }}
                </actionButton>
                <actionButton
                    class="d-none d-lg-block"
                    variant="outline-primary"
                    v-on:click="printSegment(3)"
                >
                    {{ $t('print') }}
                </actionButton>
            </template>
            <template v-slot>
                <b-table
                    v-bind:fields="seriesStandings.fields"
                    v-bind:items="seriesStandings.items"
                    class="wr-table mt-3"
                    responsive
                    hover
                >
                    <template #cell(medal)="data">
                        <img v-if="data.value" v-bind:src="data.value" class="wr-standing-medal-cell" />
                    </template>
                </b-table>
            </template>
        </wrapper>
        <!-- END standings -->

        <!-- impact players -->
        <list
            v-bind:items="impactPlayers"
            v-bind:title="$t('impactPlayers')"
            v-bind:waitFor="apiData.impactPlayers"
            v-bind:waitForRetry="reloadImpactPlayers"
            v-bind:hide="hideElement(4)"
        >
            <template v-slot:buttons>
                <actionButton
                    class="d-none d-lg-block"
                    variant="outline-primary"
                    v-on:click="printSegment(4)"
                >
                    {{ $t('print') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <playerRow
                    showPositionNumber showTeamCode showTackles showBreaks showOffloads showCarries showTotal
                    isHeadline
                />
            </template>
            <template v-slot="{ item: person, role }">
                <playerRow
                    showPositionNumber showTeamCode showTackles showBreaks showOffloads showCarries showTotal
                    v-bind:person="person"
                    v-bind:role="role"
                />
            </template>
        </list>
        <!-- END impact players -->

        <!-- series team evolution -->
        <list
            v-bind:items="seriesTeamEvolution"
            v-bind:title="$t('seriesTeamEvolution')"
            v-bind:waitFor="apiData.seriesTeamEvolution"
            v-bind:waitForRetry="reloadSeriesTeamEvolution"
            v-bind:hide="hideElement(5)"
        >
            <template v-slot:buttons>
                <actionButton
                    class="d-none d-lg-block"
                    variant="outline-primary"
                    v-on:click="printSegment(5)"
                >
                    {{ $t('print') }}
                </actionButton>
            </template>
            <template v-slot:header>
                <seriesTeamEvolutionRow
                    isHeadline
                />
            </template>
            <template v-slot="{ item: seriesTeamEvo }">
                <seriesTeamEvolutionRow
                    v-bind:seriesTeamEvo="seriesTeamEvo"
                />
            </template>
        </list>
        <!-- END series team evolution -->

        <!-- reports -->
        <wrapper v-bind:title="$tc('report', 2)" v-bind:hide="hideElement(6)">
            <simpleRow
                v-bind:text="$t('bestPerformersSeries')"
                v-bind:moveTo="toBestPerformers"
                openInNewTab
                showMove textLeft normalText
            />
        </wrapper>

        <changeSeriesPublishStatusModal
            id="modal-changePublishSeriesStatus"
            v-bind:title="$t('changeSeriesStatus')"
            v-bind:publishStatus="series ? series.publishStatus : null"
            v-bind:confirmHandler="updateSeriesPublishStatus"
            v-on:confirmed="updateSeriesPublishStatusComplete"
        />

        <tournamentPickModal
            id="tournamentPick"
            multiselect
            v-bind:title="$t('searchSeriesTournaments')"
            v-bind:listNotices="pickerNotices"
            v-bind:filterDisplay="pickerFilterDisplay"
            v-bind:rowProps="pickerRowProps"
            v-bind:listHideItems="seriesTournaments"
            v-bind:selectHandler="addTournaments"
        />

        <confirmModal
            v-bind:id="'confirmDeleteDeclaration'"
            v-bind:text="$t('pleaseConfirmDeletion', { name: $t('seriesDeclaration') })"
            v-bind:data="declaration"
            v-bind:confirmHandler="deleteSeriesDeclarationHandler"
            v-on:confirmed="deleteSeriesDeclarationComplete"
        />
    </div>
</template>

<script>
import base from '@/views/base.vue';
import detailsHeader from '@/components/detailsHeader.vue';
import subNavigation from '@/components/subnavigation.vue';
import tournamentRow from '@/components/rows/tournament.vue';
import teamRow from '@/components/rows/team.vue';
import playerRow from '@/components/rows/player.vue';
import seriesTeamEvolutionRow from '@/components/rows/seriesTeamEvolution.vue'
import constants from '@/constants.js';
import changeSeriesPublishStatusModal from '@/components/modals/changePublishStatus.vue';
import tournamentPickModal from '@/components/modals/pick/tournament.vue';
import simpleRow from '@/components/rows/simple.vue';
import SeriesModel from '@/models/series.js';
import TournamentModel from '@/models/tournament.js';
import SeriesTeamModel from '@/models/seriesTeam.js';
import PlayerModel from '@/models/person/player.js';
import SeriesTeamEvolutionModel from '@/models/seriesTeamEvolution.js';
import SeriesDeclarationModel from '@/models/declaration/series.js';
import confirmModal from '@/components/modals/confirm.vue';

export default {
    name: 'commonSeriesDetail',
    extends: base,
    components: {
        detailsHeader,
        subNavigation,
        tournamentRow,
        teamRow,
        playerRow,
        seriesTeamEvolutionRow,
        changeSeriesPublishStatusModal,
        tournamentPickModal,
        confirmModal,
        simpleRow,
    },
    props: {
        dataSeriesId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        this.apiData = {
            series: this.loadSeries(),
        };

        return {
            pickerNotices: [
                this.$t('You can only choose sevens tournaments'),
            ],
            pickerFilterDisplay: {
                lastAppearance: true,
                dateFrom: false,
                dateUntil: false,
                team: false,
            },
            pickerRowProps: {
                showDetails: true,
            },
            headlineConfig: {
            },
            apiData: {
                series: this.apiData.series,
                seriesTournaments: this.loadSeriesTournaments(),
                seriesTeams: this.loadSeriesTeams(),
                standings: this.loadStandings(),
                standingsGeneration: null,
                impactPlayers: this.loadImpactPlayers(),
                seriesTeamEvolution: this.loadSeriesTeamEvolution(),
            },
            series: null,
            declaration: null,
            declarationTOP: null,
            subnavigation: {
                active: 0,
                elements: {
                    0: {
                        label: this.$t('all'),
                    },
                    1: {
                        label: this.$tc('tournament', 2),
                    },
                    2: {
                        label: this.$t('seriesTeams'),
                    },
                    3: {
                        label: this.$t('seriesStandings'),
                    },
                    4: {
                        label: this.$t('impactPlayers'),
                    },
                    5: {
                        label: this.$t('seriesTeamEvolution'),
                    },
                    6: {
                        label: this.$tc('report', 2),
                    }
                }
            },
            seriesTournaments: [],
            seriesTeams: [],
            seriesStandings: {
                fields: [],
                items: [],
            },
            impactPlayers: [],
            seriesTeamEvolution: [],
            goldMedalSVG: require('@/assets/img/gold-medal.svg'),
            silverMedalSVG: require('@/assets/img/silver-medal.svg'),
            bronzeMedalSVG: require('@/assets/img/bronze-medal.svg'),
        };
    },
    computed: {
        detailData(){
            if(!this.series){
                return [];
            }

            return [
                {
                    label: this.$tc('sport', 1),
                    content: this.series.sport,
                },
                {
                    label: this.$t('mainSeason'),
                    content: this.series.mainSeason,
                },
                {
                    label: this.$t('seriesStatus'),
                    content: this.series.publishStatus,
                },
            ];
        },
        moreDetailData(){
            if(!this.series){
                return [];
            }

            if(!this.declaration || (this.declaration && !this.declaration.id)){
                return [];
            }

            return [
                {
                    headline: this.$t('seriesDeclarationTopInformation'),
                    items: [],
                },
                {
                    headline: this.$t('squadAnnouncement'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOP?.squadDeadline || null,
                            type: 'date',
                        },
                        {
                            label: this.$t('minimumNumberOfPlayers'),
                            content: this.declarationTOP?.squadMinimumNumberOfPlayers || null,
                            type: 'number',
                        },
                        {
                            label: this.$t('maximumNumberOfPlayers'),
                            content: this.declarationTOP?.squadMaximumNumberOfPlayers || null,
                            type: 'number',
                        },
                        {
                            label: this.$t('minimumNumberOfManagers'),
                            content: this.declarationTOP?.squadMinimumNumberOfManagers || null,
                            type: 'number',
                        },
                        {
                            label: this.$t('maximumNumberOfManagers'),
                            content: this.declarationTOP?.squadMaximumNumberOfManagers || null,
                            type: 'number',
                        },
                        {
                            label: this.$t('minimumNumberOfMedics'),
                            content: this.declarationTOP?.squadMinimumNumberOfMedics || null,
                            type: 'number',
                        },
                        {
                            label: this.$t('maximumNumberOfMedics'),
                            content: this.declarationTOP?.squadMaximumNumberOfMedics || null,
                            type: 'number',
                        },
                    ],
                },
                {
                    headline: this.$t('personHeadshots'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOP?.squadPersonHeadshotsDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('passportInformation'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOP?.squadPassportInformationDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('medicalCertificates'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOP?.squadMedicalCertificatesDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('biography'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOP?.squadBioDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('participationAgreement'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOP?.participationAgreementDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('teamLogo'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOP?.teamLogoDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('nationalAnthem'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOP?.nationalAnthemDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('medicalInsuranceCover'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOP?.medicalInsuranceCoverDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('teamPhoneticGuide'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOP?.teamPhoneticGuideDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
                {
                    headline: this.$t('kitDeclaration'),
                    items: [
                        {
                            label: this.$t('deadlineToSubmit'),
                            content: this.declarationTOP?.kitDeadline || null,
                            type: 'date',
                        },
                    ],
                    singleColumn: true,
                },
            ];
        },
        orderedSeriesTournaments(){
            if (!this.seriesTournaments){
                return [];
            }

            return [...this.seriesTournaments].sort((a, b) => a.dateFrom - b.dateFrom);
        },
        hasSeriesDeclaration(){
            return this.declaration?.id;
        },
        toBestPerformers() {
            return {
                name: 'commonBestPerformersSeries',
                query: {
                    seriesEqId: this.series.eqId,
                }
            };
        }
    },
    methods: {
        updateSeriesPublishStatus(data) {
            return this.$api.call.tournament.publishStatusSeriesUpdate(this.dataSeriesId, { publishStatus: data });
        },
        updateSeriesPublishStatusComplete(){
            this.reloadSeries();
        },
        editSeriesLink(){
            return { name: 'commonSeriesEdit', params: { dataSeriesId: this.dataSeriesId } };
        },
        downloadDisciplinary(){
            if(this.seriesTournaments){
                const tournamentEqIds = this.seriesTournaments.map(tournament => tournament.eqId);

                this.$api.call.match.matchTimelineDisciplinaryExport({ tournamentEqIds: tournamentEqIds }, 'series-' + this.dataSeriesId + '-disciplinary-summary.csv');
            }
        },
        hideElement(index){
            return this.subnavigation.active !== 0 && this.subnavigation.active !== index;
        },

        loadSeries(){
            return this.$api.call.tournament.seriesGetById(this.dataSeriesId).then(data => {
                if (data !== undefined) {
                    this.series = new SeriesModel(data);
                }

                //reload stuff that require the tournament id or eqid so they can finally load
                this.reloadSeriesTournaments();
                this.reloadSeriesTeams();
                this.reloadStandings();
                this.reloadImpactPlayers();
                this.reloadSeriesTeamEvolution();

                return this.$api.call.termsOfParticipation.seriesDeclarationBySeriesEqId(this.series.eqId).catch(error => {
                    //if none found just use empty data
                    if(error.status === 404 || error.status === 403){
                        return {};
                    }
                    throw error;
                }).then(data => {
                    this.declaration = new SeriesDeclarationModel(data);
                    this.declarationTOP = this.declaration.getConfigurationFormModel();
                });
            });
        },
        reloadSeries(){
            this.apiData.series = this.loadSeries();
        },

        loadSeriesTournaments(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.series){
                return new Promise((resolve, reject) => this.apiData.series.catch(reject));
            }

            return this.$api.call.tournament.seriesTournamentsGetBySeriesId(this.dataSeriesId).then(result => {
                this.seriesTournaments = ((result || {}).tournaments || result || []).map(data => new TournamentModel(data));
            });
        },
        reloadSeriesTournaments(){
            this.apiData.seriesTournaments = this.loadSeriesTournaments();
        },
        removeSeriesTournament(seriesTournament) {
            return this.$api.call.tournament.seriesTournamentDeleteBySeriesIdAndTournamentId(this.dataSeriesId, seriesTournament.id)
                .then(this.reloadSeriesTournaments);
        },
        addTournaments(tournaments){
            const tournamentEqIds = tournaments.map(tournament => tournament.eqId);

            return this.$api.call.tournament.seriesAddTournaments(this.dataSeriesId, { tournamentEqIds: tournamentEqIds }).then(response => {
                if(response && response.status && response.status === 400) {
                    // handling the error
                    if (this.$log) {
                        this.$log.warn('addTournaments 400: ', response);
                    }
                } else {
                    // reload the tournaments list
                    this.seriesTournaments = [];
                    this.seriesTeams = [];
                    this.reloadSeriesTournaments();
                }
            }).catch(err => {
                if(this.$log){
                    this.$log.warn(err);
                }
                throw Error(this.$t('requestError'));
            });
        },

        loadSeriesTeams(){
            if(!this.series) {
                return new Promise((resolve, reject) => this.apiData.series.catch(reject));
            }

            this.seriesTeams = this.series.teams.map(team => SeriesTeamModel.from(team));
        },
        reloadSeriesTeams(){
            this.apiData.seriesTeams = this.loadSeriesTeams();
        },
        removeSeriesTeam(seriesTeam){
            return this.$api.call.tournament.seriesTeamsDeleteById(seriesTeam.id)
                .then(response => {
                    this.seriesTeams = [];
                    this.seriesTournaments = [];
                    this.reloadSeries();
                });
        },
        createSeriesTeamLink(){
            return { name: 'commonSeriesTeamCreate', params: { dataSeriesId: this.dataSeriesId } };
        },

        loadStandings() {
            if(!this.series){
                return new Promise((resolve, reject) => this.apiData.series.catch(reject));
            }

            return this.$api.call.tournament.seriesStandingsTableBySeriesId(this.dataSeriesId).then(result => {
                const keyPrefix = 'col';

                let fields = result.columns.map((element, i) => ({
                    key: keyPrefix + i,
                    label: element,
                    class: 'text-center',
                }));

                let items = result.data.map(item => {
                    const res = {};
                    result.columns.forEach((type, i) => {
                        if(item && item[i] !== undefined){
                            res[keyPrefix + i] = item[i];
                            // also store the last column as seriesPoint for medal calculation
                            if (i === result.columns.length - 1) {
                                res.seriesPoint = item[i];
                            }
                        }
                    });
                    return res;
                });

                // Add medals to top 3 rated teams, trust that the BE guarantees the correct order
                // The data structure is setup so it can be BE driven in the future
                this.apiData.seriesTournaments.then(() => {
                    // TODO: verify which timezone is applied here
                    const now = new Date();
                    const lastTournament = this.orderedSeriesTournaments.slice(-1).pop();

                    // if the last tournament is still ongoing, don't add medals
                    // as the tournament is scoped to date, not time
                    // there will be a lag between the end of the tournament and the display of the medals
                    if (!lastTournament || lastTournament.dateUntil > now) {
                        return;
                    }

                    // don't add medals if any seriesPoint is missing to avoid confusion
                    if (items.some(item => item.seriesPoint === null || item.seriesPoint === undefined)) {
                        return;
                    }

                    fields.unshift({
                        key: 'medal',
                        label: '',
                        class: 'text-center',
                    });

                    const medals = [this.goldMedalSVG, this.silverMedalSVG, this.bronzeMedalSVG];
                    items.slice(0, 3).forEach((item, i) => {
                        item.medal = medals[i];
                    })
                });

                this.$set(this.seriesStandings, 'fields', fields);
                this.$set(this.seriesStandings, 'items', items);
            });
        },
        reloadStandings(){
            this.apiData.standings = this.loadStandings();
        },
        generateStanding(){
            this.apiData.standingsGeneration = this.$api.call.tournament.recalculateSeriesStanding(this.dataSeriesId);
            this.apiData.standingsGeneration.then(() => this.reloadStandings());
        },

        loadImpactPlayers(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.series){
                return new Promise((resolve, reject) => this.apiData.series.catch(reject));
            }

            return this.$api.call.match.seriesImpactPlayerBySeriesEqId(this.series.eqId).then(result => {
                this.impactPlayers = (result || []).map(data => new PlayerModel(data));
            });
        },
        reloadImpactPlayers(){
            this.apiData.impactPlayers = this.loadImpactPlayers();
        },

        loadSeriesTeamEvolution(){
            //request requires the tournament eqid, so until the tournament is loaded we use a promise that never resolves
            if(!this.series){
                return new Promise((resolve, reject) => this.apiData.series.catch(reject));
            }

            return this.$api.call.tournament.seriesTeamEvolutionBySeriesEqId(this.series.eqId).then(result => {
                this.seriesTeamEvolution = ((result || {}).teams || []).map(data => new SeriesTeamEvolutionModel(data));
            });
        },
        reloadSeriesTeamEvolution(){
            this.apiData.seriesTeamEvolution = this.loadSeriesTeamEvolution();
        },
        deleteSeriesDeclarationHandler(data){
            return this.$api.call.termsOfParticipation.declarationDeleteById(constants.declarationTypes.series, data?.id);
        },
        deleteSeriesDeclarationComplete(){
            this.declaration = null;
            this.declarationTOP = null;

            this.reloadSeries();
        },
    },
};
</script>

import Model from '@/models/model.js';

export class MatchStat extends Model {
    /**
     * stat name
     *
     * @type {string}
     */
    statName = '';
    /**
     * stat value
     *
     * @type {int}
     */
    statValue = 0;
    /**
     * year of match that stat occurred in
     *
     * @type {int}
     */
    statMatchYear = null;
    /**
     * venue of match that stat occurred in
     *
     * @type {string}
     */
    statMatchVenue = '';
    /**
     * city of match that stat occurred in
     *
     * @type {string}
     */
    statMatchCity = '';
    /**
     * winning score of match that stat occurred in
     *
     * @type {int}
     */
    winningScore = null;
    /**
     * losing score of match that stat occurred in
     *
     * @type {int}
     */
    losingScore = null;
    /**
     * tournament stage of match that stat occurred in
     *
     * @type {int}
     */
    tournamentStage = '';
    /**
     * tournament stage group (e.g pool number) of match that stat occurred in
     *
     * @type {int}
     */
    tournamentStageGroup = '';
    /**
     * EqId of winner of match that stat occurred in
     *
     * @type {string}
     */
    winningTeamEqId = null;
    /**
     * name of winner of match that stat occurred in
     *
     * @type {string}
     */
    winningTeamName = '';
    /**
     * losing team
     *
     * @type {string}
     */
    losingTeamName = '';
    /**
     *
     * @param {int}
     */
    scoreDiff = null;
    /**
     * match ID
     *
     * @param {int}
     */
    matchId = null;
    /**
     * kick off date for match
     *
     * @param {DateTime}
     */
    matchKickOff = null;

    constructor(data = {}) {
        super(data);

        if (data) {
            this.statName = data.statName || null;
            this.statValue = data.statValue ?? null;
            this.statMatchYear = data.statMatchYear || (data.matchKickOff ? new Date(data.matchKickOff).getFullYear() : null) || null
            this.statMatchVenue = (data.statMatchVenue || data.venue) || '';
            this.statMatchCity = (data.statMatchCity || data.city) || '';
            this.winningScore = (data.winningScore ?? data.winningTeamScore) ?? null;
            this.losingScore = (data.losingScore ?? data.losingTeamScore) ?? null;
            this.tournamentStage = data.tournamentStage || '';
            this.winningTeamEqId = data.winningTeamEqId || null;
            this.winningTeamName = data.winningTeamName || '';
            this.tournamentStageGroup = data.tournamentStageGroup || '';
            this.losingTeamName = data.losingTeamName || '';
            this.losingTeamEqId = data.losingTeamEqId || '';
            this.scoreDiff = data.scoreDiff ?? null;
            this.matchId = data.matchId ?? null;
            this.matchKickOff = data.matchKickOff ?? null;
        }
    }
}

<template>
    <b-table-simple hover small caption-top responsive>
        <b-thead>
            <b-tr>
                <b-th colspan="3"><div class="d-flex justify-content-center text-uppercase">{{ title }}</div></b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-bind:key="'loading'" v-if="loading">
                <b-td colspan="3" class="text-center">
                    <throbber class="align-middle mt-2" />
                </b-td>
            </b-tr>
            <b-tr v-bind:key="'noData'" v-else-if="noData">
                <b-td colspan="3" class="text-center">
                    {{ $t('noData') }}
                </b-td>
            </b-tr>
            <b-tr v-for="item in items" v-bind:key="`${item.value}-${item.playerName}`" v-else>
                <b-td>{{ item.playerName }}</b-td>
                <b-td>{{ item.value }}</b-td>
                <b-td>{{ item.country }}</b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
import { empty } from '@/utils/miscUtils.js';
import throbber from '@/components/throbber.vue';

export default {
    name: 'bestPerformersSeriesTable',
    components: {
        throbber
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        stats: {
            type: Array,
            required: true
        },
        statName: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        formatData() {
            const items = [];
            if (empty(this.stats)) {
                return items;
            }
            this.stats.forEach((stat) => {
                items.push({
                    playerName: stat.displayName,
                    value: stat[this.statName],
                    country: stat.teamCode
                })
            });
            return items;
        }
    },
    computed: {
        items() {
            return this.formatData();
        },
        noData() {
            return empty(this.stats);
        }
    },
}
</script>

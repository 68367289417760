<script>
import formComponent from '@/components/form.vue';
import PenaltyModel from '@/models/penalty.js';

export default {
    name: 'createRankingPenaltyForm',
    extends: formComponent,
    props: {
        model: {
            type: PenaltyModel,
        },
        subscriptionTypes: {
            type: Array,
            default(){
                return [];
            },
        },
        sport: {
            type: String,
            default: '',
        },
    },
    methods: {},
    computed: {
        elementsConfig() {
            return [
                this.createFormRow([
                    {
                        id: 'rankingTeamId',
                        type: 'vselectRankingTeam',
                        label: 'Team',
                        plaintext: this.readonly,
                        disabled: this.readonly,
                        customProperties: {
                            sport: this.sport
                        }
                    },
                    {
                        id: 'dateApplied',
                        type: 'date',
                        label: this.$t('penaltyStartDate'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'amount',
                        type: 'number',
                        label: this.$tc('penalty', 1),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'reason',
                        type: 'textarea',
                        label: this.$t('reason'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'isEnabled',
                        type: 'checkbox',
                        text: this.$t('active'),
                        label: this.$t('penaltyStatus'),
                        plaintext: this.readonly,
                        disabled: this.readonly,
                    },
                    {
                        id: 'currentRating',
                        type: 'text',
                        label: this.$t('currentRating'),
                        plaintext: true,
                        disabled: true,
                    },
                    {
                        id: 'newRating',
                        type: 'text',
                        label: this.$t('newRating'),
                        plaintext: true,
                        disabled: true,
                    },
                ]),
            ];
        },
        abortButtonLabel(){
            return null;
        },
        confirmButtonLabel(){
            return null;
        },
        teamSelectComponent() {
            switch(this.sport) {
                case 'WRU':
                    return 'vselectRankingTeamWomens';
                case 'MRU':
                default:
                    return 'vselectRankingTeamMens';
            }
        }
    },
};
</script>

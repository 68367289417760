import Model from '@/models/model.js';

/**
 * playing position model class
 */
export default class PositionModel extends Model {
    /**
     * grouping
     *
     * @type {string}
     */
    grouping = '';

    /**
     * is forward
     *
     * @type {boolean}
     */
    isForward = false;

    /**
     * is generic
     *
     * @type {boolean}
     */
    isGeneric = false;

    /**
     * is medical
     *
     * @type {boolean}
     */
    isMedical = false;

    /**
     * is replacement
     *
     * @type {bool}
     */
    isReplacement = false;

    /**
     * long name
     *
     * @type {string}
     */
    longName = '';

    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * number
     *
     * @type {string}
     */
    number = '';

    /**
     * order
     *
     * @type {int}
     */
    order = 0;

    /**
     * role id
     *
     * @type {int}
     */
    roleId = 0;

    /**
     * role type
     *
     * @type {string}
     */
    roleType = '';

    /**
     * short name
     *
     * @type {string}
     */
    shortName = '';

    /**
     * source eq id
     *
     * @type {string}
     */
    sourceEqId = '';

    /**
     * sport
     *
     * @type {string}
     */
    sport = '';

    /**
     * is active
     *
     * @type {boolean}
     */
    isActive = true;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.eqId = data.eqId || data.positionEqId || data.sourceEqId || '';
            this.id = data.id || 0;

            this.grouping = data.grouping || '';
            this.isForward = data.isForward || false;
            this.isGeneric = data.isGeneric || false;
            this.isMedical = data.isMedical || false;
            this.isReplacement = data.isReplacement || false;
            this.longName = data.longName || '';
            this.name = data.name || data.longName || data.positionName || '';
            this.number = data.number || '';
            this.order = data.order || 0;
            this.roleId = data.roleId || 0;
            this.roleType = data.roleType || '';
            this.shortName = data.shortName || '';
            this.sourceEqId = data.sourceEqId || '';
            this.sport = data.sport || '';
            this.isActive = (data.isActive === undefined ? true : data.isActive);
        }
    }

    getPositionName() {
        return this.longName || this.name || this.shortName || this.number || '';
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return (this.longName && this.number ? this.longName + ' (' + this.number + ')' : '') || this.longName || this.name;
    }

    toJSON(){
        return {
            id: this.id,
            name: this.name,
            sourceEqId: this.sourceEqId,
        };
    }
}

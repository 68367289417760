<template>
<div class="pageContent" v-bind:waitFor="apiData.tournament">
        <div class="d-flex flex-row justify-content-between">
            <div>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat mb-5 no-print"
                    v-bind:to="backToTournamentDetailsLink"
                >
                    <icon type="arrow-left" class="align-middle"/>
                    {{ $t('backToPreviousScreen') }}
                </actionButton>
            </div>
        </div>
        <simpleHeader v-bind:title="$t('bestPerformersInGame')" class="header">
            <template v-slot:title>
                <div class="d-flex flex-row w-100 justify-content-between">
                    <div>
                        <h1>{{ $t('bestPerformersInGame')}}</h1>
                    </div>
                    <div>
                        <actionButton variant="outline-primary" class="btn-flat no-print" v-on:click="printSegment()">
                            {{ $t('print') }}
                        </actionButton>
                    </div>
                </div>
            </template>
            <div class="w-100 pt-3 d-flex flex-column align-items-center">
                <div class="d-flex align-items-center gap-15">
                    <img
                        v-bind:src="logo"
                        v-bind:alt="tournament && tournament.name"
                        v-bind:height="logoNotProvided ? 40 : 80"
                    />
                    <h2>{{ tournament.name }}</h2>
                </div>
                <span v-if="tournament">{{ $fd(tournament.dateFrom) }} - {{ $fd(tournament.dateUntil) }}</span>
            </div>
        </simpleHeader>
        <div v-if="isLoading" class="w-100 d-flex justify-content-center mt-3">
            <b-spinner class="loading-spinner"  />
        </div>
        <div v-else-if="errorMessage" class="w-100 d-flex justify-content-center mt-3">
            <loadErrorSnippet v-bind:message="errorMessage" v-bind:trigger="reloadStatsData" />
        </div>
        <wrapper
            v-else
            v-bind:waitFor="apiData.stats"
            v-bind:waitForRetry="reloadStatsData"
            noPadding noLine noShadow waitSilent
        >
            <div class="d-flex justify-content-around mb-3">
                <span class="text-uppercase">
                    {{ $t('allTimePoints') }}: {{ totalStats.points }}
                </span>
                <span class="text-uppercase">
                    {{ $t('tries') }}: {{ totalStats.tries }}
                </span>
                <span class="text-uppercase">
                    {{ $t('goals') }}: {{ totalStats.conversions + totalStats.dropGoals }}
                </span>
                <span class="text-uppercase">
                    {{ $t('penaltyGoals') }}: {{ totalStats.penalties }}
                </span>
                <span class="text-uppercase">
                    {{ $t('dropGoals') }}: {{ totalStats.dropGoals }}
                </span>
            </div>
            <div class="page-row">
                <div class="report-table">
                    <bestPerformersInGameTable
                        v-bind:title="$t('allTimeMostPoints')"
                        v-bind:stats="allTimePointScorers"
                        statName="totalPoints"
                    />
                </div>
                <div class="report-table">
                    <bestPerformersInGameTable
                    v-bind:title="$t('mostPointsScoredSeries', { year: tournament.seriesMainSeason })"
                    v-bind:stats="seriesPointScorers"
                    statName="totalPoints"
                    isSeries
                />
                </div>
            </div>
            <div class="page-row">
                <div class="report-table">
                    <bestPerformersInGameTable
                        v-bind:title="$t('allTimeMostTries')"
                        v-bind:stats="allTimeTryScorers"
                        statName="numTries"
                    />
                </div>
                <div class="report-table">
                    <bestPerformersInGameTable
                        v-bind:title="$t('mostTriesScoredSeries', { year: tournament.seriesMainSeason })"
                        v-bind:stats="seriesTryScorers"
                        statName="numTries"
                        isSeries
                    />
                </div>
            </div>
            <div class="page-row page-break">
                <div class="report-table">
                    <bestPerformersInGameTeamTable
                        v-bind:title="$t('allTimeHighestAggregateScore')"
                        v-bind:stats="allTimeAggregateScores"
                        statName="aggregateScore"
                    />
                </div>
                <div class="report-table">
                    <bestPerformersInGameTeamTable
                        v-bind:title="$t('highestAggregateScoreSeries', { year: tournament.seriesMainSeason })"
                        v-bind:stats="seriesAggregateScores"
                        statName="aggregateScore"
                        isSeries
                    />
                </div>
            </div>
            <div class="page-row">
                <div class="report-table">
                    <bestPerformersInGameTeamTable
                        v-bind:title="$t('allTimeWidestVictoryMargin')"
                        v-bind:stats="allTimeMarginsOfVictory"
                        statName="scoreDiff"
                    />
                </div>
                <div class="report-table">
                    <bestPerformersInGameTeamTable
                        v-bind:title="$t('widestVictoryMarginSeries', { year: tournament.seriesMainSeason })"
                        v-bind:stats="seriesMarginsOfVictory"
                        statName="scoreDiff"
                        isSeries
                    />
                </div>
            </div>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
// Models
import Model from '@/models/model.js';
import TournamentModel from '@/models/tournament.js';
// Components
import icon from '@/components/icon.vue';
import simpleHeader from '@/components/simpleHeader.vue';
import wrapper from '@/components/wrapper.vue';
import bestPerformersInGameTable from '@/components/tables/bestPerformersInGameTable.vue';
import bestPerformersInGameTeamTable from '@/components/tables/bestPerformersInGameTeamTable.vue';
import BestPerformerPlayer from '@/models/bestPerformerPlayer';
import BestPerformerTeam from '@/models/bestPerformerTeam';
import loadErrorSnippet from '@/components/snippets/loadError.vue';
// Utils
import { formatDateTimeOffset } from '@/utils/dateUtils.js';
import { empty } from '@/utils/miscUtils';

export default {
    name: 'commonTournamentBestPerformersInGame',
    extends: base,
    components: {
        icon,
        simpleHeader,
        bestPerformersInGameTable,
        bestPerformersInGameTeamTable,
        wrapper,
        loadErrorSnippet
    },
    props: {
        dataTournamentId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data() {
        this.apiData = {
            tournament: this.loadTournament(),
        };
        return {
            apiData: {
                tournament: this.apiData.tournament,
                stats: this.loadStatsData(),
            },
            tournament: null,
            logo: null,
            allTimeTryScorers: [],
            allTimePointScorers: [],
            allTimeAggregateScores: [],
            allTimeMarginsOfVictory: [],
            seriesTryScorers: [],
            seriesPointScorers: [],
            seriesAggregateScores: [],
            seriesMarginsOfVictory: [],
            totalStats: {},
            statsLoaded: false,
            isLoading: false,
            errorMessage: null,
        }
    },
    methods: {
        loadTournament() {
            return this.$api.call.tournament.tournamentGetById(this.dataTournamentId, true).then(data => {
                this.tournament = new TournamentModel(data);
                this.logo = (!empty(this.tournament.logo)) ? Model._api.call.tournament.resolveStoragePath(this.tournament.logo) : require('@/assets/img/tournament.svg');
                this.reloadStatsData();
            });
        },
        loadStatsData() {
            if(!this.tournament){
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject));
            }
            this.isLoading = true;

            const endDateStr = formatDateTimeOffset(this.tournament.endDate.setHours(23, 59, 59), this.tournament.endDateTimeOffset);

            return this.$api.call.match.bestPerformersInGame(this.tournament.seriesTypeId, this.tournament.sport, endDateStr, this.tournament.seriesEqId)
                .then(res => {
                    if (empty(res)) {
                        return;
                    }
                    this.allTimeTryScorers = res.allTimeTryScorers.map((item) => new BestPerformerPlayer(item));
                    this.allTimePointScorers = res.allTimePointScorers ? res.allTimePointScorers.map((item) => new BestPerformerPlayer(item)) : [];
                    this.allTimeAggregateScores = res.allTimeTeamAggregateScores ? res.allTimeTeamAggregateScores.map((item) => new BestPerformerTeam(item)) : [];
                    this.allTimeMarginsOfVictory = res.allTimeTeamMarginsOfVictory ? res.allTimeTeamMarginsOfVictory.map((item) => new BestPerformerTeam(item)) : [];
                    this.seriesTryScorers = res.seriesTryScorers ? res.seriesTryScorers.map((item) => new BestPerformerPlayer(item)) : [];
                    this.seriesPointScorers = res.seriesPointScorers ? res.seriesPointScorers.map((item) => new BestPerformerPlayer(item)) : [];
                    this.seriesAggregateScores = res.seriesTeamAggregateScores ? res.seriesTeamAggregateScores.map((item) => new BestPerformerTeam(item)) : [];
                    this.seriesMarginsOfVictory = res.seriesTeamMarginsOfVictory ? res.seriesTeamMarginsOfVictory.map((item) => new BestPerformerTeam(item)) : [];
                    this.totalStats = res.totalStats;
                }).catch((error) => {
                    switch(error.status) {
                        case 404:
                            this.errorMessage = this.$t('noData');
                            break;
                        case 408:
                            this.errorMessage = 'Timed out fetching data'
                            break;
                        default:
                            this.errorMessage = 'Something went wrong loading data';
                            break;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        reloadStatsData() {
            this.apiData.stats = this.loadStatsData();
        },
        checkBreakpoint() {
            const mediaQuery1 = window.matchMedia('(max-width: 1100px)');
            const mediaQuery2 = window.matchMedia('(max-width: 650px)');
            this.isSmallOrBelow = mediaQuery1.matches;
            this.isVSmallOrBelow = mediaQuery2.matches;
        },
        logoNotProvided() {
            if (empty(this.tournamentData)) return true;
            return empty(this.tournamentData?.tournamentLogoUrl);
        }
    },
    computed: {
        backToTournamentDetailsLink() {
            return {
                name: this.detailLinkRoute || 'commonTournamentDetail',
                params: {
                    dataTournamentId: this.dataTournamentId,
                    ...this.detailLinkParams
                },
            }
        },
        noData() {
            /* eslint-disable operator-linebreak */
            return empty(this.allTimeTryScorers)
                && empty(this.allTimePointScorers)
                && empty(this.allTimeAggregateScores)
                && empty(this.allTimeMarginsOfVictory)
                && empty(this.seriesTryScorers)
                && empty(this.seriesPointScorers)
                && empty(this.seriesAggregateScores)
                && empty(this.seriesMarginsOfVictory);
            /* eslint-enable operator-linebreak */
        }
    },
    mounted() {
        this.checkBreakpoint();
        window.addEventListener('resize', this.checkBreakpoint);
        const style = document.createElement('style');
        style.innerHTML = `
            @media print {
                @page {
                    size: landscape;
                }
            }
        `;
        document.head.appendChild(style);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkBreakpoint);
        const styles = document.querySelectorAll('style');
        styles.forEach((s) => {
            if (s.innerHTML.includes('size: landscape')){
                s.remove();
            }
        })
    },
}
</script>

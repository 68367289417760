<template>
    <b-form-group
        v-bind:label="labelText"
        v-bind:label-for="elementId"
        v-bind:class="elementClasses"
        v-bind:label-class="labelClasses"
        v-bind:[labelPropMobile]="labelColsMobile"
        v-bind:[labelPropDesktop]="labelColsDesktop"
        v-bind:state="state"
    >
        <b-form-input
            v-model="value"
            v-bind:id="elementId"
            v-bind:type="config.type"
            v-bind:required="config.required || false"
            v-bind:placeholder="config.placeholder"
            v-bind:min="config.min"
            v-bind:max="config.max"
            v-bind:step="config.step"
            v-bind:plaintext="config.plaintext"
            v-bind:readonly="config.readonly || false"
            v-bind:disabled="config.disabled || false"
            v-bind:state="state"
        />
    </b-form-group>
</template>

<script>
import base from './base.vue';

/**
 * number input element component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'numberElement',
    extends: base,
    props: {
        customProperties: {
            returnNaN: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    },
    data(){
        return {
            value: null,
            defaultValue: null,
        };
    },
    computed: {

    },
    methods: {
        convertValueIn(){
            let value = parseFloat(this.value);

            if(isNaN(value)){
                if (this.customProperties?.returnNaN) {
                    return NaN;
                }
                return null;
            }

            //limit value to max if given
            if(this.config.max){
                value = Math.min(parseInt(this.config.max, 10), value);
                this.$nextTick(() => {
                    this.value = value;
                });
            }

            return value;
        },
        convertValueOut(){
            return this.convertValueIn();
        },
    },
};
</script>

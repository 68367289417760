<script>
import formComponent from '@/components/form.vue';

/**
 * component
 */
export default {
    name: 'apiResourceMethodForm',
    extends: formComponent,
    computed: {
        elementsConfig() {
            return [
                {
                    id: 'header-text',
                    type: 'slot',
                },
                this.createFormRow([
                    {
                        id: 'ApiResourceMethod',
                        type: 'vselectApiResourceMethod',
                        placeholder: this.$t('select'),
                        label: this.$tc('apiService', 1),
                        returnFullOptions: true,
                    },
                ], null, null, 1),
                this.createFormRow([
                    {
                        id: 'description',
                        type: 'text',
                        label: this.$t('description'),
                        plaintext: false,
                        disabled: true,
                    },
                ], null, null, 1),
            ];
        },
        initValues(){
            return Object.assign({}, this.values, {
                description: ((this.formValues && this.formValues.ApiResourceMethod) ? this.formValues.ApiResourceMethod.name : null),
            });
        },
        confirmButtonLabel(){
            return null;
        },
        abortButtonLabel(){
            return null;
        },
    },
};
</script>

import constants from '@/constants';
import MatchModel from '@/models/match';
import _TeamModel from '@/models/team';


/**
 * Given an array of matches, returns an object with the teams grouped by tournament EqId and stage.
 *
 * @param {MatchModel[]} matches - An array of matches.
 * @returns {Object.<string, Object.<string, Object.<string, MatchModel[]>>>>} - An object with keys of the tournament EqId, stage name, and group name.
 */
const tournamentTeamsGroupedByStage = (matches) => {
    const results = {};

    if (!Array.isArray(matches)) {
        throw new Error('tournamentMatchTeamGroupedByStage: matches must be an array');
    }

    if (!matches.every((m) => m instanceof MatchModel)) {
        throw new Error('tournamentMatchTeamGroupedByStage: matches must be an array of MatchModel instances');
    }

    for (const match of matches) {
        if (!Array.isArray(match.tournaments)) {
            continue;
        }

        for (const tournament of match.tournaments) {
            const { tournamentEqId, tournamentStageName, group } = tournament;

            results[tournamentEqId] ??= {};
            results[tournamentEqId][tournamentStageName] ??= {};
            results[tournamentEqId][tournamentStageName][group] ??= [];

            for (const team of [match.team1, match.team2]) {
                const groupTeams = results[tournamentEqId][tournamentStageName][group];
                const teamAlreadyInGroup = groupTeams.some((t) => t.eqId === team.eqId);

                if (!teamAlreadyInGroup) {
                    groupTeams.push(team);
                }
            }
        }
    }

    return results;
};


/**
 *
 * Given a list of matches and a tournament EqId, returns a list of pool objects, sorted by pool name.
 * Each pool object contains the pool name and an array of teams in that pool.
 *
 * @param {Array.<MatchModel>} matches - Array of MatchModel instances.
 * @param {string} tournamentEqId - The EqId of the tournament.
 * @returns {Array.<{poolName: string, teams: Array.<_TeamModel>}>}
 */
const tournamentTeamsInPool = (matches, tournamentEqId) => {
    if (typeof tournamentEqId !== 'string') {
        throw new Error('tournamentTeamsInPool: tournamentEqId must be a string');
    }

    const tournamentStageGroups = tournamentTeamsGroupedByStage(matches)[tournamentEqId] ?? {};
    const pools = tournamentStageGroups[constants.stageGroup.pool] ?? {};

    return Object.keys(pools).sort().map((poolName) => ({
        poolName,
        teams: tournamentStageGroups[constants.stageGroup.pool][poolName]
    }));
};

export default {
    tournamentTeamsInPool
};

import { empty } from '@/utils/miscUtils';
import BasicService from './basic.js';

/**
 * Api request service class
 */
export default class MatchService extends BasicService
{
    /**
     * api base url
     *
     * @type {string}
     */
    _apiUrl = '/match-service/api/';

    /**
     * base path parts
     *
     * @type {object}
     */
    _paths = {
        match: 'match',
        matchTeam: 'match-team',
        matchTimeline: 'match-timeline',
        matchTimelineDisciplinary: 'match-timeline-disciplinary',
        external: 'external'
    };

    /**
     * log service instance
     *
     * @type {Log}
     */
    log;

    // match

    /**
     *
     */
    matchGetById(id){
        return this.get([this._paths.match, id]);
    }

    /**
     *
     */
    matchGetByEqId(eqId){
        return this.get([this._paths.match, 'eq-id', eqId]);
    }

    /**
     *
     */
    matchGetAllDataById(id){
        return this.get([this._paths.match, 'all-data/', id]);
    }

    /**
     *
     */
    matchGetAll(){
        return this.get(this._paths.match);
    }

    /**
     *
     */
    matchCreate(data){
        return this.post(this._paths.match, data);
    }

    /**
     *
     */
    matchUpdateById(id, data){
        return this.put([this._paths.match, id], data);
    }

    /**
     *
     */
    matchDeleteById(id){
        return this.delete([this._paths.match, id]);
    }

    /**
     *
     */
    matchSwapTeamsById(id){
        return this.patch([this._paths.match, 'swap', id]);
    }

    /**
     *
     */
    matchSearch(query){
        return this.post([this._paths.match, 'search'], query);
    }

    /**
     *
     */
    matchGetTournaments(id){
        return this.get([this._paths.match, id, 'tournaments']);
    }

    matchGetTournamentsWithSeriesData(id) {
        return this.get([this._paths.match, id, 'tournaments-series']);
    }

    /**
     *
     */
    publishStatusMatchUpdate(id, data){
        return this.put([this._paths.match, id, 'publish-status'], data);
    }

    // match official

    /**
     *
     */
    matchOfficialGetById(id){
        return this.get(['match-official', id]);
    }

    /**
     *
     */
    matchOfficialGetAllByMatchId(id){
        return this.get(['match-official', 'all-by-matchid', id]);
    }

    /**
     *
     */
    matchOfficialsGetByMatchId(id){
        return this.get([this._paths.match, id, 'officials']);
    }

    /**
     *
     */
    matchOfficialCreate(data){
        return this.post(['match-official'], data);
    }

    /**
     *
     */
    matchOfficialUpdateByMatchId(id, data){
        return this.put(['match-official', id], data);
    }

    /**
     *
     */
    matchOfficialDeleteById(matchOfficialId){
        return this.delete(['match-official', matchOfficialId]);
    }

    /**
     *
     */
    matchOfficialCreateByPerson(data){
        return this.post(['match-official', 'by-person'], data);
    }

    // match report

    // head to head
    matchGetHeadToHead(matchId, team1EqId, team2EqId, seriesTypeId) {
        return this.get(['match', matchId, 'reports', 'head-to-head'], {
            team1EqId: team1EqId,
            team2EqId: team2EqId,
            seriesTypeId: seriesTypeId
        })
    }

    /**
     *
     */
    matchReportsStatsGetByMatchId(matchId){
        return this.get(['match', matchId, 'reports', 'stats']);
    }

    /**
     *
     */
    matchReportsTeamsheetsGetByMatchId(matchId){
        return this.get(['match', matchId, 'reports', 'teamsheet']);
    }

    matchReportsTournamentTeamsheetsGetByMatchId(matchId, tournamentEqId) {
        if (empty(matchId) || empty(tournamentEqId)) {
            return;
        }

        return this.get(['match', matchId, 'reports', 'tournament-teamsheet'], {
            tournamentEqId: tournamentEqId
        });
    }

    /**
     *
     */
    matchReportsTimelineGetByMatchId(matchId){
        return this.get(['match', matchId, 'reports', 'timeline']);
    }

    // match team person

    /**
     *
     */
    matchUpdateMatchTeamPersonRepresenting(id, data){
        return this.put(['match-team-person', id, 'representing'], data);
    }

    /**
     *
     */
    matchTeamPersonGetById(matchTeamPersonId){
        return this.get(['lookups', 'MatchTeamPerson', 'byId', matchTeamPersonId]);
    }

    // match team player

    /**
     *
     */
    matchTeamPlayerCreateByMatchId(id, data){
        return this.post(['match-team-player', id], data);
    }

    /**
     *
     */
    matchTeamPlayerUpdateByMatchId(id, data){
        return this.patch(['match-team-player', id], data);
    }

    /**
     *
     */
    matchTeamPlayerDeleteById(id){
        return this.delete(['match-team-player', id]);
    }

    /**
     *
     */
    matchTeamPlayerGetById(matchTeamPlayerId){
        return this.get(['lookups', 'MatchTeamPlayer', 'byId', matchTeamPlayerId]);
    }

    /**
     *
     */
    matchTeamPlayerGetByMatchTeamId(id){
        return this.get([this._paths.matchTeam, id, 'persons', 'player']);
    }

    // match team management

    /**
     *
     */
    matchTeamManagmentCreateByMatchId(id, data){
        return this.post(['match-team-management', id], data);
    }

    /**
     *
     */
    matchTeamManagmentUpdateByMatchId(id, data){
        return this.put(['match-team-management', id], data);
    }

    /**
     *
     */
    matchTeamManagmentDeleteById(id){
        return this.delete(['match-team-management', id]);
    }

    /**
     *
     */
    matchTeamManagmentGetById(id){
        return this.get(['match-team-management', id]);
    }

    /**
     *
     */
    matchTeamManagmentGetByRole(matchTeamId, roleType){
        return this.get([this._paths.matchTeam, matchTeamId, 'persons', roleType]);
    }

    // match team

    /**
     *
     */
    matchTeamGetByMatchIdAndTeamId(matchId, TeamId){
        return this.get([this._paths.matchTeam, matchId, TeamId]);
    }

    /**
     *
     */
    matchTeamApproveKit(id, data){
        return this.patch([this._paths.matchTeam, id], data);
    }

    /**
     *
     */
    matchTeamGetByMatchTeamId(matchTeamId){
        return this.get(['lookups', 'MatchTeam', 'byId', matchTeamId]);
    }

    /**
     *
     */
    publishStatusTeamSheetUpdate(matchId, data){
        return this.put([this._paths.matchTeam, matchId, 'teamsheet-publish-status'], data);
    }

    // match team kit

    /**
     *
     */
    matchTeamKitByMatchTeamEqId(matchTeamEqId){
        return this.get(['lookups', 'MatchTeamKit', 'byMatchTeamEqId', matchTeamEqId]);
    }

    /**
     *
     */
    matchTeamKitsByMatchTeamId(matchTeamId){
        return this.get([this._paths.matchTeam, matchTeamId, 'get-kit']);
    }

    /**
     *
     */
    matchTeamAllAvailableTournamentKitsGetByMatchTeamId(matchTeamId){
        return this.get([this._paths.matchTeam, matchTeamId, 'available-tournament-kits']);
    }

    /**
     *
     */
    addKitToMatchTeam(matchTeamId, data) {
        return this.put([this._paths.matchTeam, matchTeamId, 'kit'], data);
    }

    // match timeline

    /**
     *
     */
    matchTimelineCreate(data){
        return this.post([this._paths.matchTimeline], data);
    }

    /**
     *
     */
    matchTimelineGetAllByMatchId(id){
        return this.get([this._paths.matchTimeline, id]);
    }

    /**
     *
     */
    matchTimelineUpdateByMatchId(id, data){
        return this.put([this._paths.matchTimeline, id], data);
    }

    /**
     * (params tbc)
     */
    matchTimelineGetAllBy(){
        return this.post([this._paths.matchTimeline]);
    }

    // match timeline disciplinary

    /**
     *
     */
    matchTimelineDisciplinaryCreate(matchTimelineId, data){
        return this.post([this._paths.matchTimelineDisciplinary, 'match-timeline', matchTimelineId], data);
    }

    /**
     *
     */
    matchTimelineDisciplinaryCreateWithTimeline(data){
        return this.post([this._paths.matchTimelineDisciplinary, 'match-timeline'], data);
    }

    /**
     *
     */
    matchTimelineDisciplinaryUpdate(matchTimelineId, data){
        return this.put([this._paths.matchTimelineDisciplinary, matchTimelineId], data);
    }

    /**
     *
     */
    matchTimelineDisciplinaryByMatchEqId(eqId){
        return this.get([this._paths.matchTimelineDisciplinary, 'match', eqId]);
    }

    /**
     *
     */
    matchTimelineDisciplinaryById(id){
        return this.get([this._paths.matchTimelineDisciplinary, id]);
    }

    /**
     *
     */
    matchTimelineDisciplinaryExport(data, fileName){
        return this.getFile([this._paths.matchTimelineDisciplinary, 'export'], fileName, data);
    }

    /**
     *
     */
    matchTimelineDisciplinaryByTournamentEqId(eqId){
        return this.get([this._paths.matchTimelineDisciplinary, 'tournament', eqId]);
    }

    // action type

    /**
     *
     */
    actionTypeGetAll(){
        return this.get('action-type');
    }

    /**
     *
     */
    actionTypeGetById(id){
        return this.get(['action-type', id]);
    }

    /**
     *
     */
    actionTypeCreate(id, data){
        return this.post(['action-type', id], data);
    }

    /**
     *
     */
    actionTypeUpdateById(id, data){
        return this.put(['action-type', id], data);
    }

    /**
     *
     */
    actionTypeDeleteById(id){
        return this.delete(['action-type', id]);
    }

    // action type set

    /**
     *
     */
    actionTypeSetGetAll(){
        return this.get('action-type-set');
    }

    /**
     *
     */
    actionTypeSetGetById(id){
        return this.get(['action-type-set', id]);
    }

    /**
     *
     */
    actionTypeSetCreate(id, data){
        return this.post(['action-type-set', id], data);
    }

    /**
     *
     */
    actionTypeSetUpdateById(id, data){
        return this.put(['action-type-set', id], data);
    }

    /**
     *
     */
    actionTypeSetDeleteById(id){
        return this.delete(['action-type-set', id]);
    }

    // user permission

    /**
     *
     */
    userPermissionCreate(id, data){
        return this.post(['user-permission', id], data);
    }

    /**
     *
     */
    userPermissionGetById(id){
        return this.get(['user-permission', id]);
    }

    /**
     *
     */
    userPermissionGetByUsername(username){
        return this.get(['user-permission', username]);
    }

    /**
     *
     */
    userPermissionUpdateById(id, data){
        return this.put(['user-permission', id], data);
    }

    /**
     *
     */
    userPermissionDeleteById(id){
        return this.delete(['user-permission', id]);
    }

    // Person role

    matchesGetByPersonRoleEqId(personRoleEqId) {
        return this.get(['person-role', personRoleEqId, 'matches']);
    }

    // tournament venues

    tournamentVenues(tournamentEqid){
        return this.get(['tournament', tournamentEqid, 'venues']);
    }

    // tournaments

    /**
     *
     */
    matchTournamentsGetByMatchId(id){
        return this.get([this._paths.match, id, 'tournaments']);
    }

    /**
     *
     */
    matchTournamentsGetByTournamentEqId(eqId) {
        const tournamentMatches = [];
        let searchRequest = {
            tournamentEqId: eqId,
            paging: {
                pageIndex: 0,
                pageSize: 100,
            },
        };

        return new Promise((resolve) => {
            (async () => {
                while (true) {
                    const response = await this.matchSearch(searchRequest);
                    if (!response?.data || response.data.length === 0) {
                        break;
                    }
                    tournamentMatches.push(...response.data);
                    if (response.data.length < searchRequest.paging.pageSize) {
                        break;
                    }
                    searchRequest.paging.pageIndex++;
                }
                resolve(tournamentMatches);
            })();
        });
    }

    matchTournamentGetOverlappingMatches(tournamentEqId) {
        return this.get([this._paths.external, 'tournament', tournamentEqId, 'matches', 'overlap']);
    }

    /**
     *
     */
    createMatchTournament(data){
        return this.post(['match-tournament'], data);
    }

    /**
     *
     */
    matchTournamentUpdateById(id, data){
        return this.put(['match-tournament', id], data);
    }

    /**
     *
     */
    deleteMatchTournamentByMatchTournamentId(matchTournamentId){
        return this.delete(['match-tournament', matchTournamentId]);
    }

    // statistics

    /**
     *
     */
    playerSummaryByPersonEqId(eqId, matchType){
        return this.get(['statistics', 'player-summary', eqId], {
            matchType,
            records: 100,
        });
    }

    /**
     *
     */
    playerTotalSummaryByPersonEqId(eqId, matchType){
        return this.get(['statistics', 'player-total', eqId], {
            matchType,
        });
    }

    /**
     *
     */
    playerSummaryBreakdownByPersonEqId(eqId, matchType){
        return this.get(['statistics', 'player-breakdown', eqId], {
            matchType,
            records: 100,
        });
    }

    teamStatisticsForSeries(seriesTypeIds = [], teamEqIds = [], sport = 'MRS', toTime = null) {
        return this.get(['match', 'reports', 'team-biographies'], {
            seriesTypeIds,
            teamEqIds,
            sport,
            toTime
        })
    }

    /**
     *
     */
    headCoachSummaryByPersonEqId(eqId){
        return this.get(['statistics', 'head-coach', eqId], {
            records: 100,
        });
    }

    bestPerformers(query){
        return this.post(['statistics', 'best-performers'], query);
    }

    bestPerformersSeries(seriesEqId, sport, pageSize = 15) {
        return this.get(['statistics', 'best-performers-for-series'], {
            seriesEqId: seriesEqId,
            sport: sport,
            pageSize: pageSize
        });
    }

    bestPerformersInGame(seriesTypeId, sport, endDate, seriesEqId) {
        return this.get(['statistics', 'best-performers-in-game'], {
            seriesTypeId: seriesTypeId,
            sport: sport,
            endDate: endDate,
            seriesEqId: seriesEqId
        })
    }

    // status

    /**
     *
     */
    matchStatusGetAll() {
        return this.get(['match-status']);
    }

    /**
     *
     */
    matchStatusGetById(id){
        return this.get(['match-status', id]);
    }

    // status group

    /**
     *
     */
    statusGroupGetAll(){
        return this.get(['status-group']);
    }

    /**
     *
     */
    statusGroupGetById(id){
        return this.get(['status-group', id]);
    }

    // match summary

    /**
     *
     */
    matchSummaryGetByMatchId(id){
        return this.get([this._paths.match, id, 'summary']);
    }

    // Series Impact Player

    /**
     *
     */
    seriesImpactPlayerBySeriesEqId(EqId){
        return this.get(['impact-players', 'series', EqId]);
    }

    // Tournament Impact Player

    /**
     *
     */
    tournamentImpactPlayerByTournamentEqId(EqId){
        return this.get(['impact-players', 'tournament', EqId]);
    }

    // Law

    /**
     *
     */
    lawGetAll(){
        return this.get(['law']);
    }

    /**
     *
     */
    lawGetById(id){
        return this.get(['law', id]);
    }
}

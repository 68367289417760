<template>
    <b-table-simple hover small caption-top responsive>
        <b-thead>
            <b-tr class="mt-auto mb-auto">
                <b-th class="text-center text-uppercase">{{ $tc('team', 2) }}</b-th>
                <b-th class="text-center">{{ $t('tries') }}</b-th>
                <b-th class="text-center">{{ $t('points') }}</b-th>
                <b-th class="text-center">{{ $t('conversions') }}</b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="item in items" v-bind:key="`${item.value}-${item.personEqId}`">
                <b-td>{{ item.team }}</b-td>
                <b-td class="text-center">{{ item.tries }}</b-td>
                <b-td class="text-center">{{ item.points }}</b-td>
                <b-td class="text-center">{{ item.conversions }}</b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
import { empty } from '@/utils/miscUtils.js';

export default {
    name: 'bestPerformersTeamTable',
    props: {
        stats: {
            type: Object,
            required: true
        }
    },
    data() {
        return {}
    },
    methods: {
        formatData() {
            if (empty(this.stats)) {
                return;
            }
            const items = [];
            Object.keys(this.stats).forEach((statKey) => {
                const stat = this.stats[statKey];
                items.push({
                    team: statKey,
                    tries: stat.tries,
                    points: stat.points,
                    conversions: stat.conversions,
                })
            });
            return items;
        }
    },
    computed: {
        items() {
            return this.formatData();
        }
    },
}
</script>

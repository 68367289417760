<template>
    <b-table
        class="m-0 match-report-table"
        thead-class="text-uppercase"
        v-bind:fields="tableFields"
        v-bind:tbody-tr-class="'teamsheet-row'"
        v-bind:items="items" responsive hover
    >
        <template #cell(number)="data">
            {{ data.item.number }}
        </template>
        <template #cell(name)="data">
            <span class="no-wrap">{{ data.item.name }}</span>
        </template>
        <template #cell(points)="data">
            {{ data.item.points ? data.item.points : '' }}
        </template>
        <template #cell(tries)="data">
            {{ data.item.tries ? data.item.tries : '' }}
        </template>
        <template #cell(conversions)="data">
            {{ data.item.conversions ? data.item.conversions : '' }}
        </template>
        <template #cell(penalties)="data">
            {{ data.item.penalties ? data.item.penalties : '' }}
        </template>
        <template #cell(dropGoals)="data">
            {{ data.item.penalties ? data.item.penalties : '' }}
        </template>
        <template #cell(cards)="data">
            <div class="card-div">
                <div class="centered-icon">
                    <icon v-bind:type="'card-yellow'" medium v-if="data.item.yellowCard" />
                </div>
                <div class="centered-icon">
                    <icon v-bind:type="'card-red'" medium v-if="data.item.redCard" />
                </div>
            </div>
        </template>
    </b-table>
</template>

<script>
import icon from '@/components/icon.vue';

export default {
    name: 'matchReportTable',
    components: {
        icon
    },
    props: {
        players: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'number',
                    label: '',
                    class: 'text-center',
                },
                {
                    key: 'name',
                    label: 'Name',
                    tdClass: 'match-teamsheet-name',
                },
                {
                    key: 'points',
                    label: 'Pts',
                    tdClass: 'text-center',
                    thClass: 'text-center'
                },
                {
                    key: 'tries',
                    label: 'Try',
                    tdClass: 'text-center',
                    thClass: 'text-center'
                },
                {
                    key: 'conversions',
                    label: 'Con',
                    tdClass: 'text-center',
                    thClass: 'text-center'
                },
                {
                    key: 'penalties',
                    label: 'PEN',
                    tdClass: 'text-center',
                    thClass: 'text-center'
                },
                {
                    key: 'dropGoals',
                    label: 'DG',
                    tdClass: 'text-center',
                    thClass: 'text-center'
                },
                {
                    key: 'cards',
                    label: 'CRD',
                    thClass: 'text-center',
                    tdClass: 'text-center'
                }
            ],
        }
    },
    methods: {
        formatTableData(players) {
            return players.map(p => ({
                number: parseInt(p.player.shirtNumber),
                name: this.formatName(p),
                points: p.statistics?.points ?? null,
                tries: p.statistics?.tries ?? null,
                conversions: p.statistics?.conversions ?? null,
                penalties: p.statistics?.penalties ?? null,
                dropGoals: p.statistics?.dropGoals ?? null,
                yellowCard: p.statistics?.yellowCards > 0,
                redCard: p.statistics?.redCards > 0,
            })).sort((a, b) => a.number - b.number);
        },
        formatName(person) {
            if (person.player.isCaptain) {
                return person.player.displayName + ' (C)';
            }

            return person.player.displayName;
        },
        getDisplayNumber(person) {
            if (person && (person.getPlayerAttribute('shirtNumber') || person.getPlayerAttribute('squadNumber'))) {
                const number = (person.getPlayerAttribute('shirtNumber') || person.getPlayerAttribute('squadNumber') || '');
                if (!isNaN(Number(number))) {
                    return parseInt(number);
                } else {
                    return number;
                }
            }

            if (person?.number) {
                return '' + person.number;
            }

            return '';
        },
    },
    computed: {
        items() {
            return this.formatTableData(this.players);
        }
    }
}
</script>

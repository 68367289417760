<script>
import vselectElement from '../vselect.vue';
import RankingUnionModel from '@/models/rankingUnion.js';

export default {
    name: 'vselectRankingTeamElement',
    extends: vselectElement,
    props: {
        customProperties: {
            sport: {
                type: String,
            }
        }
    },
    computed: {
        config() {
            const config = this.dataConfig || {};

            config.requestOptions = (text = '', offset = 0) => {
                if (offset > 0) {
                    return Promise.resolve(null);
                }
                return this.$api.call.rankings.rankingUnionGetBySport(this.customProperties.sport)
                    .then(result => {
                        const options = (result || []).map(data => new RankingUnionModel(data));
                        return options.map(option => ({ ...option, label: option.teamName }))
                    }
                    ).then(items => items.filter(item => !text || item.label.toLowerCase().includes(text.toLowerCase())));
            };

            config.requestInitOptions = (initValues, property) => {
                switch(property) {
                    case 'id':
                    default:
                        return Promise.all(initValues.map(id => this.$api.call.ranking.rankingUnionGetByTeamId(id)))
                            .then(results => results.map(data => new RankingUnionModel(data)));
                }
            };

            return config;
        }
    }
}
</script>

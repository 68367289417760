<template>
    <wrapper
        v-bind:title="title"
        v-bind:waitFor="waitFor"
        v-bind:waitForRetry="waitForRetry"
        class="detail-header"
        largeTitle
    >
        <template v-slot>
            <div v-bind:class="{ 'mb-0 mb-lg-3' : (moreDetails.length || $scopedSlots.more) }">
                <!--
                @slot Replace main content. Has all component props bound.
                -->
                <slot v-bind="$props">
                    <b-row
                        class="mt-5"
                    >
                        <b-col
                            cols="12"
                            lg="8"
                            v-bind:class="{
                                'mx-3 mx-lg-0 d-flex flex-row flex-nowrap align-items-center': true,
                                'mb-6 mb-lg-5': details.length,
                                'mb-3': !details.length,
                            }"
                        >
                            <div class="mr-5" v-if="imagePath">
                                <img
                                    v-bind:src="imagePath"
                                    v-bind:title="imageTitle || name"
                                    v-bind:alt="imageAlt"
                                    class="main-image mw-100"
                                />
                            </div>
                            <div class="flex-grow-1">
                                <h2 class="mb-0">{{ name }}</h2>
                                <div class="mt-2 text-uppercase font-heavy" v-if="nameSub">{{ nameSub }}</div>
                                <div class="mt-2 text-uppercase" v-if="nameSub2">{{ nameSub2 }}</div>
                            </div>
                        </b-col>
                        <b-col
                            cols="12"
                            lg="4"
                            class="d-flex flex-column justify-content-center"
                            v-if="details.length"
                        >
                            <div
                                v-for="(data, i) in details"
                                v-bind:key="i"
                                v-bind:class="itemClasses"
                            >
                                <label class="mr-4 mb-0 text-uppercase font-heavy">{{ data.label }}</label>
                                <span v-if="data.content === null || data.content === ''">{{ emptyString }}</span>
                                <span v-else-if="data.type === 'number'">{{ $n(data.content, 'nr') }}</span>
                                <span v-else-if="(data.type === 'date' || data.type === 'time' || data.type === 'dateTime') && data.offset !== undefined">{{ $fd(data.content, data.type) }} {{ formatDateOffset(data.offset) }}</span>
                                <span v-else-if="data.type === 'date' || data.type === 'time' || data.type === 'dateTime'">{{ $fd(data.content, data.type) }}</span>
                                <span v-else-if="data.type === 'boolean'">{{ data.content ? $t('yes') : $t('no') }}</span>
                                <countdown v-else-if="data.type === 'countdown'" v-bind:until="data.content" v-bind:offset="data.offset"/>
                                <span v-else>{{ data.content }}</span>
                            </div>
                        </b-col>
                    </b-row>
                    <div v-if="status || toBeDeletedOn">
                        <div v-if="!statusType">
                            <h2 class="h1">{{ status }}</h2>
                            <br>
                            <h2 v-if="toBeDeletedOn">{{ toBeDeletedOn }}</h2>
                        </div>
                        <div v-if="statusType && statusType === 'danger'">
                            <h2 class="h1 text-danger text-uppercase">{{ status }}</h2>
                            <br>
                            <h2 class="text-danger" v-if="toBeDeletedOn">{{ toBeDeletedOn }}</h2>
                        </div>
                        <div v-if="statusType && statusType === 'warning'">
                            <h2 class="h1 text-warning text-uppercase">{{ status }}</h2>
                            <br>
                            <h2 class="text-warning" v-if="toBeDeletedOn">{{ toBeDeletedOn }}</h2>
                        </div>
                    </div>
                </slot>
            </div>

            <b-collapse
                id="moreDetails"
                v-if="moreDetails.length || $scopedSlots.more"
            >
                <div
                    class="mb-0 mb-lg-3 mt-3 mt-lg-0"
                >
                    <!--
                    @slot Replace more content. Has all component props bound.
                    -->
                    <slot name="more" v-bind="$props">
                        <b-row>
                            <b-col
                                v-for="(data, i) in moreDetails"
                                v-bind:key="i"
                                cols="12"
                                v-bind:lg="data.singleColumn ? 4 : 12"
                            >
                                <h2 class="mt-3 mb-6 mx-3 mx-lg-0">{{ data.headline }}</h2>
                                <b-row
                                    cols="1"
                                    v-bind:cols-lg="data.singleColumn ? 1 : 3"
                                >
                                    <b-col
                                        v-for="(data, i) in (data.items || [])"
                                        v-bind:key="i"
                                        v-bind:class="itemClasses"
                                    >
                                        <label class="mb-3 text-uppercase font-heavy">{{ data.label }}</label>
                                        <div v-if="data.content === null || data.content === '' || data.content === 0">{{ emptyString }}</div>
                                        <div v-else-if="data.type === 'number'">{{ $n(data.content, 'nr') }}</div>
                                        <div v-else-if="(data.type === 'date' || data.type === 'time' || data.type === 'dateTime') && data.offset !== undefined">{{ $fd(data.content, data.type) }} {{ formatDateOffset(data.offset) }}</div>
                                        <div v-else-if="data.type === 'date' || data.type === 'time' || data.type === 'dateTime'">{{ $fd(data.content, data.type) }}</div>
                                        <div v-else-if="data.type === 'boolean'">{{ data.content ? $t('yes') : $t('no') }}</div>
                                        <div v-else-if="data.type === 'image'">
                                            <a v-bind:href="data.content" target="_blank" class="d-inline-block align-bottom">
                                                <img v-bind:src="data.content" class="sub-image d-block mw-100" alt="logo"/>
                                            </a>
                                        </div>
                                        <countdown v-else-if="data.type === 'countdown'" v-bind:until="data.content" v-bind:offset="data.offset"/>
                                        <div v-else>{{ data.content }}</div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </slot>
                </div>
            </b-collapse>

            <div class="moreDetailsToggle text-center text-lg-right mb-3 mx-3 mx-lg-0" v-if="moreDetails.length || $scopedSlots.more">
                <b-button v-b-toggle:moreDetails class="toggleButton" variant="outline-primary">
                    <span class="when-open">{{ $t('viewLess') }}</span>
                    <span class="when-closed">{{ $t('viewMore') }}</span>
                </b-button>
            </div>
        </template>
        <template v-slot:title="{ title }">
            <!--
            @slot Replace default module headline element. uses h1 by default.
            @binding {string} title Title prop value
            -->
            <slot name="title" v-bind:title="title">
                <h1>{{ title }}</h1>
            </slot>
        </template>
        <template v-slot:buttons v-if="$scopedSlots.buttons">
            <!--
            @slot Module buttons
            -->
            <slot name="buttons"/>
        </template>
    </wrapper>
</template>

<script>
import wrapper from '@/components/wrapper.vue';
import countdown from '@/components/countdown.vue';

export default {
    name: 'detailsHeader',
    components: {
        wrapper,
        countdown,
    },
    props: {
        /**
         * wrapper title
         */
        title: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * item name
         */
        name: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * item name sub text
         */
        nameSub: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * item name sub text 2
         */
        nameSub2: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * image path (src attribute)
         */
        imagePath: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * image title attribute
         */
        imageTitle: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * image alt attribute
         */
        imageAlt: {
            type: String,
            required: false,
            default: null,
        },
        status: {
            type: String,
            required: false,
            default: null,
        },
        statusType: {
            type: String,
            required: false,
            default: '',
        },
        toBeDeletedOn: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * main detail data
         * array items must be objects with "label" (string) and "content" (string)
         */
        details: {
            type: Array,
            required: false,
            default(){
                return [];
            },
        },
        /**
         * more detail data
         * array items must be objects with "headline" (string) and "items" (array), which must contain objects with "label" (string) and "content" (string)
         */
        moreDetails: {
            type: Array,
            required: false,
            default(){
                return [];
            },
        },
        /**
         * promise to wait for before showing top, default, and footer slot content
         */
        waitFor: {
            type: Promise,
            required: false,
            default: null,
        },
        /**
         * method to bind to click action on error msg retry button
         * if not defined retry button is not shown
         */
        waitForRetry: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data(){
        return {
            emptyString: '-----',
        };
    },
    computed: {
        itemClasses(){
            return 'mb-5 mx-3 mx-lg-0';
        },
    },
    methods: {
        formatDateOffset(offset){
            offset = parseInt(offset, 10) || 0;
            return (offset < 0 ? '-' : '+') + String(Math.floor(Math.abs(offset) / 60)).padStart(1, '0') + ':' + String(Math.abs(offset) % 60).padStart(2, '0');
        },
    },
}
</script>

<template>
    <b-table-simple small caption-top responsive>
        <b-thead>
            <b-tr>
                <b-th class="text-uppercase text-center">{{ $tc('sport', 1) }}</b-th>
                <b-th class="text-uppercase text-center">#</b-th>
                <b-th class="text-uppercase text-center">{{ $t('Match') }}</b-th>
                <b-th class="text-uppercase text-center">{{ $tc('team', 1) }} 1</b-th>
                <b-th class="text-uppercase text-center" colspan="3">{{ $t('score') }}</b-th>
                <b-th class="text-uppercase text-center">{{ $tc('team', 1)  }} 2</b-th>
                <b-th class="text-uppercase text-center">{{ $t('kickOff') }}</b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="item in items" v-bind:key="item.id" v-bind:class="calculateClasses(item)" >
                <b-td class="text-center">{{ item.sport }}</b-td>
                <b-td class="text-center">{{ item.number }}</b-td>
                <b-td class="text-center">{{ item.match }}</b-td>
                <b-td class="text-center">{{ item.team1 }}</b-td>
                <b-td class="text-center">{{ item.team1Score }}</b-td>
                <b-td class="text-center">-</b-td>
                <b-td class="text-center">{{ item.team2Score }}</b-td>
                <b-td class="text-center">{{ item.team2 }}</b-td>
                <b-td class="text-center">{{ $fd(item.kickOff, 'dateTime') }}</b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>

export default {
    name: 'tournamentMatchScheduleTable',
    props: {
        matches: {
            type: Array,
            required: true
        },
        highlightRows: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    methods: {
        formatTableData(matches) {
            const matchList = matches.map((data, i) => {
                const kickOffDate = new Date(data.kickOff);
                const isFuture = kickOffDate > new Date();
                return {
                    number: i + 1,
                    sport: data.sport,
                    match: `${data.tournaments[0]?.tournamentStageName} ${data.tournaments[0]?.group}`,
                    team1: data.teamOne?.name,
                    team2: data.teamTwo?.name,
                    team1Score: isFuture ? '' : data.teamOne?.score,
                    team2Score: isFuture ? '' : data.teamTwo?.score,
                    kickOff: data.kickOff,
                };
            });

            return matchList;
        },
        calculateClasses(item) {
            if (!this.highlightRows) {
                return '';
            }
            switch(item.sport) {
                case 'MRS':
                    return 'mens-row';
                case 'WRS':
                    return 'womens-row';
                default:
                    return '';
            }
        }
    },
    computed: {
        items() {
            return this.formatTableData(this.matches);
        }
    },
}
</script>

<template>
    <span class="card-base"
        v-bind:class="{ yellow: !isRedCard, red: isRedCard}"
    >
        <div class="card-content">
        <span
            class="card-text"
            v-bind:class="{ white: isRedCard, black: !isRedCard, padRight: ![2, 7].includes(quantity) }"
        >
            {{ quantity }}
        </span>
        </div>
    </span>
</template>

<script>
export default {
    name: 'penaltyCard',
    props: {
        quantity: {
            type: Number,
        },
        isRedCard: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.card-base {
    display: block;
    height: 20px;
    width: 15px;
    padding-top: 1px;
}

.card-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.yellow {
    background-color: #F1C564;
}

.red {
    background-color: #FF003D;
}

.card-text {
    font-size: 1rem;
}
.white {
    color: #ffffff;
}
.black {
    color: #000000;
}
.padRight {
    padding-right: 1px;
}
</style>

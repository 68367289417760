<template>
    <b-table-simple hover small caption-top responsive>
        <b-thead>
            <b-tr>
                <b-th colspan="7">
                    <div class="d-flex justify-content-center text-uppercase">{{ title }}</div>
                </b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="item in items" v-bind:key="`${item.value}-${item.personEqId}`">
                <b-td>{{ item.stat }}</b-td>
                <b-td class="text-nowrap">{{ item.teams }}</b-td>
                <b-td class="text-center text-nowrap">{{ item.score }}</b-td>
                <b-td class="text-nowrap">{{ item.tournamentStage }}</b-td>
                <b-td>{{ item.city }}</b-td>
                <b-td  v-if="!isSeries">{{ item.year }}</b-td>
                <b-td class="text-center text-nowrap">{{ item.date }}</b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
import { empty } from '@/utils/miscUtils.js';
import reportUtils from '@/utils/reportUtils';

export default {
    name: 'bestPerformersInGameTeamTable',
    props: {
        title: {
            type: String,
            required: true,
        },
        stats: {
            type: Array,
            required: true
        },
        statName: {
            type: String,
            required: true
        },
        isSeries: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    methods: {
        formatData() {
            if (empty(this.stats)) {
                return;
            }
            const items = [];
            this.stats.forEach((stat) => {
                items.push({
                    stat: stat[this.statName],
                    teams: reportUtils.formatTeams(stat.winningTeamName, stat.losingTeamName),
                    score: reportUtils.formatScore(stat.winningTeamScore, stat.losingTeamScore),
                    tournamentStage: reportUtils.formatTournamentStage(stat.tournamentStage, stat.tournamentStageGroup),
                    city: stat.locationName,
                    year: reportUtils.formatYear(stat.matchKickOff),
                    date: reportUtils.formatDate(stat.matchKickOff)
                })
            });
            return items;
        },
    },
    computed: {
        items() {
            return this.formatData();
        }
    },
}
</script>

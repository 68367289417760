<template>
<div class="pageContent">
        <div class="d-flex flex-row justify-content-between">
            <div>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat mb-5 no-print"
                    v-bind:to="backToTournamentDetailsLink"
                >
                    <icon type="arrow-left" class="align-middle"/>
                    {{ $t('backToPreviousScreen') }}
                </actionButton>
            </div>
        </div>
        <simpleHeader v-bind:title="$t('bestPerformers')">
            <template v-slot:title>
                <div class="d-flex flex-row w-100 justify-content-between">
                    <div>
                        <h1>{{ $t('bestPerformers')}}</h1>
                    </div>
                    <div>
                        <actionButton variant="outline-primary" class="btn-flat no-print" v-on:click="printSegment()">
                            {{ $t('print') }}
                        </actionButton>
                    </div>
                </div>
            </template>
            <div class="w-100 pt-3 d-flex flex-column align-items-center">
                <div class="d-flex align-items-center gap-15">
                    <img
                        v-bind:src="logo"
                        v-bind:alt="tournament.name"
                        v-bind:height="logoNotProvided ? 40 : 80"
                    />
                    <h2>{{ tournament.name }}</h2>
                </div>
                <span>{{ $fd(tournament.dateFrom) }} - {{ $fd(tournament.dateUntil) }}</span>
            </div>
        </simpleHeader>
        <wrapper v-bind:waitFor="apiData.stats" v-bind:waitForRetry="reloadStatsData"
            noPadding noLine noShadow waitSilent>
                <div class="d-flex flex-row gap-15">
                    <bestPerformersTable v-bind:title="$t('pointsScorers')" v-bind:stats="playerPoints" />
                    <bestPerformersTable v-bind:title="$t('tryScorers')" v-bind:stats="playerTries" />
                    <bestPerformersTable v-bind:title="$t('goalScorers')" v-bind:stats="playerConversions" />
                    <bestPerformersTeamTable v-bind:stats="teamStats" />
                </div>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import icon from '@/components/icon.vue';
import simpleHeader from '@/components/simpleHeader.vue';
import TournamentModel from '@/models/tournament.js';
import wrapper from '@/components/wrapper.vue';
import Model from '@/models/model.js';
import { empty } from '@/utils/miscUtils';
import bestPerformersTable from '@/components/tables/bestPerformersTable.vue';
import bestPerformersTeamTable from '@/components/tables/bestPerformersTeamTable.vue';

export default {
    name: 'commonTournamentBestPerformers',
    extends: base,
    components: {
        icon,
        simpleHeader,
        wrapper,
        bestPerformersTable,
        bestPerformersTeamTable
    },
    props: {
        dataTournamentId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data() {
        this.apiData = {
            tournament: this.loadTournament(),
            stats: this.loadStatsData(),
        };
        return {
            apiData: {
                tournament: this.apiData.tournament,
            },
            tournament: null,
            logo: null,
            playerTries: [],
            playerPoints: [],
            playerConversions: [],
            teamTries: [],
            teamPoints: [],
            teamConversions: [],
        }
    },
    methods: {
        loadTournament() {
            return this.$api.call.tournament.tournamentGetById(this.dataTournamentId).then(data => {
                this.tournament = new TournamentModel(data);
                this.logo = (!empty(this.tournament.logo)) ? Model._api.call.tournament.resolveStoragePath(this.tournament.logo) : require('@/assets/img/tournament.svg');
                this.reloadStatsData();
            });
        },
        loadStatsData() {
            if(!this.tournament){
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject));
            }
            const queryData = {
                sports: [
                    this.tournament.sport
                ],
                tournaments: [
                    this.tournament.eqId
                ],
                allMatches: true,
                paging: {
                    pageIndex: 0,
                    pageSize: 15
                },
                metrics: [
                    'Tries',
                    'Points',
                    'Conversions'
                ]
            }
            return this.$api.call.match.bestPerformers(queryData).then(res => {
                if (empty(res)) {
                    return;
                }
                const data = res.data[0];
                const playerData = data.players;
                playerData.forEach((p) => {
                    switch(p.statistic) {
                        case 'Tries':
                            this.playerTries = p.player;
                            break;
                        case 'Points':
                            this.playerPoints = p.player;
                            break;
                        case 'Conversions':
                            this.playerConversions = p.player;
                            break;
                        default:
                            break;
                    }
                });

                const teamData = data.teams;
                teamData.forEach((p) => {
                    switch(p.statistic) {
                        case 'Tries':
                            this.teamTries = p.team;
                            break;
                        case 'Points':
                            this.teamPoints = p.team;
                            break;
                        case 'Conversions':
                            this.teamConversions = p.team;
                            break;
                        default:
                            break;
                    }
                });
            })
        },
        reloadStatsData() {
            this.apiData.stats = this.loadStatsData();
        },
        checkBreakpoint() {
            const mediaQuery1 = window.matchMedia('(max-width: 1100px)');
            const mediaQuery2 = window.matchMedia('(max-width: 650px)');
            this.isSmallOrBelow = mediaQuery1.matches;
            this.isVSmallOrBelow = mediaQuery2.matches;
        },
        logoNotProvided() {
            if (empty(this.tournamentData)) return true;
            return empty(this.tournamentData?.tournamentLogoUrl);
        }
    },
    computed: {
        teamStats() {
            const teamData = {};
            this.teamPoints.forEach((tp) => {
                const teamName = tp.team.teamName;
                if (!teamData[teamName]) {
                    teamData[teamName] = { points: 0, tries: 0, conversions: 0 };
                }
                teamData[teamName].points = parseInt(tp.value);
            });

            this.teamTries.forEach((tt) => {
                const teamName = tt.team.teamName;
                if (!teamData[teamName]) {
                    teamData[teamName] = { points: 0, tries: 0, conversions: 0 };
                }
                teamData[teamName].tries = parseInt(tt.value);
            });

            this.teamTries.forEach((tc) => {
                const teamName = tc.team.teamName;
                if (!teamData[teamName]) {
                    teamData[teamName] = { points: 0, tries: 0, conversions: 0 };
                }
                teamData[teamName].conversions = parseInt(tc.value);
            });

            return teamData;
        },
        backToTournamentDetailsLink() {
            return {
                name: this.detailLinkRoute || 'commonTournamentDetail',
                params: Object.assign({
                    dataTournamentId: this.dataTournamentId,
                }, this.detailLinkParams),
            }
        },
    },
    mounted() {
        this.checkBreakpoint();
        window.addEventListener('resize', this.checkBreakpoint);
        const style = document.createElement('style');
        style.innerHTML = `
            @media print {
                @page {
                    size: landscape;
                }
            }
        `;
        document.head.appendChild(style);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkBreakpoint);
        const styles = document.querySelectorAll('style');
        styles.forEach((s) => {
            if (s.innerHTML.includes('size: landscape')){
                s.remove();
            }
        })
    },
}
</script>

<template>
    <b-table-simple hover small caption-top responsive>
        <b-thead>
            <b-tr class="mt-auto mb-auto">
                <b-th class="text-center text-uppercase">{{ $tc('team', 2) }}</b-th>
                <b-th class="text-center">{{ $t('tries') }}</b-th>
                <b-th class="text-center">{{ $t('points') }}</b-th>
                <b-th class="text-center">{{ $t('conversions') }}</b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-bind:key="'noData'" v-if="noData">
                <b-td colspan="4" class="text-center">
                    {{ $t('noData') }}
                </b-td>
            </b-tr>
            <b-tr v-for="item in items" v-bind:key="`${item.teamEqId}-${item.points}`" v-else>
                <b-td>{{ item.team }}</b-td>
                <b-td class="text-center">{{ item.tries }}</b-td>
                <b-td class="text-center">{{ item.points }}</b-td>
                <b-td class="text-center">{{ item.conversions }}</b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
import { empty } from '@/utils/miscUtils.js';

export default {
    name: 'bestPerformersSeriesTeamTable',
    props: {
        stats: {
            type: Array,
            required: true
        }
    },
    methods: {
        formatData() {
            if (empty(this.stats)) {
                return [];
            }

            return this.stats.map((stat) => ({ ...stat, team: stat.teamName }))
        }
    },
    computed: {
        items() {
            return this.formatData();
        },
        noData() {
            return empty(this.items);
        }
    },
}
</script>

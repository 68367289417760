import dateTime from 'date-and-time';


export const calculateAge = (dateOfBirth, comparisonDate = new Date()) => {
    if (!dateOfBirth) return null;

    const diff = new Date(comparisonDate.getTime() - dateOfBirth.getTime());
    return Math.abs(diff.getUTCFullYear() - 1970);
}

export const isUnderage = (dateOfBirth, threshhold = 19, comparisonDate = new Date()) => {
    if (!dateOfBirth) return null;

    const age = calculateAge(dateOfBirth, comparisonDate);

    return age < threshhold;
}

export const formatDate = (dateToFormat, dateFormat = 'DD/MM/YYYY') => {
    let date = dateToFormat;

    if (!(date instanceof Date)) {
        if (typeof date === 'string' || typeof date === 'number') {
            date = new Date(date);
        } else {
            return '';
        }
    }

    if (isNaN(date.getTime())) return '';

    return dateTime.format(date, dateFormat);
}

export const formatDateLong = (date) => {
    return formatDate(date, 'DD MMMM YYYY HH:mm');
}

export const formatDateShort = (date) => {
    return formatDate(date, 'DD MMMM YYYY');
}

/**
 * Formats a date and offset into an ISO 8601 compliant string.
 *
 * @param {Date} date - The date to format.
 * @param {number} offset - The timezone offset in minutes.
 * @returns {string} A string representing the formatted date with offset.
 */
export const formatDateTimeOffset = (date, offset) => {
    const dateStr = formatDate(date, 'YYYY-MM-DDTHH:mm:ss');
    const offsetStr = formatTimezoneOffset(offset);

    return `${dateStr}${offsetStr}`;
};

/**
 * Formats the timezone offset in minutes into a string like "+01:00".
 *
 * @param {number} offsetMins - The timezone offset in minutes. If not provided, defaults to 0.
 * @returns {string} The formatted offset string.
 */
export const formatTimezoneOffset = (offsetMins = 0) => {
    if (offsetMins === null || offsetMins === undefined || offsetMins === 0) {
        return '+00:00';
    }
    if (typeof offsetMins !== 'number') {
        throw TypeError('offsetMins must be a number');
    }

    const prefix = offsetMins >= 0 ? '+' : '-';

    const hours = Math.floor(Math.abs(offsetMins) / 60);
    const hoursStr = hours < 10 ? `0${hours}` : hours.toString();

    const mins = Math.abs(offsetMins % 60);
    const minsStr = mins < 10 ? `0${mins}` : mins.toString();

    return `${prefix}${hoursStr}:${minsStr}`;
};

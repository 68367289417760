<template>
    <wrapper
        v-bind:title="title"
        v-bind:waitFor="waitFor"
        v-bind:waitForRetry="waitForRetry"
        class="detail-header"
        largeTitle
    >
        <template v-slot:title="{ title }">
            <slot name="title" v-bind:title="title">
                <h1>{{ title }}</h1>
            </slot>
        </template>
        <slot />
    </wrapper>
</template>

<script>
import wrapper from '@/components/wrapper.vue';

export default {
    name: 'simpleHeader',
    components: {
        wrapper,
    },
    props: {
        /**
         * wrapper title
         */
        title: {
            type: String,
            required: false,
            default: null,
        },
    },
}
</script>

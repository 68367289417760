<template>
    <div class="w-100 wr-report team-bio-report">
        <b-table-simple hover small caption-top responsive>
            <b-thead>
                <b-tr>
                    <b-th class="table-top-header text-uppercase text-center" colspan="4">{{ team.teamName }}</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr>
                    <b-th class="table-section-header text-uppercase text-center" colspan="4">Challenger Series Record</b-th>
                </b-tr>
                <b-tr>
                    <b-td> Tournaments: {{ statistics.tournamentCount }} </b-td>
                    <b-td colspan="3"> Debut: {{ statistics.tournamentDebut }} </b-td>
                </b-tr>
                <b-tr>
                    <b-td> Played: {{ statistics.matchesPlayed }} </b-td>
                    <b-td> Won: {{ statistics.matchesWon }} </b-td>
                    <b-td> Lost: {{ statistics.matchesLost }} </b-td>
                    <b-td> Drawn: {{ statistics.matchesDrawn }} </b-td>
                </b-tr>
                <b-tr>
                    <b-td colspan="2"> Points: {{ statistics.pointsWon }} </b-td>
                    <b-td colspan="2"> Conceded: {{ statistics.pointsConceded }} </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple hover small caption-top responsive>
            <b-thead>
                <b-tr>
                    <b-th class="table-section-header text-uppercase text-center" colspan="3">From Over Last 10 Games</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr>
                    <b-td> Won: {{ statistics.last10MatchesWon }} </b-td>
                    <b-td> Lost: {{ statistics.last10MatchesLost }} </b-td>
                    <b-td> Drawn: {{ statistics.last10MatchesDrawn }} </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple hover small caption-top responsive>
            <b-thead>
                <b-tr>
                    <b-th class="table-section-header text-uppercase text-center" colspan="3">Best Streak</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr>
                    <b-td colspan="3" class="team-bio-streak">
                        <span class="streak-length"> {{ statistics.longestWinningStreak.length }} {{ statistics.longestWinningStreak.length > 1 ? 'wins' : 'win' }}</span>
                        <span class="streak-match"> {{ this.streakPeriod(statistics.longestWinningStreak) }} </span>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple hover small caption-top responsive>
            <b-thead>
                <b-tr>
                    <b-th class="table-section-header text-uppercase text-center" colspan="3">Worst Run</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr>
                    <b-td colspan="3" class="team-bio-streak">
                        <span class="streak-length"> {{ statistics.longestLosingStreak.length }} {{ statistics.longestLosingStreak.length > 1 ? 'losses' : 'loss' }}</span>
                        <span class="streak-match"> {{ this.streakPeriod(statistics.longestLosingStreak) }} </span>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple hover small caption-top responsive>
            <b-thead>
                <b-tr>
                    <b-th class="table-section-header text-uppercase text-center" colspan="4">Highest Score</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr>
                    <b-td colspan="4">{{ matchScoreDetails(statistics.matchHighestScore) }}</b-td>
                </b-tr>
                <b-tr>
                    <b-td colspan="4">{{ matchDetails(statistics.matchHighestScore) }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple hover small caption-top responsive>
            <b-thead>
                <b-tr>
                    <b-th class="table-section-header text-uppercase text-center" colspan="4">Widest Margin</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr>
                    <b-td colspan="4">{{ matchScoreDetails(statistics.matchWidestMargin) }}</b-td>
                </b-tr>
                <b-tr>
                    <b-td colspan="4">{{ matchDetails(statistics.matchWidestMargin) }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-table-simple hover small caption-top responsive>
            <b-thead>
                <b-tr>
                    <b-th class="table-section-header text-uppercase text-center" colspan="4">Heaviest Defeat</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr>
                    <b-td colspan="4">{{ matchScoreDetails(statistics.matchHeaviestDefeat) }}</b-td>
                </b-tr>
                <b-tr>
                    <b-td colspan="4">{{ matchDetails(statistics.matchHeaviestDefeat) }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
import constants from '@/constants';
import { formatDateShort } from '@/utils/dateUtils';

export default {
    name: 'teamBiographyTable',
    props: {
        team: {
            type: Object,
            required: true
        },
        statistics: {
            type: Object,
            required: true
        }
    },
    methods: {
        streakPeriod(streak) {
            if (streak.length === 0) return '';

            if (streak.length === 1) {
                return formatDateShort(new Date(streak[0].matchKickOff));
            }

            return formatDateShort(new Date(streak[0].matchKickOff)) + ` ${constants.enDash} ` + formatDateShort(new Date(streak[streak.length - 1].matchKickOff));
        },
        matchScoreDetails(match) {
            if (!match) return '';
            return `${match.baseTeamScore} ${constants.enDash} ${match.opponentScore} vs ${match.opponentName}`
        },
        matchDetails(match) {
            if (!match) return '';
            return `${match.matchDetails}`
        }
    }
}
</script>

import constants from '@/constants.js';

/**
 * @description Given an array of matches, calculate the team statistics for all teams involved in those matches.
 * The statistics include match counts, match win/loss/draw counts, tournament count & debut, and streaks.
 * The results are returned as an object where each key is the EqId of a team and the value is an object with the statistics.
 * @param {Object[]} matches An array of match objects.
 * @returns {Object} An object with team statistics.
 */
export const teamSeriesStatistics = (matches) => {
    if (!Array.isArray(matches)) {
        throw new Error('teamSeriesStatistics: matches must be an array');
    }

    if (matches.length === 0) {
        return {};
    }

    matches.sort((a, b) => a.matchKickOff.localeCompare(b.matchKickOff));

    const statistics = {};

    for (const match of matches) {
        const team1EqId = match.team1EqId;
        const team2EqId = match.team2EqId;
        const team1Data = getOrCreateTeamData(team1EqId, statistics);
        const team2Data = getOrCreateTeamData(team2EqId, statistics);

        const winningTeamEqId = match.winningTeamEqId;
        const losingTeamEqId = match.losingTeamEqId;

        // match counts
        team1Data.matchesPlayed += 1;
        team2Data.matchesPlayed += 1;

        team1Data._matches.push(representableMatch(match, team1EqId));
        team2Data._matches.push(representableMatch(match, team2EqId));

        // tournament count & debut
        trackTeamTournaments(match, statistics);

        // match win, loss, draw counts, margins, and streaks
        if (matchIsDraw(match)) {
            team1Data.matchesDrawn += 1;
            team2Data.matchesDrawn += 1;

            team1Data.pointsWon += match.winningTeamScore;
            team2Data.pointsWon += match.winningTeamScore;
        } else {
            trackMatchWinLossAndMargin(match, winningTeamEqId, losingTeamEqId, statistics);
            trackTeamStreaks(match, statistics);
        }

        // highest score
        trackHighestScore(match, team1EqId, statistics);
        trackHighestScore(match, team2EqId, statistics);
    }

    for (const team in statistics) {
        trackTeam10LatestMatches(team, statistics);

        // Remove transient data
        delete statistics[team]._currentWinStreak;
        delete statistics[team]._currentLossStreak;
        delete statistics[team]._matches;
    }

    return statistics;
};


/**
 * Increment the match win count and sum of points won for the winning team,
 * and increment the match loss count and sum of points conceded for the losing team.
 *
 * @param {Object} match - the match object with the winning team's score
 * @param {number} winningTeamEqId - the EqId of the winning team
 * @param {number} losingTeamEqId - the EqId of the losing team
 * @param {Object} statistics - the statistics object for all teams
 *
 * @returns {void}
 */
const trackMatchWinLossAndMargin = (match, winningTeamEqId, losingTeamEqId, statistics) => {
    if (matchIsDraw(match)) return;

    const winningTeamData = statistics[winningTeamEqId];
    const losingTeamData = statistics[losingTeamEqId];

    winningTeamData.matchesWon += 1;
    winningTeamData.pointsWon += match.winningTeamScore;
    if (!winningTeamData.matchWidestMargin || winningTeamData.matchWidestMargin.scoreDiff < match.scoreDiff) {
        winningTeamData.matchWidestMargin = representableMatch(match, winningTeamEqId);
    }

    losingTeamData.matchesLost += 1;
    losingTeamData.pointsConceded += match.winningTeamScore;
    if (!losingTeamData.matchHeaviestDefeat || losingTeamData.matchHeaviestDefeat.scoreDiff < match.scoreDiff) {
        losingTeamData.matchHeaviestDefeat = representableMatch(match, losingTeamEqId);
    }
};

/**
 * Store the highest score achieved by a team in their statistics, and update the match data with the
 * highest score if applicable.
 *
 * @param {Object} match - the match object with the scores
 * @param {number} baseTeamEqId - the EqId of the team
 * @param {Object} statistics - the statistics object for all teams
 *
 * @returns {void}
 */
const trackHighestScore = (match, baseTeamEqId, statistics) => {
    const baseTeamData = statistics[baseTeamEqId];
    const _representableMatch = representableMatch(match, baseTeamEqId);

    if (!baseTeamData.matchHighestScore || _representableMatch.baseTeamScore > baseTeamData.matchHighestScore.baseTeamScore) {
        baseTeamData.matchHighestScore = _representableMatch;
    }
};


/**
 * Increment the tournament count for the two teams in a given match
 * and stores the tournament title and start date in the team data.
 *
 * @param {Object} match - the match object with the tournament title and start date
 * @param {Object} statistics - the statistics object for all teams
 *
 * @returns {void}
 */
const trackTeamTournaments = (match, statistics) => {
    const {
        team1EqId,
        team2EqId,
        tournamentTitle,
        tournamentStartDate
    } = match;

    const tournamentDate = new Date(tournamentStartDate);
    const team1Data = statistics[team1EqId];
    const team2Data = statistics[team2EqId];

    team1Data._tournaments[tournamentTitle] = tournamentDate;
    team2Data._tournaments[tournamentTitle] = tournamentDate;

    // tournament count
    team1Data.tournamentCount = Object.keys(team1Data._tournaments).length;
    team2Data.tournamentCount = Object.keys(team2Data._tournaments).length;

    // tournament debut
    const getFirstTournament = (tournaments) => {
        // NOTE: [tournamentTitle, tournamentDate]
        const sortedTournaments = Object.entries(tournaments).sort((a, b) => a[1] - b[1]);
        const firstTournament = sortedTournaments[0];
        return firstTournament ? firstTournament[0] : null;
    };

    team1Data.tournamentDebut = getFirstTournament(team1Data._tournaments);
    team2Data.tournamentDebut = getFirstTournament(team2Data._tournaments);
};


/**
 * @description
 * Updates the longest winning and losing streaks for both teams
 * given the outcome of the current match.
 *
 * @param {Object} match - The match object
 * @param {Object} statistics - the statistics object for all teams
 *
 * @returns {void}
 */
const trackTeamStreaks = (match, statistics) => {
    const winningTeamEqId = match.winningTeamEqId;
    const losingTeamEqId = match.losingTeamEqId;

    // no ops if it's a draw
    if (matchIsDraw(match)) {
        return;
    }

    const winningTeamData = statistics[winningTeamEqId];
    const losingTeamData = statistics[losingTeamEqId];

    // Add the current match to the winning team's streak if it's a winning streak
    winningTeamData._currentWinStreak.push(match);
    winningTeamData._currentLossStreak = [];

    // Add the current match to the losing team's streak if it's a losing streak
    losingTeamData._currentLossStreak.push(match);
    losingTeamData._currentWinStreak = [];

    // Because matches are sorted by kick off time ascending, the current streaks are always the latest

    // Update the longest winning streak
    if (winningTeamData._currentWinStreak.length >= winningTeamData.longestWinningStreak.length) {
        winningTeamData.longestWinningStreak = [...winningTeamData._currentWinStreak];
    }
    // Update the longest losing streak
    if (losingTeamData._currentLossStreak.length >= losingTeamData.longestLosingStreak.length) {
        losingTeamData.longestLosingStreak = [...losingTeamData._currentLossStreak];
    }
};

/**
 * Updates the team data with the latest 10 matches' win/loss/draw counts.
 *
 * @param {string} teamEqId - the team EqId
 * @param {Object} statistics - the statistics object for all teams
 *
 * @returns {void}
 */
const trackTeam10LatestMatches = (teamEqId, statistics) => {
    const teamData = statistics[teamEqId];

    const last10Matches = teamData._matches.slice(-10);

    teamData.last10MatchesWon = last10Matches.filter(match => match.MatchResult === 'won').length;
    teamData.last10MatchesLost = last10Matches.filter(match => match.MatchResult === 'lost').length;
    teamData.last10MatchesDrawn = last10Matches.filter(match => match.MatchResult === 'drawn').length;
}


/**
 * Gets or creates the team data for a given team EqId.
 *
 * If the team data does not exist, it will be created with the default values.
 *
 * @param {string} teamEqId - the team EqId
 * @param {Object} statistics - the statistics object for all teams
 *
 * @returns {Object} the team data
 */
const getOrCreateTeamData = (teamEqId, statistics) => {
    if (!statistics[teamEqId]) {
        statistics[teamEqId] = {
            tournamentDebut: null,
            tournamentCount: 0,
            matchesPlayed: 0,
            matchesWon: 0,
            matchesLost: 0,
            matchesDrawn: 0,
            last10MatchesWon: 0,
            last10MatchesLost: 0,
            last10MatchesDrawn: 0,
            pointsWon: 0,
            pointsConceded: 0,
            matchHighestScore: null,
            matchWidestMargin: null,
            matchHeaviestDefeat: null,
            _tournaments: {},
            _matches: [],
            // streak
            longestWinningStreak: [],
            longestLosingStreak: [],
            _currentWinStreak: [],
            _currentLossStreak: []
        };
    }

    return statistics[teamEqId];
};


/**
 * @description
 * Given a match object and a base team EqId, returns a subset of the match
 * data that is relevant for the biography of the base team.
 *
 * @param {Object} match - the match object
 * @param {string} baseTeamEqId - the EqId of the base team
 *
 * @returns {Object} the subset of match data
 */
const representableMatch = (match, baseTeamEqId) => {
    const baseTeam = match.team1EqId === baseTeamEqId ? 'team1' : 'team2';
    const opponent = match.team1EqId === baseTeamEqId ? 'team2' : 'team1';

    const tournamentStageWithStageGroup = match.tournamentStageGroup
        ? `${match.tournamentStage} ${match.tournamentStageGroup}`
        : match.tournamentStage;

    const matchYear = new Date(match.matchKickOff).getFullYear();
    const matchDetails = `${matchYear} ${constants.enDash} ${match.city} ${constants.enDash} ${tournamentStageWithStageGroup}`;

    const matchResult = match.winningTeamEqId !== baseTeamEqId
        ? 'lost'
        : match.scoreDiff > 0 ? 'won' : 'drawn';

    return {
        matchId: match.matchId,
        baseTeamEqId: match[baseTeam + 'EqId'],
        baseTeamScore: match[baseTeam + 'Score'],
        opponentEqId: match[opponent + 'EqId'],
        opponentScore: match[opponent + 'Score'],
        opponentName: match[opponent + 'Name'],
        scoreDiff: match.scoreDiff,
        matchResult: matchResult,
        matchKickOff: match.matchKickOff,
        city: match.city,
        venue: match.venue,
        matchDetails: matchDetails,
    };
};

/**
 * @description
 * Checks if a match is a draw.
 *
 * @param {Object} match - the match object
 *
 * @returns {boolean} true if the match is a draw, false otherwise
 */
const matchIsDraw = (match) => {
    return match.scoreDiff === 0;
}

import Model from '@/models/model.js';

export class TeamStats extends Model {
    /**
     * team eqId
     *
     * @type {string}
     */
    teamEqId = null;
    /**
     * team name
     *
     * @type {string}
     */
    teamName = '';
    /**
     * team's number of wins
     *
     * @type {int}
     */
    wins = 0;
    /**
     * team's total number of points
     *
     * @type {int}
     */
    totalPoints = 0;
    /**
     * team's average number of points
     *
     * @type {int}
     */
    avgPoints = 0;
    /**
     * team's highest score
     *
     * @type {int}
     */
    highestScore = null;
    /**
     * team's best win margin
     *
     * @type {int}
     */
    bestWinMargin = null;

    constructor(data = {}) {
        super(data);
        if (data) {
            this.teamEqId = data.teamEqId || '';
            this.teamName = data.teamName || '';
            this.wins = data.wins || 0;
            this.totalPoints = data.totalPoints || 0;
            this.avgPoints = data.avgPoints || 0;
            this.highestScore = data.highestScore || null;
            this.bestWinMargin = data.bestWinMargin || null;
        }
    }
}

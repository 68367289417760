<template>
    <b-table-simple table-class="teamsheet-data-table" hover small caption-top responsive>
        <b-tbody>
            <b-tr class="teamsheet-row" v-for="(item, i) in items" v-bind:key="`${i}-${item.number}`">
                <td class="teamsheet-table">{{ item.number }}</td>
                <td class="teamsheet-table no-wrap auto-width">{{ item.name }}</td>
                <td class="teamsheet-table no-wrap auto-width">
                    <span v-bind:class="{'text-danger': item.isUnderage}">
                        {{ item.dateOfBirth }}
                    </span>
                </td>
                <td class="teamsheet-table">
                    <div class="center-icon">
                        <icon v-if="item.card" class="card-icon" v-bind:type="'card-' + item.card" medium />
                    </div>
                </td>
                <td class="teamsheet-table">
                    <div class="center-icon">
                        <icon
                            v-bind:type="isSub ? 'up-circle' : 'down-circle'"
                            v-bind:color="isSub ? 'success' : 'danger'"
                            class="replacement-icon"
                            medium
                            v-if="item.replacement"
                        />
                    </div>
                </td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
import icon from '@/components/icon.vue';
import { isUnderage, calculateAge } from '@/utils/dateUtils';

export default {
    name: 'teamsheetTable',
    components: {
        icon
    },
    props: {
        players: {
            type: Array,
            required: true,
        },
        isSub: {
            type: Boolean,
            required: false,
            default: false,
        },
        scorings: {
            type: Object,
            required: false
        },
        disciplinaries: {
            type: Object,
            required: false
        },
        matchKickOffDate: {
            type: Date,
            required: true
        }
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'number',
                    label: this.$t('lineup'),
                    class: 'text-center',
                },
                {
                    key: 'name',
                    label: '',
                    tdClass: 'match-teamsheet-name',
                },
                {
                    key: 'date-of-birth',
                    label: '',
                    tdClass: '',
                },
                {
                    key: 'captain',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
                {
                    key: 'scoring',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
                {
                    key: 'card',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
                {
                    key: 'replacement',
                    label: '',
                    tdClass: 'match-teamsheet-icon',
                },
            ],
        }
    },
    methods: {
        formatTableData(players, isSub = false) {
            const map = {};
            for (let player of players) {
                const number = parseInt(player.positionNumber) || this.getDisplayNumber(player);
                if (!number) {
                    continue;
                }

                map[number] = player;
            }
            let formattedPlayers = Object.entries(map).sort(([number1], [number2]) => number1 - number2).map(([key, player]) => player)
                .map((data) => {
                    return {
                        number: typeof data?.positionNumber === 'number' ? parseInt(data?.positionNumber) : this.getDisplayNumber(data),
                        name: this.formatName(data),
                        dateOfBirth: this.formatDateOfBirth(data),
                        isUnderage: isUnderage(data.dateOfBirth, 19, this.matchKickOffDate),
                        scoring: data?.eqId && this.scorings[data?.eqId],
                        card: ((data?.eqId && this.disciplinaries[data?.eqId]) ? this.disciplinaries[data?.eqId].cardType : null),
                        replacement: isSub ? data?.getPlayerAttribute('isUsedReplacement') : data.getPlayerAttribute('isReplaced'),
                    }
                });
            return formattedPlayers;
        },
        formatName(teamsheet) {
            if (!teamsheet) {
                return '';
            }

            let name = teamsheet.getName();

            if (teamsheet?.getPlayerAttribute('isCaptain')) {
                name += ' (C)';
            }

            return name;
        },
        formatDateOfBirth(person) {
            if (person.dateOfBirth == null) {
                return null;
            }
            const formattedDateOfBirth = this.$fd(person.dateOfBirth, 'date');
            const formattedAge = calculateAge(person.dateOfBirth, this.matchKickOffDate);

            return `${formattedDateOfBirth} (${formattedAge})`;
        },
        getDisplayNumber(person) {
            if (person && (person.getPlayerAttribute('shirtNumber') || person.getPlayerAttribute('squadNumber'))) {
                return '' + (person.getPlayerAttribute('shirtNumber') || person.getPlayerAttribute('squadNumber') || '');
            }

            if (person?.number) {
                return '' + person.number;
            }

            return '';
        },
    },
    computed: {
        items() {
            return this.formatTableData(this.players, this.isSub);
        }
    }
}
</script>

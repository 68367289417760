<template>
    <div class="pageContent">
        <simpleHeader v-bind:title="$t('bestPerformersSeries')">
            <template v-slot:title>
                <div class="d-flex flex-row w-100 justify-content-between">
                    <div>
                        <h1>{{ $t('bestPerformersSeries')}}</h1>
                    </div>
                    <div>
                        <actionButton variant="outline-primary" class="btn-flat no-print" v-on:click="printSegment()">
                            {{ $t('print') }}
                        </actionButton>
                    </div>
                </div>
            </template>
            <div class="w-100 pt-3 d-flex flex-column align-items-center">
                <div class="d-flex align-items-center gap-15">
                    <img
                        v-bind:src="seriesLogoUrl"
                        v-bind:alt="seriesName"
                        height="80"
                    />
                    <h2>{{ series.name }}</h2>
                </div>
            </div>
        </simpleHeader>
        <wrapper v-bind:waitFor="apiData.bestPerformers" v-bind:waitForRetry="reloadBestPerformers"
            noPadding noLine noShadow waitSilent>
            <div class="d-flex flex-row gap-15">
                    <bestPerformersSeriesTable v-bind:title="$t('pointsScorers')" v-bind:stats="playerPoints" v-bind:loading="isLoading" statName="points"/>
                    <bestPerformersSeriesTable v-bind:title="$t('tryScorers')" v-bind:stats="playerTries" v-bind:loading="isLoading" statName="tries" />
                    <bestPerformersSeriesTable v-bind:title="$t('goalScorers')" v-bind:stats="playerConversions" v-bind:loading="isLoading" statName="conversions" />
                    <bestPerformersSeriesTeamTable v-bind:stats="teamTries" />
                </div>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import wrapper from '@/components/wrapper.vue';
import simpleHeader from '@/components/simpleHeader.vue';
import { empty } from '@/utils/miscUtils';
import bestPerformersSeriesTable from '@/components/tables/bestPerformersSeriesTable.vue';
import bestPerformersSeriesTeamTable from '@/components/tables/bestPerformersSeriesTeamTable.vue';
import Model from '@/models/model.js';

export default {
    name: 'commonBestPerformersSeries',
    extends: base,
    components: {
        wrapper,
        simpleHeader,
        bestPerformersSeriesTable,
        bestPerformersSeriesTeamTable
    },

    data() {
        this.apiData = {
            series: this.loadSeries(),
            bestPerformers: this.loadBestPerformers(),
        }
        return {
            apiData: {
                series: this.apiData.series,
                bestPerformers: this.apiData.bestPerformers,
            },
            series: {},
            sport: '',
            playerTries: [],
            playerPoints: [],
            playerConversions: [],
            teamTries: [],
            teamPoints: [],
            teamConversions: [],
            isLoading: true,
        }
    },
    methods: {
        loadSeries() {
            return this.$api.call.tournament.seriesGetByEqId(this.$route.query.seriesEqId).then(data => {
                this.series = data;
                this.sport = data.sport;
                this.reloadBestPerformers();
            });
        },
        reloadSeries() {
            this.apiData.series = this.loadSeries();
        },
        loadBestPerformers() {
            const seriesEqId = this.$route.query.seriesEqId;
            const sport = this.sport;
            if (empty(seriesEqId) || empty(sport)) {
                return;
            }
            return this.$api.call.match.bestPerformersSeries(seriesEqId, sport).then(data => {
                if (empty(data)) {
                    return;
                }
                const playerData = data.playerData;
                this.playerTries = playerData.topTryScorers;
                this.playerPoints = playerData.topPointScorers;
                this.playerConversions = playerData.topConversionScorers;

                const teamData = data.teamData;
                this.teamTries = teamData.topTryScorers;
                this.teamPoints = teamData.topPointScorers;
                this.teamConversions = teamData.topConversionScorers;

                this.isLoading = false;
            });
        },
        reloadBestPerformers() {
            this.apiData.bestPerformers = this.loadBestPerformers();
        },
        checkBreakpoint() {
            const mediaQuery1 = window.matchMedia('(max-width: 1100px)');
            const mediaQuery2 = window.matchMedia('(max-width: 650px)');
            this.isSmallOrBelow = mediaQuery1.matches;
            this.isVSmallOrBelow = mediaQuery2.matches;
        },
    },
    computed: {
        seriesLogoUrl() {
            if (!empty(this.series.logoUrl)) {
                return Model._api.call.tournament.resolveStoragePath(this.series.logoUrl);
            }
            return null;
        },
    },
    mounted() {
        this.checkBreakpoint();
        window.addEventListener('resize', this.checkBreakpoint);
        const style = document.createElement('style');
        style.innerHTML = `
        @media print {
            @page {
                size: landscape;
            }
        }`;
        document.head.appendChild(style);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkBreakpoint);
        const styles = document.querySelectorAll('style');
        styles.forEach((s) => {
            if (s.innerHTML.includes('size: landscape')){
                s.remove();
            }
        })
    },
}
</script>

<template>
    <div class="pageContent">
        <div class="d-flex flex-row justify-content-between">
            <div>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat mb-5"
                    v-bind:to="backToTournamentDetailsLink"
                >
                    <icon type="arrow-left" class="align-middle"/> {{ $t('backToPreviousScreen') }}
                </actionButton>
            </div>
            <div>
            </div>
        </div>
        <simpleHeader v-bind:title="$t('matchSchedule')">
            <template v-slot:title>
                <div class="d-flex flex-row w-100 justify-content-between">
                    <div>
                        <h1>{{ $t('matchSchedule')}}</h1>
                    </div>
                    <div>
                        <actionButton variant="outline-primary" class="btn-flat" v-on:click="printSegment()">
                            {{ $t('print') }}
                        </actionButton>
                    </div>
                </div>
            </template>
            <div class="w-100 pt-3 d-flex flex-column align-items-center">
                <div class="d-flex align-items-center gap-15">
                    <img
                        v-bind:src="logo"
                        v-bind:alt="tournament.name"
                        v-bind:height="logoNotProvided ? 40 : 80"
                    />
                    <h2>{{ tournament.name }}</h2>
                </div>
                <span>{{ $fd(tournament.dateFrom) }} - {{ $fd(tournament.dateUntil) }}</span>
                <div class="w-100 pt-3 d-flex justify-content-start">
                    <b-form-checkbox v-model="highlightRows" name="check-button">Highlight Rows</b-form-checkbox>
                </div>
            </div>
        </simpleHeader>
        <wrapper v-bind:waitFor="apiData.tournamentMatches" v-bind:waitForRetry="reloadTournamentMatches"
            noPadding noLine noShadow waitSilent>
            <tournamentMatchScheduleTable v-bind:matches="tournamentMatches" v-bind:highlightRows="highlightRows" />
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import icon from '@/components/icon.vue';
import simpleHeader from '@/components/simpleHeader.vue';
import tournamentMatchScheduleTable from '@/components/tables/tournamentMatchScheduleTable.vue';
import TournamentModel from '@/models/tournament.js';
import MatchModel from '@/models/match.js';
import Model from '@/models/model.js';
import { empty } from '@/utils/miscUtils';


export default {
    name: 'commonTournamentScheduleCombinedDetail',
    extends: base,
    components: {
        simpleHeader,
        icon,
        tournamentMatchScheduleTable
    },
    props: {
        dataTournamentId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data() {
        this.apiData = {
            tournament: this.loadTournament(),
        };

        return {
            apiData: {
                tournament: this.apiData.tournament,
                tournamentMatches: this.loadTournamentMatches(),
            },
            tournament: null,
            tournamentMatches: [],
            logo: '',
            highlightRows: true,
        }
    },
    methods: {
        loadTournament() {
            return this.$api.call.tournament.tournamentGetById(this.dataTournamentId).then(data => {
                this.tournament = new TournamentModel(data);
                this.logo = (!empty(this.tournament.logo)) ? Model._api.call.tournament.resolveStoragePath(this.tournament.logo) : require('@/assets/img/tournament.svg');
                this.reloadTournamentMatches();
            });
        },
        reloadTournament() {
            this.apiData.tournament = this.loadTournament();
        },
        loadTournamentMatches() {
            if (!this.tournament) {
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject))
            }

            this.$api.call.match.matchTournamentGetOverlappingMatches(this.tournament.eqId).then(tournamentMatches => {
                const matches = tournamentMatches.map(tournamentMatch => new MatchModel(tournamentMatch, this.dataTournamentId));
                matches.map(match => { match.sport = match.tournaments[0].sport });
                this.tournamentMatches = matches;
            })
        },
        reloadTournamentMatches() {
            this.apiData.tournamentMatches = this.loadTournamentMatches();
        },
    },
    computed: {
        backToTournamentDetailsLink() {
            return {
                name: this.detailLinkRoute || 'commonTournamentDetail',
                params: Object.assign({
                    dataTournamentId: this.dataTournamentId,
                }, this.detailLinkParams),
            }
        },
        logoNotProvided() {
            if (empty(this.tournamentData)) return true;
            return empty(this.tournamentData?.tournamentLogoUrl);
        }
    }
}
</script>

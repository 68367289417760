<template>
    <modal
        v-bind="modalProps"
        v-on="modalEvents"
        v-bind:okTitle="$t('save')"
        v-bind:confirmHandler="confirmHandler"
        v-bind:confirmHandlerData="disciplinary"
        size="xl"
    >
        <addEditDisciplinaryForm
            v-bind:model="disciplinary"
            v-bind:matchReadonly="matchReadonly"
            v-bind:teamReadonly="teamReadonly"
            v-bind:citingCommissionerReadonly="citingCommissionerReadonly"
            v-bind:tournamentEqId="tournamentEqId"
            v-bind:errors="errorIds"
        />
    </modal>
</template>

<script>
import base from './base.vue';
import addEditDisciplinaryForm from '@/components/forms/addEditDisciplinary.vue';

import DisciplinaryModel from '@/models/disciplinary.js';

/**
 *
 */
export default {
    name: 'addEditDisciplinaryModal',
    extends: base,
    components: {
        addEditDisciplinaryForm,
    },
    props: {
        disciplinary: {
            type: DisciplinaryModel,
            default(){
                return new DisciplinaryModel();
            }
        },
        matchReadonly: {
            type: Boolean,
            default: false,
        },
        teamReadonly: {
            type: Boolean,
            default: false,
        },
        citingCommissionerReadonly: {
            type: Boolean,
            default: false,
        },
        matchId: {
            type: Number,
            default: 0,
        },
        tournamentEqId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            errors: {
                type: Array,
                required: false,
            }
        }
    }
};
</script>

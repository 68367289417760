import Model from '@/models/model.js';
import { empty } from '@/utils/miscUtils.js';

export class HeadToHead extends Model {
    /**
     * Last 5 matches between teams
     *
     * @type {Array}
     */
    last5Matches = [];
    /**
     * Number of matches the two teams have played
     *
     * @type {int}
     */
    numberOfMatchesPlayed = null;
    /**
     * number of draws between the two teams
     *
     * @type {string}
     */
    draws = null;
    /**
     * team 1 stats
     *
     * @type {TeamStats}
     */
    team1Stats = null;
    /**
     * team 2 stats
     *
     * @type {TeamStats}
     */
    team2Stats = null;
    /**
     * @type {MatchStat}
     */
    firstEncounter = null;
    /**
     * computed property
     * whether the 2 teams have any previous matches before
     * @type {boolean}
     */
    hasPreviousMatches = null;

    constructor(data = {}) {
        super(data);
        if (data) {
            this.last5Matches = data.last5Matches || null;
            this.numberOfMatchesPlayed = data.numberOfMatchesPlayed || null;
            this.draws = data.draws || null;
            this.team1Stats = data.team1Stats || null;
            this.team2Stats = data.team2Stats || null;
            this.firstEncounter = data.firstEncounter || null;
            this.hasPreviousMatches = (!empty(data.last5Matches) && data.last5Matches.length > 0);
        }
    }
}

import Model from '@/models/model.js';
import EventTypeModel from '@/models/eventType.js';
import DeclarationTOPModel from '@/models/declarationTOP.js';
import constants from '@/constants';

/**
 * tournament model class
 */
export default class TournamentModel extends Model {
    /**
     * name
     *
     * @type {string}
     */
    name = '';

    /**
     * dateFrom
     *
     * @type {string}
     */
    dateFrom = '';

    /**
     * dateUntil
     *
     * @type {string}
     */
    dateUntil = '';

    /**
     * workflow status
     *
     * @type {string}
     */
    workflowStatus = '';

    /**
     * workflow deadline
     *
     * @type {string}
     */
    workflowDeadline = '';

    /**
     * mapping providers
     *
     * @type {array}
     */
    mappingProviders = [];

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            const seriesData = (data.series && data.series.length > 0) ? data.series[0] : null;
            this.abbreviation = data.abbreviation || data.titleAbbreviation || '';
            this.country = data.country || '';
            this.dateFrom = this.toDate(data.dateFrom) || this.toDate(data.startDate);
            this.dateUntil = this.toDate(data.dateUntil) || this.toDate(data.endDate);
            this.declarationSteps = (data.declarationSteps || []).map(entry => new DeclarationTOPModel(entry));
            this.endDate = this.toDate(data.endDate) || this.toDate(data.dateUntil);
            this.endDateTimeOffset = !isNaN(Number(data.endDateTimeOffset)) ? Number(data.endDateTimeOffset) : '';
            this.eqId = data.eqId || data.tournamentEqId || ((typeof data === 'string') ? data : '') || '';
            this.id = data.id || data.tournamentId || 0;
            this.location = data.location || '';
            this.logoUrl = data.logoUrl || '';
            this.group = data.matchTournamentGroup || data.group || '';
            this.groupName = data.groupName || data.tournamentTypeGroupName || '';
            this.mainYear = data.mainYear || '';
            this.name = data.name || data.title || data.tournamentName || '';
            this.parentTournamentEqId = data.parentTournamentEqId || '';
            this.qualificationTournamentEqIds = data.qualificationTournamentEqIds || [];
            this.sport = data.sport || '';
            this.startDate = this.toDate(data.startDate) || this.toDate(data.dateFrom);
            this.startDateTimeOffset = !isNaN(Number(data.startDateTimeOffset)) ? Number(data.startDateTimeOffset) : '';
            this.tournamentTypeName = data.tournamentTypeName || (typeof data.tournamentType !== 'object' && data.tournamentType !== null ? data.tournamentType : '') || '';
            this.tournamentType = ((typeof data.tournamentType === 'object' && data.tournamentType !== null) ? EventTypeModel.from(data.tournamentType) : data.tournamentType) || [];
            this.tournamentTypeEqId = data.tournamentTypeEqId || '';
            this.tournamentTypeGroup = data.tournamentTypeGroup || '';
            this.tournamentTypeId = this.tournamentType.id || data.tournamentTypeId || 0;
            this.winningTeamEqId = data.winningTeamEqId || '';
            this.winningTeamId = data.winningTeamId || 0;
            this.year = data.year || '';
            this.position = (typeof data.position === 'object' ? data.position.name ? data.position.name : data.position.positionName : data.position) || '';
            this.seriesEqId = (data.seriesEqId || seriesData?.seriesEqId) || '';
            this.seriesId = (data.seriesId || seriesData?.seriesId) || 0;
            this.seriesName = (data.seriesName || seriesData?.name) || '';
            this.seriesTypeId = (data.seriesTypeId || seriesData?.seriesTypeId) || null;
            this.seriesMainSeason = seriesData?.mainSeason || null;
            this.seriesIds = data.seriesIds || [];
            this.tournamentEqId = data.tournamentEqId || '';
            this.matchNumber = data.tournamentMatchNumber || data.matchNumber || 0;
            this.matchTournamentId = data.matchTournamentId || 0;
            this.tournamentName = data.tournamentName || '';
            this.tournamentStage = data.tournamentStage || [];
            this.tournamentStageName = data.tournamentStageName || '';
            this.tournamentStatusTypeId = data.tournamentStatusTypeId || (data.tournamentStatusType ? data.tournamentStatusType.id : 0) || 0;
            this.winningTeamAliasSourceEqId = data.winningTeamAliasSourceEqId || '';
            this.publishStatus = data.publishStatus || '';
            this.winningTeamDisplay = (data.winningTournamentTeam?.teamName && data.winningTournamentTeam?.unionName) ? data.winningTournamentTeam?.teamName + ', ' + data.winningTournamentTeam?.unionName : '' || '';

            this.declarationEnabled = false;
            this.declarationWorkflowId = null;
            this.declarationOpeningDate = null;
            this.declarationDeadlineDate = null;
            this.declarationType = data.declarationType || null;

            this.logoStorage = data.logoUrl || '';
            this.logo = Model._api.call.tournament.resolveStoragePath(this.logoStorage) || null;
        }
    }

    setLogoStorage(logoStorage){
        this.logoStorage = logoStorage;
        this.logo = Model._api.call.tournament.resolveStoragePath(this.logoStorage);
    }

    /**
     * get event type name
     *
     * @returns {string}
     */
    getEventTypeName(){
        if(this.tournamentType && (this.tournamentType.id || this.tournamentType.eqId)){
            return this.tournamentType.getTournamentTypeName();
        }

        return this.tournamentTypeName || '';
    }

    /**
     * get tournament image path
     *
     * @returns {string}
     */
    getImgPath(){
        return this.logo || require('@/assets/img/tournament.svg');
    }

    /**
     * check if sevens tournament
     *
     * @returns {boolean}
     */
    isSevens(){
        return Object.values(constants.sevenSport).includes(this.sport);
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }

    toJSON() {
        return {
            dateFrom: this.fromDate(this.dateFrom),
            dateUntil: this.fromDate(this.dateUntil),
            endDateTimeOffset: this.endDateTimeOffset,
            location: this.location,
            logoUrl: this.logoStorage,
            mainYear: this.mainYear,
            parentTournamentEqId: this.parentTournamentEqId || null,
            qualificationTournamentEqIds: this.qualificationTournamentEqIds,
            seriesEqId: this.seriesEqId || null,
            sport: this.sport,
            startDateTimeOffset: this.startDateTimeOffset,
            title: this.name,
            titleAbbreviation: this.abbreviation,
            tournamentStatusTypeId: this.tournamentStatusTypeId,
            tournamentTypeId: this.tournamentTypeId,
            winningTeamAliasSourceEqId: this.winningTeamAliasSourceEqId || null,
        };
    }

    buildMappingPayloads() {
        if (!this.mappingProviders || !this.mappingProviders.length){
            return [];
        }

        return this.mappingProviders.map(mappingProvider => ({
            provider: mappingProvider.name,
            entity: constants.mappingEntityNames.tournament,
            id: this[mappingProvider.name.toLowerCase()],
            eqId: this.eqId,
        }));
    }
}

const gtmId = 'G-23SLFGQJ7X';
const zendeskKey = '482ff979-8ff9-4729-aa6f-e869a67fa852';

export const setupTracking = function(config, log){
    /* eslint-disable */
    //gtm
    (function(gtmId){
        (function(p,e,n){var y,z;
            y=e.createElement(n);y.async=!0;y.src='https://www.googletagmanager.com/gtag/js?id='+gtmId;
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script');
    })(gtmId);

    //Zendesk
    (function(zendeskKey){
        if (!config.zendeskEnabled) {
            log.info('zendesk enabled: false');
            return;
        }

        log.info('zendesk enabled: true');

        (function(p,e,n){var y,z;
            y=e.createElement(n);y.async=!0;y.src='https://static.zdassets.com/ekr/snippet.js?key='+zendeskKey;y.id='ze-snippet';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script');
    })(zendeskKey);
    /* eslint-enable */
};

export const initTracking = function(user){
    //gtm
    window.dataLayer = window.dataLayer || [];
    window.gtag = function(){
        window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', gtmId);
};

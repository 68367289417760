<template>
    <div class="pageContent">
        <actionButton
            variant="outline-primary"
            class="btn-flat mb-5"
            v-bind:to="backToMatchDetailsLink"
        >
            <icon type="arrow-left" class="align-middle" /> {{ $t('backToPreviousScreen') }}
        </actionButton>

        <matchReportsHeader
            v-bind:waitFor="apiData.teamsheets"
            v-bind:waitForRetry="reloadTeamsheets"
            v-bind:title="$t('teamsheets')"
            v-bind:data="teamsheets"
            v-bind:teamOneHeadCoach="teamOneHeadCoach"
            v-bind:teamTwoHeadCoach="teamTwoHeadCoach"
            v-bind:teamOneTeamDoctor="teamOneTeamDoctor"
            v-bind:teamTwoTeamDoctor="teamTwoTeamDoctor"
            v-bind:teamOneTeamManager="teamOneTeamManager"
            v-bind:teamTwoTeamManager="teamTwoTeamManager"
            v-bind:matchOfficials="matchOfficials"
        />
        <wrapper
            v-bind:waitFor="apiData.teamsheets"
            v-bind:waitForRetry="reloadTeamsheets"
            noPadding noLine noShadow  waitSilent
        >
            <h2 class="teamsheet-header">{{ $t('lineup') }}</h2>
            <div class="teamsheet-table-layout teamsheet-report">
                <teamsheetTable
                    v-bind:players="team1Lineup"
                    v-bind:isSub="false"
                    v-bind:scorings="team1Scorings"
                    v-bind:disciplinaries="team1Disciplinaries"
                    v-bind:matchKickOffDate="match.kickOff"
                />
                <teamsheetTable
                    v-bind:players="team2Lineup"
                    v-bind:isSub="false"
                    v-bind:scorings="team2Scorings"
                    v-bind:disciplinaries="team2Disciplinaries"
                    v-bind:matchKickOffDate="match.kickOff"
                />
            </div>

            <h2 class="teamsheet-header">{{ $t('subs') }}</h2>
            <div class="d-flex flex-row justify-content-between teamsheet-report">
                <teamsheetTable
                    v-bind:players="team1Subs"
                    v-bind:isSub="true"
                    v-bind:scorings="team1Scorings"
                    v-bind:disciplinaries="team1Disciplinaries"
                    v-bind:matchKickOffDate="match.kickOff"
                />
                <teamsheetTable
                    v-bind:players="team2Subs"
                    v-bind:isSub="true"
                    v-bind:scorings="team2Scorings"
                    v-bind:disciplinaries="team2Disciplinaries"
                    v-bind:matchKickOffDate="match.kickOff"
                />
            </div>
        </wrapper>
    </div>
</template>

<script>
import icon from '@/components/icon.vue';
import matchReportsHeader from '@/components/matchReportsHeader.vue';
import teamsheetTable from '@/components/tables/teamsheetTable.vue';
import MatchTeamsheetModel from '@/models/matchTeamsheet.js';
import base from '@/views/base.vue';
import { empty } from '@/utils/miscUtils';

export default {
    name: 'commonMatchTeamsheets',
    extends: base,
    components: {
        icon,
        matchReportsHeader,
        teamsheetTable
    },
    props: {
        dataMatchId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data(){
        return {
            apiData: {
                teamsheets: this.loadTeamsheets(),
            },
            matchTournaments: [],
            matchOfficials: [],
            teamsheets: null,
            teamsheetTeam1Lineup: null,
            teamsheetTeam1Replacement: null,
            teamsheetTeam2Lineup: null,
            teamsheetTeam2Replacement: null,
            teamOneHeadCoach: '',
            teamTwoHeadCoach: '',
            teamOneTeamDoctor: '',
            teamTwoTeamDoctor: '',
            teamOneTeamManager: '',
            teamTwoTeamManager: '',
            match: null,
        };
    },
    computed: {
        backToMatchDetailsLink() {
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.dataMatchId,
                }, this.detailLinkParams),
            };
        },
        hasTournament() {
            const tournaments = this.match?.tournaments;
            return tournaments && tournaments.length > 0;
        },
        team1Lineup() {
            return this.getLineup(0);
        },
        team2Lineup() {
            return this.getLineup(1);
        },
        team1Subs() {
            return this.getLineup(0, true);
        },
        team2Subs() {
            return this.getLineup(1, true);
        },
        team1Disciplinaries() {
            return this.getDisciplinaries(0);
        },
        team2Disciplinaries() {
            return this.getDisciplinaries(1);
        },
        team1Scorings() {
            return this.getScorings(0);
        },
        team2Scorings() {
            return this.getScorings(0);
        },
        team1EqId() {
            return this.teamsheets?.teams?.[0]?.team?.teamEqId || null;
        },
        team2EqId() {
            return this.teamsheets?.teams?.[1]?.team?.teamEqId || null;
        },
        tournamentId() {
            const tournaments = this.match?.tournaments;
            if (empty(tournaments)) {
                return null;
            }
            return tournaments[0].id;
        },
    },
    methods: {
        loadTeamsheets() {
            return this.$api.call.match.matchReportsTeamsheetsGetByMatchId(this.dataMatchId).then(result => {
                this.teamsheets = new MatchTeamsheetModel(result);

                this.teamOneHeadCoach = this.extractTeamPersonnel(0, 'Head Coach');
                this.teamTwoHeadCoach = this.extractTeamPersonnel(1, 'Head Coach');

                this.teamOneTeamDoctor = this.extractTeamPersonnel(0, 'Team Doctor');
                this.teamTwoTeamDoctor = this.extractTeamPersonnel(1, 'Team Doctor');

                this.teamOneTeamManager = this.extractTeamPersonnel(0, 'Team Manager');
                this.teamTwoTeamManager = this.extractTeamPersonnel(1, 'Team Manager');

                this.match = this.teamsheets.match;
                if ((this.hasTournament)
                    && (empty(this.teamOneHeadCoach)
                        || empty(this.teamTwoHeadCoach)
                        || empty(this.teamOneTeamDoctor)
                        || empty(this.teamTwoTeamDoctor))
                ) {
                    this.getMatchManagement();
                }

                return this.getMatchOfficials();
            });
        },
        reloadTeamsheets() {
            this.apiData.teamsheets = this.loadTeamsheets();
        },
        getMatchOfficials() {
            return this.$api.call.match.matchOfficialsGetByMatchId(this.dataMatchId).then(result => {
                this.matchOfficials = (result || []).map(official => ({
                    position: official.positionName,
                    name: official.displayName,
                    union: official.unionName,
                }));
            });
        },
        extractTeamPersonnel(teamIndex, positionName) {
            const teamPerson = this.teamsheets.teams[teamIndex].team.teamSheets.filter((item) => item.positionName === positionName);
            return teamPerson[0]?.displayName || '';
        },
        getLineup(teamIndex, isSubs = false) {
            if (!this.teamsheets || this.teamsheets.teams.length < 2) {
                return [];
            }

            return this.teamsheets.teams[teamIndex].team.teamSheets.filter((item) =>
                isSubs ? item.positionName === 'Replacement' : item.positionName !== 'Replacement');
        },
        getDisciplinaries(teamIndex) {
            if (!this.teamsheets || this.teamsheets.teams.length < 2) {
                return {};
            }

            const map = {};
            const disciplinaryData = this.teamsheets.teams[teamIndex].team.disciplinary;
            ['yellow', 'red'].forEach(cardType => {
                const disciplinaryList = disciplinaryData[cardType + 'Cards'] || [];

                disciplinaryList.forEach(disciplinary => {
                    if (!disciplinary.personEqId) {
                        return;
                    }

                    disciplinary.cardType = cardType;
                    map[disciplinary.personEqId] = disciplinary;
                });
            });
            return map;
        },
        getMatchManagement() {
            const tournamentId = this.tournamentId;
            const team1AliasEqId = this.teamsheets.teams?.[0]?.team?.teamAliasEqId;
            const team2AliasEqId = this.teamsheets.teams?.[1]?.team?.teamAliasEqId;
            if (empty(tournamentId) || empty(team1AliasEqId) || empty(team2AliasEqId)) {
                return Promise.reject(Error('Missing data to get match management'));
            }
            return this.$api.call.tournament.tournamentGetManagement(tournamentId, team1AliasEqId, team2AliasEqId).then(managementData => {
                const team1EqId = this.team1EqId;
                const team2EqId = this.team2EqId;
                const managers = managementData?.managers;
                const medical = managementData?.medical;
                const team1HeadCoach = managers?.find(manager => manager.positionName === 'Head Coach' && manager.teamEqId === team1EqId);
                const team2HeadCoach = managers?.find(manager => manager.positionName === 'Head Coach' && manager.teamEqId === team2EqId);
                const team1TeamManager = managers?.find(manager => manager.positionName === 'Team Manager' && manager.teamEqId === team1EqId);
                const team2TeamManager = managers?.find(manager => manager.positionName === 'Team Manager' && manager.teamEqId === team2EqId);
                const team1TeamDoctor = medical?.find(medic => medic.positionName === 'Team Doctor' && medic.teamEqId === team1EqId);
                const team2TeamDoctor = medical?.find(medic => medic.positionName === 'Team Doctor' && medic.teamEqId === team2EqId);
                if (!empty(team1HeadCoach)) {
                    this.teamOneHeadCoach = team1HeadCoach.displayName;
                }
                if (!empty(team2HeadCoach)) {
                    this.teamTwoHeadCoach = team2HeadCoach.displayName;
                }
                if (!empty(team1TeamManager)) {
                    this.teamOneTeamManager = team1TeamManager.displayName;
                }
                if (!empty(team2TeamManager)) {
                    this.teamTwoTeamManager = team2TeamManager.displayName;
                }
                if (!empty(team1TeamDoctor)) {
                    this.teamOneTeamDoctor = team1TeamDoctor.displayName;
                }
                if (!empty(team2TeamDoctor)) {
                    this.teamTwoTeamDoctor = team2TeamDoctor.displayName;
                }
            });
        },
        getScorings(teamIndex) {
            if (!this.teamsheets || this.teamsheets.teams.length < 2) {
                return {};
            }

            const map = {};
            const scoreData = this.teamsheets.teams[teamIndex].team.scoring;
            ['conversion', 'tries', 'penalties', 'dropGoals'].forEach(scoreType => {
                const scoreList = scoreData[scoreType] || [];

                scoreList.forEach(score => {
                    if (!score.personEqId || !score.points) {
                        return;
                    }

                    map[score.personEqId] = true;
                });
            });

            return map;
        }
    },
};
</script>

import constants from '@/constants.js';
import permissions from '@/permissions.js';
import routerTools from '@/router/tools.js';
import commonTournamentBestPerformers from '@/views/common/tournament/bestPerformers';
import commonTournamentCreate from '@/views/common/tournament/create.vue';
import commonTournamentDetail from '@/views/common/tournament/detail.vue';
import commonTournamentEdit from '@/views/common/tournament/edit.vue';
import commonTournamentFinalPlaces from '@/views/common/tournament/finalPlaces.vue';
import commonTournamentIndex from '@/views/common/tournament/index.vue';
import commonTournamentOverview from '@/views/common/tournament/overview.vue';
import commonTournamentScheduleCombinedDetail from '@/views/common/tournament/reports/schedule-combined.vue';
import commonTournamentScheduleDetail from '@/views/common/tournament/reports/schedule.vue';
import commonTournamentUnder19Detail from '@/views/common/tournament/reports/under19.vue';
import commonTournamentTeamBiographies from '@/views/common/tournament/reports/teamBiographies.vue';
import pageFrame from '@/views/frame.vue';
import commonTournamentBestPerformersInGame from '@/views/common/tournament/bestPerformersInGame';
import tournamentMatchRoutes from './tournament/match.js';
import tournamentTeamRoutes from './tournament/team.js';

export default {
    path: '/tournament/',
    component: pageFrame,
    children: [
        {
            name: 'commonTournamentIndex',
            path: constants.routePaths.index,
            component: commonTournamentIndex,
            props: (route) => ({ teamEqId: route.query.teamEqId }),
            meta: {
                permission: permissions.tournaments.list,
            },
        },
        {
            name: 'commonTournamentCreate',
            path: constants.routePaths.create,
            component: commonTournamentCreate,
            meta: {
                permission: permissions.tournaments.create,
            },
        },
        {
            path: routerTools.propRoute(constants.routeProps.tournamentId),
            component: pageFrame,
            children: [
                {
                    name: 'commonTournamentDetail',
                    path: constants.routePaths.index,
                    component: commonTournamentDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
                    meta: {
                        permission: permissions.tournaments.detail,
                    },
                },
                {
                    name: 'commonTournamentEdit',
                    path: constants.routePaths.edit,
                    component: commonTournamentEdit,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
                    meta: {
                        permission: permissions.tournaments.update,
                    },
                },
                {
                    name: 'commonTournamentUnder19Detail',
                    path: constants.routePaths.under19,
                    component: commonTournamentUnder19Detail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
                    meta: {
                        permission: permissions.tournaments.detail,
                    },
                },
                {
                    name: 'commonTournamentFinalPlaces',
                    path: constants.routePaths.finalPlaces,
                    component: commonTournamentFinalPlaces,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
                    meta: {
                        permission: permissions.tournaments.detail,
                    },
                },
                {
                    name: 'commonTournamentScheduleDetail',
                    path: constants.routePaths.schedule,
                    component: commonTournamentScheduleDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
                    meta: {
                        permission: permissions.tournaments.detail,
                    },
                },
                {

                    name: 'commonTournamentScheduleCombinedDetail',
                    path: constants.routePaths.scheduleCombined,
                    component: commonTournamentScheduleCombinedDetail,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
                    meta: {
                        permission: permissions.tournaments.detail,
                    },
                },
                {
                    name: 'commonTournamentOverview',
                    path: constants.routePaths.overview,
                    component: commonTournamentOverview,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
                    meta: {
                        permission: permissions.tournaments.detail,
                    },
                },
                {
                    name: 'commonTournamentBestPerformers',
                    path: constants.routePaths.bestPerformers,
                    component: commonTournamentBestPerformers,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
                    meta: {
                        permission: permissions.tournaments.detail,
                    },
                },
                {
                    name: 'commonTournamentTeamBiographies',
                    path: 'team-biographies',
                    component: commonTournamentTeamBiographies,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
                    meta: {
                        permission: permissions.tournaments.detail,
                    },
                },
                {
                    name: 'commonTournamentBestPerformersInGame',
                    path: constants.routePaths.bestPerformersInGame,
                    component: commonTournamentBestPerformersInGame,
                    props: routerTools.convertNumericalProps.bind(null, [constants.routeProps.tournamentId]),
                    meta: {
                        permission: permissions.tournaments.detail,
                    },
                },
                tournamentMatchRoutes,
                tournamentTeamRoutes,
            ],
        },
    ],
};

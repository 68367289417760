<template>
    <div class="pageContent">
        <div class="d-flex flex-row justify-content-between">
            <div>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat mb-5"
                    v-bind:to="backToTournamentDetailsLink"
                >
                    <icon type="arrow-left" class="align-middle"/> {{ $t('backToPreviousScreen') }}
                </actionButton>
            </div>
        </div>
        <simpleHeader v-bind:title="$t('finalPlaces')">
            <template v-slot:title>
                <div class="d-flex flex-row w-100 justify-content-between">
                    <div>
                        <h1>{{ $t('finalPlaces')}}</h1>
                    </div>
                    <div>
                        <actionButton variant="outline-primary" class="btn-flat" v-on:click="printSegment()">
                            {{ $t('print') }}
                        </actionButton>
                    </div>
                </div>
            </template>
            <div class="w-100 pt-3 d-flex flex-column align-items-center">
                <div class="d-flex align-items-center gap-15">
                    <img
                        v-bind:src="logo"
                        v-bind:alt="tournament.name"
                        v-bind:height="logoNotProvided ? 40 : 80"
                    />
                    <h2>{{ tournament.name }}</h2>
                </div>
                <span>{{ $fd(tournament.dateFrom) }} - {{ $fd(tournament.dateUntil) }}</span>
            </div>
        </simpleHeader>
        <wrapper v-bind:waitFor="apiData.matches" v-bind:waitForRetry="reloadTournamentMatches"
            noPadding noLine noShadow waitSilent>
            <b-table v-bind:fields="tableFields" v-bind:items="tableData" responsive hover />
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import icon from '@/components/icon.vue';
import simpleHeader from '@/components/simpleHeader.vue';
import TournamentModel from '@/models/tournament.js';
import MatchModel from '@/models/match.js';
import wrapper from '@/components/wrapper.vue';
import Model from '@/models/model.js';
import { empty } from '@/utils/miscUtils';
import constants from '@/constants.js';

export default {
    name: 'commonTournamentFinalPlaces',
    extends: base,
    components: {
        icon,
        simpleHeader,
        wrapper,
    },
    props: {
        dataTournamentId: {
            type: Number,
            mandatory: true,
            default: null
        },
    },
    data() {
        this.apiData = {
            tournament: this.loadTournament(),
        };
        return {
            apiData: {
                tournament: this.apiData.tournament,
                matches: this.loadTournamentMatches(),
            },
            tournament: null,
            tournamentMatches: [],
            logo: '',
            tableFields: [
                {
                    key: 'place',
                    label: 'Tournament Placings',
                },
                {
                    key: 'game',
                    label: 'Game'
                },
                {
                    key: 'team',
                    label: 'Team'
                },
                {
                    key: 'points',
                    label: 'Points'
                }
            ]
        }
    },
    methods: {
        loadTournament() {
            return this.$api.call.tournament.tournamentGetById(this.dataTournamentId).then(data => {
                this.tournament = new TournamentModel(data);
                this.logo = (!empty(this.tournament.logo)) ? Model._api.call.tournament.resolveStoragePath(this.tournament.logo) : require('@/assets/img/tournament.svg');
                this.reloadTournamentMatches();
            });
        },
        loadTournamentMatches() {
            if (!this.tournament) {
                return new Promise((resolve, reject) => this.apiData.tournament.catch(reject))
            }
            return this.$api.call.match.matchTournamentsGetByTournamentEqId(this.tournament.eqId).then(tournamentMatches => {
                this.tournamentMatches = tournamentMatches.map(tournamentMatch => new MatchModel(tournamentMatch, this.dataTournamentId));
            })
        },
        reloadTournamentMatches() {
            this.apiData.matches = this.loadTournamentMatches();
        },
        formatOrdinalNumber(number) {
            if (number > 4) {
                return `${number}th ${this.$t('placeRankedTeam')}`;
            }

            switch(number) {
                case 1:
                    return this.$t('cupWinner')
                case 2:
                    return this.$t('cupFinalist');
                case 3:
                    return this.$t('thirdPlaceWinner');
                case 4:
                    return this.$t('thirdPlaceFinalist');
                default:
                    return null;
            }
        },
        calculatePoints(place) {
            const points = constants.finalPlacesPoints;
            return points[place];
        }
    },
    computed: {
        matchResults() {
            const playoffMatches = this.tournamentMatches.filter((match) => match.stageName !== 'Pool');

            return playoffMatches.sort((a, b) => {
                return new Date(b.kickOff) - new Date(a.kickOff)
            })
        },
        tableData() {
            const results = [];
            let place = 1;
            const teamNames = [];
            for (let res of this.matchResults) {
                // only include first 12 places
                if (place > 12) {
                    break;
                }
                const team1 = res.teamOne;
                const team2 = res.teamTwo;
                const tournament = res.tournaments[0];
                const winningTeam = team1.score > team2.score ? team1 : team2;
                const losingTeam = team1.score < team2.score ? team1 : team2;
                const resultWinner = { team: winningTeam.team.name, game: `Winner Match ${tournament.matchNumber}`, place: this.formatOrdinalNumber(place) };
                const resultLoser = { team: losingTeam.team.name, game: `Loser Match ${tournament.matchNumber}`, place: this.formatOrdinalNumber(place + 1) };
                if (!teamNames.includes(resultWinner.team)) {
                    resultWinner.points = this.calculatePoints(place);
                    teamNames.push(resultWinner.team);
                    results.push(resultWinner);
                    place++;
                }
                if (!teamNames.includes(resultLoser.team)) {
                    resultLoser.points = this.calculatePoints(place);
                    teamNames.push(resultLoser.team);
                    results.push(resultLoser);
                    place++;
                }
            }
            return results;
        },
        backToTournamentDetailsLink() {
            return {
                name: this.detailLinkRoute || 'commonTournamentDetail',
                params: Object.assign({
                    dataTournamentId: this.dataTournamentId,
                }, this.detailLinkParams),
            }
        },
        logoNotProvided() {
            if (empty(this.tournamentData)) return true;
            return empty(this.tournamentData?.tournamentLogoUrl);
        },
    }
}
</script>

<template>
    <div class="pageContent wr-report">
        <div class="d-flex flex-row justify-content-between">
            <div>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat mb-5"
                    v-bind:to="backToTournamentDetailsLink"
                >
                    <icon type="arrow-left" class="align-middle"/> {{ $t('backToPreviousScreen') }}
                </actionButton>
            </div>
        </div>

        <simpleHeader v-bind:title="$t('teamBiographies')">
            <template v-slot:title>
                <div class="d-flex flex-row w-100 justify-content-between">
                    <div>
                        <h1>{{ $t('teamBiographies') }}</h1>
                    </div>
                    <div>
                        <actionButton variant="outline-primary" class="btn-flat no-print" v-on:click="printSegment()">
                            {{ $t('print') }}
                        </actionButton>
                    </div>
                </div>
            </template>
            <div class="w-100 pt-3 d-flex flex-column align-items-center" v-if="tournament">
                <div class="d-flex align-items-center gap-15">
                    <img
                        v-bind:src="logo"
                        v-bind:alt="tournament.name"
                        v-bind:height="40"
                    />
                    <h2>{{ tournament.name }}</h2>
                </div>
                <span>{{ $fd(tournament.dateFrom) }} - {{ $fd(tournament.dateUntil) }}</span>
            </div>
        </simpleHeader>

        <div class="w-100 pt-3 pb-3 d-flex flex-column align-items-left report-main-header">
            <h2 class="text-uppercase">{{ $t('teamBiographies') }}: Pool {{ poolName }}</h2>
        </div>

        <div v-bind:key="'isLoading'" v-if="isLoading">
            <b-row>
                <b-col class="text-center">
                    <throbber class="align-middle mt-2" />
                </b-col>
            </b-row>
        </div>
        <div v-bind:waitFor="apiData._loadData" v-else>
            <b-row>
                <b-col>
                    <teamBiographyTable v-bind:team="team1" v-bind:statistics="team1Statistics" v-if="team1Statistics"/>
                </b-col>
                <b-col>
                    <teamBiographyTable v-bind:team="team2" v-bind:statistics="team2Statistics" v-if="team2Statistics"/>
                </b-col>
            </b-row>

            <div style="height: 30px"></div>

            <b-row>
                <b-col>
                    <teamBiographyTable v-bind:team="team3" v-bind:statistics="team3Statistics" v-if="team3Statistics"/>
                </b-col>
                <b-col>
                    <teamBiographyTable v-bind:team="team4" v-bind:statistics="team4Statistics" v-if="team4Statistics"/>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import icon from '@/components/icon.vue';
import simpleHeader from '@/components/simpleHeader.vue';
import teamBiographyTable from '@/components/tables/teamBiographyTable.vue';
import base from '@/views/base.vue';
import throbber from '@/components/throbber.vue';
import { teamSeriesStatistics } from '@/services/report/teamBiographiesBuilder.js';

import Model from '@/models/model.js';
import TournamentModel from '@/models/tournament.js';

import { empty } from '@/utils/miscUtils';

export default {
    name: 'commonTournamentTeamBiographies',
    extends: base,
    components: {
        icon,
        simpleHeader,
        teamBiographyTable,
        throbber,
    },
    data() {
        return {
            apiData: {
                _tournament: this.loadTournament(),
                _loadData: this.loadTeamsAndStatistics(),
            },
            tournament: null,
            logo: null,
            team1: null,
            team2: null,
            team3: null,
            team4: null,
            team1Statistics: null,
            team2Statistics: null,
            team3Statistics: null,
            team4Statistics: null,
            isLoading: true
        }
    },
    props: {
        dataTournamentId: {
            type: Number,
            mandatory: true,
            default: null
        }
    },
    methods: {
        loadTournament() {
            return this.$api.call.tournament.tournamentGetById(this.dataTournamentId).then(data => {
                this.tournament = new TournamentModel(data);
                this.logo = (!empty(this.tournament.logo)) ? Model._api.call.tournament.resolveStoragePath(this.tournament.logo) : require('@/assets/img/tournament.svg');
            });
        },
        reloadTournament() {
            this.apiData.tournament = this.loadTournament();
        },
        loadTeamsAndStatistics() {
            return this.$api.call.tournament
                .tournamentTeamsGetAllByTournamentId(this.dataTournamentId)
                .then(response => {
                    this.teamEqIds.forEach((teamEqId, index) => {
                        this[`team${index + 1}`] = response.teams.find(team => team.teamEqId === teamEqId);
                    });
                })
                .then(() => {
                    if (this.teamEqIds.length === 0) {
                        return new Promise((resolve) => resolve());
                    }

                    return this.$api.call.match.teamStatisticsForSeries([1, 3], this.teamEqIds, 'MRS').then(matches => {
                        const data = teamSeriesStatistics(matches);

                        [this.team1, this.team2, this.team3, this.team4].forEach((team, index) => {
                            this[`team${index + 1}Statistics`] = data[team.teamEqId];
                        });

                        this.isLoading = false;
                    });
                });
        }
    },
    computed: {
        teamEqIds() {
            const ids = this.$route.query.teamEqIds;
            return ids ? ids.split(',') : [];
        },
        poolName() {
            return this.$route.query.poolName;
        },
        backToTournamentDetailsLink() {
            return {
                name: this.detailLinkRoute || 'commonTournamentDetail',
                params: Object.assign({
                    dataTournamentId: this.dataTournamentId,
                }, this.detailLinkParams),
            }
        },
    }
}
</script>

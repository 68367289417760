<template>
    <div class="pageContent">
        <div class="d-flex flex-row justify-content-between w-100">
            <div>
                <actionButton
                    variant="outline-primary"
                    class="btn-flat mb-5"
                    v-bind:to="backToMatchDetailsLink"
                >
                    <icon type="arrow-left" class="align-middle" /> {{ $t('backToPreviousScreen') }}
                </actionButton>
            </div>
            <div>
                <actionButton variant="outline-primary" class="btn-flat no-print" v-on:click="printSegment()">
                    {{ $t('print') }}
                </actionButton>
            </div>
        </div>
        <detailsHeader
                v-bind:waitFor="apiData.match"
                v-bind:waitForRetry="reloadMatch"
                v-bind:title="$t('headToHead')"
            >
            <template v-slot:default>
                <div class="col-12 px-3 px-lg-5 py-4 pt-lg-6" v-if="match">
                    <div class="top col-12">
                        <div class="col-12 pt-0 pb-5 text-center">
                            <ul>
                                <li class="col-12">
                                    <span class="m-0">{{ $fd(match.kickOff, 'dateTime') }}</span>
                                </li>
                                <li class="col-12">
                                    <span>{{ match.venue ? match.venue.name : '' }}</span>
                                    <span>{{ match.venue ? match.venue.locationName : '' }}</span>
                                </li>
                                <li class="col-12">
                                    <span>{{ formattedTournamentStage }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="px-0 pb-5 d-flex flex-row justify-content-center">
                            <div class="d-flex justify-content-between gap-30 teams"> <!-- meow -->
                                <div class="d-flex">
                                    <h2 class="float-right text-uppercase m-0 py-2">{{ team1Name}}</h2>
                                    <span>
                                        <b-img v-bind:src="team1Logo" v-bind:height="32" class="ml-2"></b-img>
                                    </span>
                                </div>
                                <div class="d-flex">
                                    <span>
                                        <b-img v-bind:src="team2Logo" v-bind:height="32" class="mr-2"></b-img>
                                    </span>
                                    <h2 class="float-left text-uppercase m-0 py-2">{{ team2Name }}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </detailsHeader>
        <wrapper
            v-bind:waitFor="apiData.headToHeadData"
            v-bind:waitForRetry="reloadHeadToHeadData"
            noPadding noLine noShadow waitSilent
        >
            <div class="h2h-stats-container">
                <div class="head-to-head">
                    <b-table-simple small caption-top responsive v-if="headToHeadData.hasPreviousMatches">
                        <b-thead>
                            <b-tr>
                                <b-th class="text-uppercase text-center" colspan="4">
                                    Head to Head &ndash; {{ match.teamOneDisplayName }} vs {{ match.teamTwoDisplayName }}
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tr>
                            <b-td>{{ $t('played') }}: {{ headToHeadData.numberOfMatchesPlayed }}</b-td>
                            <b-td>{{ team1Name }}: {{ headToHeadData.team1Stats.wins }}</b-td>
                            <b-td>{{ team2Name }}: {{ headToHeadData.team2Stats.wins }}</b-td>
                            <b-td>{{ $t('drawn') }}: {{ headToHeadData.draws }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td colspan="2">
                                {{ $t('pointsScored') }}: {{ headToHeadData.team1Stats.totalPoints }} &ndash; {{ headToHeadData.team2Stats.totalPoints }}
                            </b-td>
                            <b-td colspan="2">
                                {{ $t('average') }}: {{ headToHeadData.team1Stats.avgPoints }} &ndash; {{ headToHeadData.team2Stats.avgPoints }}
                            </b-td>
                        </b-tr>
                    </b-table-simple>

                    <b-table-simple small caption-top responsive v-else>
                        <b-thead>
                            <b-tr>
                                <b-th class="text-uppercase text-center" colspan="4">
                                    Head to Head &ndash; {{ match.teamOneDisplayName }} vs {{ match.teamTwoDisplayName }}
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tr class="text-center">
                            {{  $t('firstEncounter') }}
                        </b-tr>
                    </b-table-simple>

                    <b-table-simple small caption-top responsive v-if="headToHeadData.hasPreviousMatches">
                        <b-tr>
                            <b-td colspan="6">
                                {{ $t('firstEncounterIn') }} {{ headToHeadData.firstEncounter.statMatchCity }} {{ headToHeadData.firstEncounter.statMatchYear }}, {{ headToHeadData.firstEncounter.tournamentStage }}. {{ headToHeadData.firstEncounter.winningTeamName }} {{ $t('won') }} {{ headToHeadData.firstEncounter.winningScore }} &ndash; {{ headToHeadData.firstEncounter.losingScore }}.
                            </b-td>
                        </b-tr>
                        <b-tr v-for="(stat, i) in highestScores" v-bind:key="`${i}-${stat.statMatchYear}-${stat.statMatchCity}-${stat.winningScore}`">
                            <b-td>{{ stat.teamName }} highest score:</b-td>
                            <!-- eslint-disable-next-line -->
                            <b-td class="text-center" v-html="'&nbsp;' + stat.statValue" />
                            <b-td class="text-center">{{ stat.statMatchCity }}</b-td>
                            <b-td class="text-center">{{ stat.statMatchYear }}</b-td>
                            <b-td class="text-center">{{ stat.tournamentStage }}</b-td>
                            <b-td class="text-center">{{ stat.teamName === stat.winningTeamName ? $t('won') : $t('lost') }} {{ stat.winningScore }} &ndash; {{ stat.losingScore }}</b-td>
                        </b-tr>
                        <b-tr v-for="(stat, i) in bestWinMargins" v-bind:key="`${i}-${stat.statMatchYear}-${stat.statMatchCity}`">
                            <b-td>{{ stat.winningTeamName }} best win margin:</b-td>
                            <b-td class="text-center">+{{ stat.statValue }}</b-td>
                            <b-td class="text-center">{{ stat.statMatchCity }}</b-td>
                            <b-td class="text-center">{{ stat.statMatchYear }}</b-td>
                            <b-td class="text-center">{{ stat.tournamentStage }}</b-td>
                            <b-td class="text-center">{{ $t('won') }} {{ stat.winningScore }} &ndash; {{ stat.losingScore }}</b-td>
                        </b-tr>
                    </b-table-simple>
                </div>
                <div class="h2h-matches">
                    <b-table-simple small caption-top responsive v-if="headToHeadData.hasPreviousMatches">
                        <b-thead>
                            <b-tr>
                                <b-th class="text-uppercase text-center" colspan="6">
                                    Last 5 Encounters
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tr v-for="prevMatch in headToHeadData.last5Matches" v-bind:key="`${prevMatch.city}${prevMatch.matchKickOff}`">
                            <b-td class="text-center">{{ prevMatch.city }}</b-td>
                            <b-td>{{ prevMatch.matchKickOff.slice(0, 4) }}</b-td>
                            <b-td class="text-center">{{ formatLastMatchTournamentStage(prevMatch.tournamentStage, prevMatch.tournamentStageGroup) }}</b-td>
                            <b-td>{{ (prevMatch.winningScore === prevMatch.losingScore) ? 'Draw' : prevMatch.winningTeamName }}</b-td>
                            <b-td>{{ prevMatch.winningScore }} &ndash; {{ prevMatch.losingScore }}</b-td>
                            <b-td>{{ prevMatch.scoreDiff }}</b-td>
                        </b-tr>
                    </b-table-simple>
                    <b-table-simple small caption-top responsive v-else>
                        <b-thead>
                            <b-tr>
                                <b-th class="text-uppercase text-center" colspan="6">
                                    Last 5 Encounters
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tr>
                            <b-td class="text-center">
                                {{ $t('noMatchesPlayed') }}
                            </b-td>
                        </b-tr>
                    </b-table-simple>
                </div>
            </div>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import icon from '@/components/icon.vue';
import MatchModel from '@/models/match.js';
import { MatchTournamentModelWithSeries } from '@/models/matchTournament.js';
import detailsHeader from '@/components/detailsHeader.vue';
import { empty } from '@/utils/miscUtils.js';
import { HeadToHead } from '@/models/report/headToHead.js';
import { TeamStats } from '@/models/report/teamStats';
import { MatchStat } from '@/models/report/matchStat';

export default {
    name: 'commonMatchHeadToHead',
    extends: base,
    components: {
        detailsHeader,
        icon
    },
    props: {
        dataMatchId: {
            type: Number,
            mandatory: true,
            default: null,
        },
    },
    data() {
        this.apiData = {
            match: this.loadMatch(),
        }
        return {
            apiData: {
                match: this.apiData.match,
                matchTournaments: this.loadMatchTournaments(),
                headToHeadData: this.loadHeadToHeadData(),
            },
            match: {},
            matchTournaments: [],
            headToHeadData: {},
        }
    },
    methods: {
        loadMatch(){
            return this.$api.call.match.matchGetById(this.dataMatchId).then(data => {
                this.match = new MatchModel(data);
                this.reloadMatchTournaments();
            });
        },
        reloadMatch() {
            this.apiData.match = this.loadMatch();
        },
        loadMatchTournaments(){
            if(!this.match){
                return new Promise((resolve, reject) => this.apiData.match.catch(reject));
            }

            return this.$api.call.match.matchGetTournamentsWithSeriesData(this.match.id).then(result => {
                this.matchTournaments = (result || []).map(data => new MatchTournamentModelWithSeries(data));
                this.reloadHeadToHeadData();
            });
        },
        reloadMatchTournaments(){
            this.apiData.matchTournaments = this.loadMatchTournaments();
        },
        loadHeadToHeadData() {
            if (!this.match || !this.matchTournaments) {
                return new Promise((resolve, reject) => this.apiData.headToHeadData.catch(reject));
            }

            const team1EqId = this.match.team1?.eqId;
            const team2EqId = this.match.team2?.eqId;
            const matchId = this.match.id;
            const seriesTypeId = this.matchTournaments[0].seriesTypeId;

            return this.$api.call.match.matchGetHeadToHead(matchId, team1EqId, team2EqId, seriesTypeId).then(result => {
                const last5Matches = (result.last5Matches || []).map((match) => new MatchStat(match));
                const firstEncounter = new MatchStat(result.firstEncounter);
                const team1StatsObj = result.team1Stats;
                const team2StatsObj = result.team2Stats;

                const team1HighestScore = !empty(team1StatsObj.highestScore) ? new MatchStat(team1StatsObj.highestScore) : null;
                const team2HighestScore = !empty(team2StatsObj.highestScore) ? new MatchStat(team2StatsObj.highestScore) : null;
                const team1BestWinMargin = !empty(team1StatsObj.bestWinMargin) ? new MatchStat(team1StatsObj.bestWinMargin) : null;
                const team2BestWinMargin = !empty(team2StatsObj.bestWinMargin) ? new MatchStat(team2StatsObj.bestWinMargin) : null;
                const team1Stats = new TeamStats({ ...team1StatsObj, highestScore: team1HighestScore, bestWinMargin: team1BestWinMargin });
                const team2Stats = new TeamStats({ ...team2StatsObj, highestScore: team2HighestScore, bestWinMargin: team2BestWinMargin });
                this.headToHeadData = new HeadToHead({
                    last5Matches: last5Matches,
                    numberOfMatchesPlayed: result.numberOfMatchesPlayed,
                    draws: result.draws,
                    team1Stats: team1Stats,
                    team2Stats: team2Stats,
                    firstEncounter: firstEncounter
                });
            });
        },
        reloadHeadToHeadData(){
            this.apiData.headToHeadData = this.loadHeadToHeadData();
        },
        formatTournamentStage() {
            if (empty(this.matchTournaments) || empty(this.matchTournaments[0]?.tournamentStageName)) {
                return '';
            }
            let tournamentStageName = this.matchTournaments[0].tournamentStageName;
            if (!empty(this.matchTournaments[0]?.groupName)) {
                tournamentStageName += ` ${this.matchTournaments[0].groupName}`;
            }

            return tournamentStageName;
        },
        checkBreakpoint() {
            const mediaQuery1 = window.matchMedia('(max-width: 1100px)');
            const mediaQuery2 = window.matchMedia('(max-width: 650px)');
            this.isSmallOrBelow = mediaQuery1.matches;
            this.isVSmallOrBelow = mediaQuery2.matches;
        },
        formatLastMatchTournamentStage(tournamentStage, tournamentStageGroup) {
            if (empty(tournamentStageGroup)) {
                return tournamentStage;
            }

            return `${tournamentStage} ${tournamentStageGroup}`;
        },
    },
    computed: {
        backToMatchDetailsLink() {
            return {
                name: this.detailLinkRoute || 'commonMatchDetail',
                params: Object.assign({
                    dataMatchId: this.dataMatchId,
                }, this.detailLinkParams),
            };
        },
        formattedTournamentStage() {
            return this.formatTournamentStage();
        },
        highestScores() {
            if (empty(this.headToHeadData)) {
                return [];
            }
            const scores = [];

            if (!empty(this.headToHeadData.team1Stats.highestScore)) {
                const team1HighestScore = { ...this.headToHeadData.team1Stats.highestScore };
                team1HighestScore.teamName = this.team1Name;
                scores.push(team1HighestScore);
            }
            if (!empty(this.headToHeadData.team2Stats.highestScore)) {
                const team2HighestScore = { ...this.headToHeadData.team2Stats.highestScore };
                team2HighestScore.teamName = this.team2Name;
                scores.push(team2HighestScore);
            }

            return scores;
        },
        bestWinMargins() {
            if (empty(this.headToHeadData)) {
                return [];
            }
            const winMargins = [];
            if (!empty(this.headToHeadData.team1Stats.bestWinMargin)) {
                winMargins.push(this.headToHeadData.team1Stats.bestWinMargin);
            }
            if (!empty(this.headToHeadData.team2Stats.bestWinMargin)) {
                winMargins.push(this.headToHeadData.team2Stats.bestWinMargin);
            }

            return winMargins;
        },
        team1Name() {
            return !empty(this.match) ? this.match.getTeamOneName() : null;
        },
        team2Name() {
            return !empty(this.match) ? this.match.getTeamTwoName() : null;
        },
        team1Logo() {
            return !empty(this.match) ? this.match.getImgPathTeamOne() : null;
        },
        team2Logo() {
            return !empty(this.match) ? this.match.getImgPathTeamTwo() : null;
        }
    },
    mounted() {
        this.checkBreakpoint();
        window.addEventListener('resize', this.checkBreakpoint);
        const style = document.createElement('style');
        style.innerHTML = `
            @media print {
                @page {
                    size: landscape;
                }
            }
        `;
        document.head.appendChild(style);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkBreakpoint);
        const styles = document.querySelectorAll('style');
        styles.forEach((s) => {
            if (s.innerHTML.includes('size: landscape')){
                s.remove();
            }
        })
    },
}
</script>

import Model from '@/models/model.js';

/**
 * match tournament model class
 */
export default class MatchTournamentModel extends Model {
    /**
     * group name
     *
     * @type {string}
     */
    groupName = '';

    /**
     * match number
     *
     * @type {number}
     */
    matchNumber = '';

    /**
     * tournament stage source eq id
     *
     * @type {string}
     */
    tournamentStageSourceEqId = '';

    /**
     * tournament
     *
     * @type {TournamentModel}
     */
    tournament = {};

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.id = data.id || data.matchTournamentId || 0;
            this.endDate = this.toDate(data.endDate);
            this.groupName = data.groupName || data.group || '';
            this.logoUrl = data.logoUrl || '';
            this.matchId = data.matchId || 0;
            this.matchNumber = data.matchNumber || 0;
            this.name = data.title || data.tournamentName || '';
            this.sport = data.sport || '';
            this.startDate = this.toDate(data.startDate);
            this.tournamentEqId = data.tournamentEqId || '';
            this.tournamentId = data.tournamentId || 0;
            this.tournamentStageName = data.tournamentStageName || '';
            this.tournamentStageEqId = data.tournamentStageEqId || '';
            this.tournamentTypeName = data.tournamentTypeName || '';
            this.tournamentTypeEqId = data.tournamentTypeEqId || '';
            this.year = data.year || '';

            this.logoStorage = data.logoUrl || '';
            this.logo = Model._api.call.tournament.resolveStoragePath(this.logoStorage) || null;
        }
    }

    setLogoStorage(logoStorage){
        this.logoStorage = logoStorage;
        this.logo = Model._api.call.tournament.resolveStoragePath(this.logoStorage);
    }

    /**
     * get tournament image path
     *
     * @returns {string}
     */
    getImgPath(){
        return this.logo || require('@/assets/img/tournament.svg');
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return this.name;
    }

    toJSON() {
        return {
            matchId: this.matchId,
            tournamentEqId: this.tournamentEqId,
            matchNumber: this.matchNumber || null,
            group: this.groupName || null,
            tournamentStageEqId: this.tournamentStageEqId || null,
        };
    }
}

export class MatchTournamentModelWithSeries extends MatchTournamentModel {
    /**
     * series id
     *
     * @type {number}
     */
    seriesId = null;

    /**
     * series EqId
     *
     * @type {string}
     */
    seriesEqId = '';

    /**
     * series id
     *
     * @type {number}
     */
    seriesTypeId = null;

    constructor(data = {}){
        super(data);

        if(data){
            this.seriesId = data.seriesId || null;
            this.seriesTypeId = data.seriesTypeId || null;
            this.seriesEqId = data.seriesEqId || null;
        }
    }
}

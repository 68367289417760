<template>
    <div class="pageContent">
        <actionButton
            variant="outline-primary"
            class="btn-flat mb-5"
            v-bind:to="backToTournamentDetailsLink"
        >
            <icon type="arrow-left" class="align-middle"/> {{ $t('backToPreviousScreen') }}
        </actionButton>
        <simpleHeader v-bind:title="'Under 19 Players'">
            <template v-slot:title>
                <div class="d-flex flex-row w-100 justify-content-between">
                    <div>
                        <h1>{{ $t('under19Players')}}</h1>
                    </div>
                    <div>
                        <actionButton variant="outline-primary" class="btn-flat" v-on:click="printSegment()">
                            {{ $t('print') }}
                        </actionButton>
                    </div>
                </div>
            </template>
            <div class="w-100 pt-3 d-flex justify-content-center">
                <div class="d-flex align-items-center gap-15">
                    <img
                        v-bind:src="logo"
                        v-bind:alt="tournamentData.tournamentName"
                        v-bind:height="logoNotProvided ? 40 : 80"
                    />
                    <h2>{{ tournamentData.tournamentName }}</h2>
                </div>
            </div>
        </simpleHeader>

        <wrapper v-bind:waitFor="apiData.personData" v-bind:waitForRetry="reloadTournamentPersonData"
            noPadding noLine noShadow waitSilent>
            <under19Table
                v-bind:playerData="tournamentData.persons"
                v-bind:tournamentStartDate="tournamentData.tournamentStartDate"
                v-if="tournamentData.persons.length > 0"
            />
            <div class="top col-12 d-flex justify-content-center" v-else>
                <h3>{{ $t('noUnderageFound') }}</h3>
            </div>
        </wrapper>
    </div>
</template>

<script>
import base from '@/views/base.vue';
import icon from '@/components/icon.vue';
import simpleHeader from '@/components/simpleHeader.vue';
import wrapper from '@/components/wrapper.vue';
import under19Table from '@/components/tables/under19Table.vue';
import Model from '@/models/model.js';
import { empty } from '@/utils/miscUtils';

export default {
    name: 'commonTournamentUnder19Detail',
    extends: base,
    components: {
        icon,
        simpleHeader,
        wrapper,
        under19Table
    },
    data() {
        return {
            apiData: {
                personData: this.loadTournamentPersonData(),
            },
            tournamentData: null,
            logo: null,
        }
    },
    props: {
        dataTournamentId: {
            type: Number,
            mandatory: true,
            default: null
        },
    },
    methods: {
        loadTournamentPersonData() {
            return this.$api.call.tournament.tournamentGetPersons(this.dataTournamentId, 'Player', 19)
                .then((res) => {
                    this.tournamentData = res;
                    this.logo = (!empty(this.tournamentData.tournamentLogoUrl)) ? Model._api.call.tournament.resolveStoragePath(this.tournamentData.tournamentLogoUrl) : require('@/assets/img/tournament.svg');
                })
        },
        reloadTournamentPersonData() {
            this.apiData.personData = this.loadTournamentPersonData();
        },
    },
    computed: {
        backToTournamentDetailsLink() {
            return {
                name: this.detailLinkRoute || 'commonTournamentDetail',
                params: Object.assign({
                    dataTournamentId: this.dataTournamentId,
                }, this.detailLinkParams),
            }
        },
        logoNotProvided() {
            if (empty(this.tournamentData)) return true;
            return empty(this.tournamentData?.tournamentLogoUrl);
        }
    }
}
</script>

<template>
    <list
        v-bind="listProps"
        v-bind:title="title"
        v-bind:largeTitle="largeTitle"
        v-bind:mainTitle="mainTitle"
        v-bind:items="itemsWithSelected"
        v-bind:itemsTotal="!noItemsTotal ? itemsTotal : undefined"
        v-bind:loadItems="(content || noFilters || ((!listFilterConfig || filtersInit) && (!listFilterMoreConfig || filtersMoreInit))) ? loadItems : null"
        v-bind:dataId="dataId"
        v-bind:waitFor="waitFor ? waitFor.then(itemLoadForbidden) : itemLoadForbidden"
        v-bind:waitForRetry="loadItems"
        v-bind:considerEmpty="!itemsWithSelectedVisible.length"
        v-bind:collapseEnabled="collapseEnabled"
        v-bind:collapseHidden="collapseHidden"
    >
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
            <!-- @slot All slots are passed through to list component, except for: header, default, empty -->
            <slot v-bind:name="name" v-bind="data"/>
        </template>
        <template v-slot:header>
            <validations
                v-bind:notices="notices"
                v-bind:successes="successes"
                v-bind:warnings="warnings"
                v-bind:errors="errors"
                v-if="notices.length || successes.length || warnings.length || errors.length"
            />
            <div class="filterWrapper" v-if="!noFilters && (listFilterConfig || listFilterMoreConfig)">
                <listFilter
                    v-bind:elements="listFilterConfig"
                    v-bind:elementsRaw="listFilterConfigRaw"
                    v-bind:disableElements="filterDisableElements"
                    v-bind:values="filterValues"
                    hasSearch
                    v-on:change="handleFilterChange"
                    v-on:init="handleFilterInit"
                    v-if="listFilterConfig && !filterReset"
                />
                <b-collapse
                    ref="collapse"
                    v-if="listFilterMoreConfig"
                    v-on:show="filtersMoreOpen = true"
                    v-on:hide="filtersMoreOpen = false"
                >
                    <listFilter
                        v-bind:elements="listFilterMoreConfig"
                        v-bind:elementsRaw="listFilterMoreConfigRaw"
                        v-bind:disableElements="filterDisableElements"
                        v-bind:values="filterValues"
                        hasSearch
                        v-on:change="handleFilterChange"
                        v-on:init="handleFilterInit($event, true)"
                        v-if="!filterReset"
                    />
                </b-collapse>
                <div class="toggleButtonWrapper text-center text-lg-right mb-3 mx-3 mx-lg-0" v-if="listFilterMoreConfig">
                    <b-button
                        class="toggle-button"
                        variant="outline-primary"
                        v-bind:class="{ collapsed: !filtersMoreOpen, 'not-collapsed': filtersMoreOpen }"
                        v-on:click="$refs.collapse ? $refs.collapse.toggle() : null"
                    >
                        <span class="when-open">{{ $t('viewLess') }}</span>
                        <span class="when-closed">{{ $t('viewMore') }}</span>
                    </b-button>
                </div>
            </div>
            <!-- @slot additional header content -->
            <slot name="header"/>
            <component
                v-bind:is="rowElement"
                v-bind="combinedRowProps"
                isHeadline
                v-bind:headlineConfig="headlineConfig || defaultHeadlineConfig || undefined"
                v-bind:headlineSortKey="sortKey"
                v-bind:headlineSortAsc="sortAsc"
                v-on:headlineSort="handleSort"
            />
        </template>
        <template v-slot="{ item, role, index }">
            <component
                v-bind:is="rowElement"
                v-bind="combinedRowProps"
                v-bind:[rowItemPropName]="item"
                v-bind:role="role"
                v-bind:readonly="rowReadonly"
                v-bind:detailLinkRoute="rowDetailLinkRoute"
                v-bind:updateHandler="updateItem"
                v-bind:removeHandler="removeItem"
                v-on:updated="handleUpdate($event, index)"
                v-on:removed="handleRemoved"
                v-on:select="handleRowSelect"
                v-bind:bodyClass="{
                    'bg-secondary': (isSelected(item) && isDisplayed(item)),
                    'border': true,
                    'border-transparent': !isSelected(item),
                    'border-secondary': isSelected(item),
                    'rounded': isSelected(item),
                    'o-50': !isDisplayed(item)
                }"
                v-show="!item.id || !hiddenItemIds.includes(item.id)"
            />
        </template>
        <template v-slot:empty>
            <div class="mt-2 text-center">{{ $t('noFilterResult') }}</div>
        </template>
    </list>
</template>

<script>
import validations from '@/components/validations.vue';
import list from '@/components/list.vue';
import listFilter from '@/components/forms/listFilter.vue';
import { RequestError } from '@/errors.js';
import PermissionModel from '@/models/permission.js';
import Model from '@/models/model.js';

const abstractMethodError = 'abstract method';

/**
 * complex list component
 *
 * @author Thomas Haberzettl <t.haberzettl@sportradar.com>
 */
export default {
    name: 'itemList',
    components: {
        validations,
        list,
        listFilter,
    },
    props: {
        /**
         * list title
         */
        title: {
            type: String,
            required: false,
            default: '',
        },
        /**
         * list title shown as large variant
         */
        largeTitle: {
            type: Boolean,
            default: false,
        },
        /**
         * list title is page main title (h1)
         */
        mainTitle: {
            type: Boolean,
            default: false,
        },
        /**
         * use defined items for the list content
         */
        content: {
            type: Array,
            required: false,
            default: null,
        },
        /**
         * disable pagination
         */
        noPagination: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * only show first pagination page
         */
        paginationSinglePage: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * list component props
         */
        listProps: {
            type: Object,
            required: false,
            default(){
                return {};
            },
        },
        /**
         * row component props
         */
        rowProps: {
            type: Object,
            required: false,
            default(){
                return {};
            },
        },
        /**
         * row permission for edit button
         */
        rowEditPermission: {
            type: PermissionModel,
            default: null,
            required: false,
        },
        /**
         * row permission for remove button
         */
        rowRemovePermission: {
            type: PermissionModel,
            default: null,
            required: false,
        },
        /**
         * set readonly prop on rows
         */
        rowReadonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * list of notice messages for validations component
         */
        notices: {
            type: Array,
            required: false,
            default(){
                return [];
            }
        },
        /**
         * list of success messages for validations component
         */
        successes: {
            type: Array,
            required: false,
            default(){
                return [];
            }
        },
        /**
         * list of warning messages for validations component
         */
        warnings: {
            type: Array,
            required: false,
            default(){
                return [];
            }
        },
        /**
         * list of error messages for validations component
         */
        errors: {
            type: Array,
            required: false,
            default(){
                return [];
            }
        },
        /**
         * headline row config
         * @see baseRow component
         */
        headlineConfig: {
            type: Object,
            required: false,
            default: null,
        },
        /**
         * hide filters
         */
        noFilters: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * toggle which filters should be displayed or not
         */
        filterDisplay: {
            type: Object,
            required: false,
            default: null,
        },
        /**
         * initial filter values
         */
        filterValues: {
            type: Object,
            required: false,
            default: null,
        },
        /**
         * list of filter element ids to disable
         */
        filterDisableElements: {
            type: Object,
            required: false,
            default: null,
        },
        /**
         * items to hide (id property is used for comparison)
         */
        hideItems: {
            type: Array,
            required: false,
            default: null,
        },
        /**
         * arrow link rut name
         */
        arrowLinkRoute: {
            type: String,
            default: null,
        },
        /**
         * promise to wait for before loading list items
         */
        waitFor: {
            type: Promise,
            required: false,
            default: null,
        },
        /**
         * enable the option to collapse everything below the headline
         */
        collapseEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * change default collapse state to hidden
         */
        collapseHidden: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * page size limit overwrite
         */
        pageSizeLimit: {
            type: Number,
            required: false,
            default: null,
        },
        /**
         * do not show item total count
         */
        noItemsTotal: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data(){
        return {
            //base properties
            items: [],
            itemsTotal: 0,
            selected: [],
            filters: {},
            filtersInit: false,
            filtersMoreInit: false,
            filtersMoreOpen: false,
            filterDebounce: null,
            filterDebounceTime: 500,
            filterReset: false,
            dataId: 1,
            pageSizeMax: 100,
            itemLoadForbidden: null,
            //feel free to overwrite
            rowElement: '',
            rowItemPropName: '',
            rowDetailLinkRoute: null,
            selectItemProp: '',
            pageSize: this.pageSizeLimit || 20,
            sortKey: '',
            sortAsc: true,
        };
    },
    computed: {
        defaultRowProps(){
            return null;
        },
        combinedRowProps(){
            return Object.assign({
                editPermission: this.rowEditPermission,
                removePermission: this.rowRemovePermission,
            }, this.defaultRowProps, this.rowProps);
        },
        listFilterConfig(){
            return null;
        },
        listFilterConfigRaw(){
            return false;
        },
        listFilterMoreConfig(){
            return null;
        },
        listFilterMoreConfigRaw(){
            return false;
        },
        filterDisplayDefault(){
            return null;
        },
        defaultHeadlineConfig(){
            return null;
        },
        itemsWithSelected(){
            return this.selected.filter(item => !this.isDisplayed(item)).concat(this.items);
        },
        itemsWithSelectedVisible(){
            if(!this.hiddenItemIds.length){
                return this.itemsWithSelected;
            }

            return this.itemsWithSelected.filter(item => !item.id || !this.hiddenItemIds.includes(item.id));
        },
        hiddenItemIds(){
            if(!this.hideItems){
                return [];
            }

            return this.hideItems.map(item => item.id);
        },
    },
    methods: {
        resetBasics(){
            this.filters = {};
            this.filtersInit = false;
            this.filtersMoreInit = false;
            this.filtersMoreOpen = false;
            this.dataId++;

            if(this.filterDebounce){
                window.clearTimeout(this.filterDebounce);
                this.filterDebounce = null;
            }
        },
        resetItems(){
            this.items = [];
            this.itemsTotal = 0;

            //to force infinity load to run a new loading we simply change the data version id
            this.dataId++;
        },
        resetSelected(){
            this.selected = [];
        },
        resetFilters(){
            this.filterReset = true;

            this.filters = {};
            this.filtersInit = false;
            this.filtersMoreInit = false;

            this.$nextTick(() => {
                this.filterReset = false;
            });
        },
        reload(resetFilters = false){
            if(resetFilters){
                this.resetFilters();
            }

            this.resetItems();
            this.resetSelected();
        },
        handleUpdate(data, index){
            if(!data){
                return;
            }

            //get item from event information. use event target (updateHandler result) if it is a model.
            let item = data[this.selectItemProp];
            if(data.event && data.event.result instanceof Model){
                item = data.event.result;
            }
            if(!item){
                return;
            }

            //update list item
            this.$set(this.items, index, item);
        },
        handleRowSelect(data){
            if(!data || !this.selectItemProp){
                return;
            }

            //get item from event information
            const item = data[this.selectItemProp];
            if(!item){
                return;
            }

            //add/remove item to/from selected list
            this.toggleSelect(item);

            /**
             * Item(s) have been selected
             *
             * @param {Model[]} selected
             */
            this.$emit('select', this.selected);
        },
        handleRemoved(data){
            this.resetItems();
            this.$emit('removed', data);
        },
        handleFilterChange(data){
            this.filters = Object.assign({}, this.filters, data);
        },
        handleFilterInit(data, more){
            this.filters = Object.assign({}, this.filters, data);

            this.$nextTick(() => {
                if(more){
                    this.filtersMoreInit = true;
                }
                else {
                    this.filtersInit = true;
                }
            });
        },
        handleSort(sortKey, sortAsc){
            if(this.sortKey === sortKey && this.sortAsc === sortAsc){
                return;
            }

            this.sortKey = sortKey;
            this.sortAsc = sortAsc;

            this.resetItems();
        },
        loadItems(){
            //calc current page number based on current list array length
            const pageSize = (this.noPagination ? null : this.pageSize);
            const pageIndex = (pageSize ? Math.ceil(this.items.length / pageSize) : null);

            //make a copy of the current data id to check against when we got the results
            let dataId = this.dataId;

            //if waitFor prop is given, wait for it before loading items
            let waitFor = Promise.resolve();
            if(this.waitFor instanceof Promise){
                waitFor = this.waitFor.then(() => {
                    //in this case update our copy of data id after the wait
                    dataId = this.dataId;
                });
            }

            //request data from api with page index and size
            const promise = waitFor.then(() => (this.content ? this.requestContent : this.requestItems)(this.filters, pageIndex, pageSize, this.sortKey, this.sortAsc)).then(result => {
                //ignore results for requests of old data id
                if(dataId !== this.dataId){
                    throw Error('old request');
                }

                //reset load forbidden error
                this.itemLoadForbidden = null;

                //if problem occurs, throw error
                if(!result.items){
                    throw Error('no data from feed');
                }

                //if no further items can be loaded, resolve promise with false to indicate so
                if(result.items.length === 0){
                    return false;
                }

                //add items to array
                this.items.push(...result.items);

                //if there is nothing more to load, return false to indicate so
                if(result.total){
                    this.itemsTotal = result.total;

                    if(this.items.length >= result.total){
                        return false;
                    }
                }

                //when pagination is disabled or single page, treat a successful result as final
                if(this.noPagination || this.paginationSinglePage){
                    return false;
                }

                //resolve promise with true if there is more to load
                return true;
            }, err => {
                //if request fails with 404 error, interpret as no (more) results found. if 403 forbidden error show error
                if(err instanceof RequestError){
                    switch(err.status){
                        case 404:
                            return false;

                        case 403:
                            this.itemLoadForbidden = promise;
                    }
                }

                throw err;
            });

            return promise;
        },
        requestItems(filters, pageIndex, pageSize, sortKey, sortAsc){
            //overwrite me
            return Promise.reject(Error(abstractMethodError));
        },
        requestContent(filters, pageIndex, pageSize, sortKey, sortAsc){
            const items = this.sortItems(this.filterItems(this.content, this.filters), sortKey, sortAsc);

            return Promise.resolve({
                items: (pageSize ? items.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize) : items),
                total: items.length,
            });
        },
        updateItem(item){
            return this.requestItemUpdate(item).catch(err => {
                if(this.$log){
                    this.$log.error(err);
                }

                throw err;
            });
        },
        removeItem(item){
            return this.requestItemRemove(item).catch(err => {
                if(this.$log){
                    this.$log.error(err);
                }

                throw err;
            });
        },
        requestItemUpdate(item){
            //overwrite me
            return Promise.reject(Error(abstractMethodError));
        },
        requestItemRemove(item){
            //overwrite me
            return Promise.reject(Error(abstractMethodError));
        },
        toggleSelect(item){
            const index = this.isSelected(item, true);

            if(index !== -1){
                this.selected.splice(index, 1);
            }
            else {
                this.selected.push(item);
            }
        },
        isSelected(item, getIndex = false){
            //use item id to check if it is in the current selected list
            return this.selected.map(item => item.id)[getIndex ? 'indexOf' : 'includes'](item.id);
        },
        isDisplayed(item, getIndex = false){
            //use item id to check if it is in the current item list
            return this.items.map(item => item.id)[getIndex ? 'indexOf' : 'includes'](item.id);
        },
        filterItems(items, filters){
            return items.filter(item => {
                for(const key in filters){
                    if(Object.prototype.hasOwnProperty.call(filters, key) && filters[key]){
                        switch(typeof item[key]){
                            case 'string':
                                //for strings check if it contains it
                                if(!item[key].toLowerCase().includes(('' + filters[key]).toLowerCase())){
                                    return false;
                                }
                                break;

                            default:
                                //convert to strings and compare directly
                                if('' + item[key] !== '' + filters[key]){
                                    return false;
                                }
                                break;
                        }
                    }
                }

                return true;
            });
        },
        sortItems(items, sortKey, sortAsc = true, pageIndex = null, pageSize = null){
            if(!items || !sortKey){
                return items;
            }

            items.sort((itemA, itemB) => {
                let valueA = itemA[sortKey];
                let valueB = itemB[sortKey];

                if(valueA === undefined || valueB === undefined){
                    return 0;
                }

                //if function, get result
                if(typeof valueA === 'function'){
                    valueA = valueA.call(itemA);
                }
                if(typeof valueB === 'function'){
                    valueB = valueB.call(itemB);
                }

                //if string, transform to lowercase for comparison
                if(typeof valueA === 'string'){
                    valueA = valueA.toLowerCase();
                }
                if(typeof valueB === 'string'){
                    valueB = valueB.toLowerCase();
                }

                //compare
                if(valueA < valueB){
                    return (sortAsc ? -1 : 1);
                }
                if(valueA > valueB){
                    return (sortAsc ? 1 : -1);
                }
                return 0;
            });

            //enforce pagination if given
            if(pageIndex !== null && pageIndex >= 0 && pageSize > 0){
                items = items.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);
            }

            return items;
        },
        createFilterRow(elements = [], dependency = null, hidden = null){
            return {
                type: 'row',
                props: {
                    cols: 2,
                    'cols-md': 2,
                    'cols-lg': 3,
                },
                cols: elements.map(element => ({
                    elements: [element],
                    dependency: element.dependency,
                    hidden: element.hidden,
                })),
                dependency: dependency,
                hidden: hidden,
            };
        },
        usePagination(pageIndex, pageSize, sortKey = null){
            //if no pagination, load as much as possible (otherwise API will limit to 20 by default)
            if(!pageSize){
                pageIndex = 0;
                pageSize = this.pageSizeMax;
            }

            return {
                pageIndex: (sortKey ? 0 : pageIndex),
                pageSize: (sortKey ? this.pageSizeMax : pageSize),
            };
        },
        filterDisplayCheck(filters){
            const filterDisplay = Object.assign({}, this.filterDisplayDefault, this.filterDisplay);
            return filters.filter(config => (!config.id || filterDisplay[config.id] !== false));
        },
        fromDate(date, nullable = true){
            //detect empty input
            if(nullable && !date){
                return null;
            }

            //needs to be date
            if(!(date instanceof Date)){
                return null;
            }

            //convert to UTC datetime string
            return (new Date(date.getTime() - date.getTimezoneOffset() * 60000)).toJSON();
        }
    },
    watch: {
        filters: {
            deep: true,
            handler(filters, filtersBefore){
                //if filter has been initialised and does change
                if(this.noFilters || (this.listFilterConfig && !this.filtersInit) || (this.listFilterMoreConfig && !this.filtersMoreInit) || JSON.stringify(filters) === JSON.stringify(filtersBefore)){
                    return;
                }

                //stop previous debounce timer
                if(this.filterDebounce){
                    window.clearTimeout(this.filterDebounce);
                }

                //start debounce timer
                this.filterDebounce = window.setTimeout(() => {
                    //then force
                    this.resetItems();
                    this.filterDebounce = null;
                }, this.filterDebounceTime);
            },
        },
        items(items){
            /**
             * Item list changed
             *
             * @param {Model[]} items
             */
            this.$emit('items', items);

            /**
             * Item count changed
             *
             * @param {number} itemCount
             */
            this.$emit('itemCount', items.length);
        },
        itemsTotal(total){
            /**
             * Total item list number changed
             *
             * @param {number} itemTotal
             */
            this.$emit('itemTotal', total);
        },
        content(){
            this.resetItems();
        },
    },
};
</script>

import Model from '@/models/model.js';

export default class BestPerformerPlayer extends Model {
    matchId = null;

    constructor(data = {}) {
        super(data);
        if (data) {
            this.matchId = data.matchId || null;
            this.winningTeamName = data.winningTeamName || '';
            this.winningTeamScore = data.winningTeamScore || 0;
            this.losingTeamName = data.losingTeamName || '';
            this.losingTeamScore = data.losingTeamScore || 0;
            this.venueName = data.venueName || '';
            this.locationName = data.locationName || '';
            this.matchKickOff = data.matchKickOff || '';
            this.personRoleEqId = data.personRoleEqId || '';
            this.personEqId = data.personEqId || null;
            this.displayName = data.displayName || '';
            this.teamEqId = data.teamEqId || null;
            this.teamName = data.teamName || '';
            this.countryCode = data.countryCode || '';
            this.numTries = data.numTries || 0;
            this.numConversions = data.numConversions || 0;
            this.numPenalties = data.numPenalties || 0;
            this.numDropGoals = data.numDropGoals || 0;
            this.totalPoints = data.totalPoints || 0;
            this.totalGoals = data.totalGoals || 0;
            this.year = data.year || null;
        }
    }
}

'use strict';

import '@babel/polyfill';
import 'mutationobserver-shim';

import Vue from 'vue';
import router from './router/index.js';
import store from './store/index.js';
import { initRouterGuards } from './router/guards.js';

import './services/hooks.js';
import Api from './services/api.js';
import Log from './services/log.js';
import Auth from './services/auth.js';

import './plugins/bootstrap-vue';
import i18n from './plugins/i18n.js';

import infiniteLoading from 'vue-infinite-loading';
import throbber from '@/components/throbber.vue';
import loadErrorSnippet from '@/components/snippets/loadError.vue';

import App from './App.vue';
import Model from '@/models/model.js';

import { setupTracking } from './tracking.js';

Vue.config.productionTip = false;

const config = window.wrConfig || {
    apiUrl: process.env.VUE_APP_API_URL || '',
    authUrl: process.env.VUE_APP_AUTH_URL || '',
    authClientId: process.env.VUE_APP_AUTH_CLIENT_ID || '',
    authClientSecret: process.env.VUE_APP_AUTH_CLIENT_SECRET || '',
    matchManagerUrl: process.env.VUE_APP_MATCH_MANAGER_URL || '',
    logLevel: parseInt(process.env.VUE_APP_LOG_LEVEL, 10) || 0,
    zendeskEnabled: process.env.VUE_APP_ZENDESK_ENABLED === 'true',
};

const log = new Log(config.logLevel);
const auth = new Auth(config.authUrl, config.authClientId, config.authClientSecret, store, log);
const api = new Api(config.apiUrl, auth);
auth.setApi(api);

Model.setApi(api);
Model.setLog(log);

// TODO: Can get rid of these at some stage, just for testing
log.always('sc full sha: ' + (window.wrConfig || { scShaIfGiven: 'None' }).scShaIfGiven);
log.always('target environment: ' + (window.wrConfig || { targetEnvironment: 'development' }).targetEnvironment);

setupTracking(config, log);

initRouterGuards(router, store, auth, log);

Vue.use(infiniteLoading, {
    slots: {
        noResults: '',
        noMore: '',
        spinner: throbber,
        error: loadErrorSnippet,
    },
});

/**
 * init main vue instance
 */
new Vue({
    router,
    store,
    storage: window.localStorage,
    config: {
        matchManagerUrl: config.matchManagerUrl,
    },
    api,
    log,
    auth,
    i18n,
    render: h => h(App),
}).$mount('#app');

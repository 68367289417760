import { empty } from '@/utils/miscUtils.js';
import { formatDate as dateFormat } from '@/utils/dateUtils.js';
import constants from '@/constants';


const formatPlayerName = (playerName, playerCountryCode) => {
    if (empty(playerName)) {
        return '';
    }

    if (empty(playerCountryCode)) {
        return playerName;
    }

    return `${playerName} (${playerCountryCode})`;
}

const formatScore = (winningScore, losingScore) => {
    if (winningScore == null && losingScore == null) {
        return ` ${constants.enDash} `;
    }

    return `${winningScore} ${constants.enDash} ${losingScore}`;
}

const formatTeams = (leadingText, secondText) => {
    if (empty(leadingText) && empty(secondText)) {
        return '';
    }
    return `${leadingText} v ${secondText}`;
}

const formatYear = (kickOff) => {
    return dateFormat(kickOff, 'YYYY');
}

const formatDate = (date) => {
    return dateFormat(date, 'DD MMM');
}

const formatTournamentStage = (tournamentStage, tournamentStageGroup) => {
    if (empty(tournamentStage)) {
        return '';
    }

    if (empty(tournamentStageGroup)) {
        return tournamentStage;
    }

    return `${tournamentStage} ${tournamentStageGroup}`;
}

export default {
    formatPlayerName,
    formatScore,
    formatTeams,
    formatYear,
    formatDate,
    formatTournamentStage
};

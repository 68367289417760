<template>
    <b-table-simple
        table-class="m-0 sevens-teamsheet-table"
        responsive hover small caption-top
    >
        <b-thead>
            <b-tr>
                <b-th class="table-top-header tth-large-txt text-center">
                    <img v-bind:src="logoUrl" height="20" width="20" v-if="teamLogoUrl" />
                </b-th>
                <b-th class="table-top-header tth-large-txt text-uppercase">
                    {{ teamName }}
                </b-th>
                <b-th class="table-top-header"></b-th>
                <b-th class="table-top-header text-uppercase statsdata-7s text-center tth-med-txt tth-vertical-middle">Age</b-th>
                <b-th class="table-top-header text-uppercase statsdata-7s text-center tth-med-txt tth-vertical-middle">Try</b-th>
                <b-th class="table-top-header text-uppercase statsdata-7s text-center tth-med-txt tth-vertical-middle">Pts</b-th>
                <b-th class="table-top-header text-uppercase statsdata-7s text-center tth-med-txt tth-vertical-middle">Con</b-th>
                <b-th class="table-top-header text-uppercase statsdata-7s text-center tth-med-txt tth-vertical-middle">Crd</b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr id="shade-table-7s" class="data-row-7s" v-for="(item, i) in items" v-bind:key="`${i}-${item.name}`">
                <b-td class="data-7s text-center">{{ item.number }}</b-td>
                <b-td class="data-7s">{{ item.name }}</b-td>
                <b-td class="data-7s">
                    <div
                        class="d-flex justify-content-center"
                        v-bind:class="{ replacement: item.position === 'R'}"
                    >
                        {{ item.position }}
                    </div>
                </b-td>
                <b-td class="data-7s text-center">{{ item.age }}</b-td>
                <b-td class="data-7s text-center">{{ item.tries }}</b-td>
                <b-td class="data-7s text-center">{{ item.points }}</b-td>
                <b-td class="data-7s text-center">{{ item.conversions }}</b-td>
                <b-td class="data-7s text-center">
                    <div class="h-100 d-flex flex-column justify-content-center">
                        <icon v-bind:type="'card-' + item.card" class="card-icon-7s" v-if="item.card" />
                    </div>
                </b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
import icon from '@/components/icon.vue';
import { calculateAge } from '@/utils/dateUtils';
import { empty } from '@/utils/miscUtils';
import Model from '@/models/model.js';

export default {
    name: 'teamsheetTable7s',
    components: {
        icon
    },
    props: {
        players: {
            type: Array,
            required: true,
        },
        isSub: {
            type: Boolean,
            required: false,
            default: false,
        },
        scoreData: {
            type: Object,
            required: false
        },
        disciplinaries: {
            type: Object,
            required: false
        },
        matchKickOffDate: {
            type: Date,
            required: true
        },
        teamName: {
            type: String,
            required: true,
        },
        teamLogoUrl: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'number',
                    label: '',
                    class: 'text-center',
                },
                {
                    key: 'name',
                    label: `${this.teamName}`,
                    thClass: 'table-team-text',
                },
                {
                    key: 'position',
                    label: '',
                    tdClass: 'position'
                },
                {
                    key: 'age',
                    label: 'Age',
                    tdClass: 'text-center',
                    thClass: 'text-center table-header-text'
                },
                {
                    key: 'tries',
                    label: 'Try',
                    tdClass: 'text-center',
                    thClass: 'text-center table-header-text'
                },
                {
                    key: 'points',
                    label: 'Pts',
                    tdClass: 'text-center',
                    thClass: 'text-center table-header-text'
                },
                {
                    key: 'conversions',
                    label: 'Con',
                    tdClass: 'text-center',
                    thClass: 'text-center table-header-text'
                },
                {
                    key: 'card',
                    label: 'Crd',
                    tdClass: 'match-teamsheet-icon',
                    thClass: ' table-header-text'
                },
            ],
        }
    },
    methods: {
        formatTableData(players, isSub = false) {
            const map = {};
            for (let player of players) {
                const number = parseInt(player.positionNumber) || this.getDisplayNumber(player);
                if (!number) {
                    continue;
                }

                map[number] = player;
            }
            let formattedPlayers = Object.entries(map).sort(([number1], [number2]) => number1 - number2).map(([key, player]) => player)
                .map((data) => {
                    const playerEqId = data?.eqId;
                    const playerScoreData = this.scoreData[playerEqId];
                    return {
                        number: typeof data?.positionNumber === 'number' ? parseInt(data?.positionNumber) : this.getDisplayNumber(data),
                        name: this.formatName(data),
                        age: calculateAge(data.dateOfBirth, this.matchKickOffDate),
                        conversions: (playerEqId && !empty(playerScoreData)) ? playerScoreData.conversions : 0,
                        tries: (playerEqId && !empty(playerScoreData)) ? playerScoreData.tries : 0,
                        points: (playerEqId && !empty(playerScoreData)) ? playerScoreData.points : 0,
                        position: this.getPositionLetter(data),
                        card: ((playerEqId && this.disciplinaries[playerEqId]) ? this.disciplinaries[playerEqId].cardType : null),
                        replacement: isSub ? data?.getPlayerAttribute('isUsedReplacement') : data.getPlayerAttribute('isReplaced'),
                    }
                });
            return formattedPlayers;
        },
        formatName(teamsheet) {
            if (!teamsheet) {
                return '';
            }

            let name = teamsheet.getName();
            if(teamsheet?.getPlayerAttribute('isCaptain')) {
                name += ' (C)';
            }
            return name;
        },
        getDisplayNumber(person) {
            if (person && (person.getPlayerAttribute('shirtNumber') || person.getPlayerAttribute('squadNumber'))) {
                return '' + (person.getPlayerAttribute('shirtNumber') || person.getPlayerAttribute('squadNumber') || '');
            }

            if (person?.number) {
                return '' + person.number;
            }

            return '';
        },
        getPositionLetter(person) {
            const position = person.positionNumber;
            switch(position) {
                case 'Fwd':
                    return 'F';
                case 'Bck':
                    return 'B';
                case 'Rep':
                    return 'R';
                default:
                    return '';
            }
        }
    },
    computed: {
        items() {
            return this.formatTableData(this.players, this.isSub);
        },
        logoUrl() {
            if (!empty(this.teamLogoUrl)) {
                return Model._api.call.union.resolveStoragePath(this.teamLogoUrl);
            }
            return null;
        }
    }
}
</script>

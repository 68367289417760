import Model from '@/models/model.js';

export default class BestPerformerInGameTeamStat extends Model {
    matchId = null;

    constructor(data = {}) {
        super(data);

        if (data) {
            this.matchId = data.matchId || null;
            this.winningTeamEqId = data.winningTeamEqId || '';
            this.winningTeamScore = data.winningTeamScore || 0;
            this.winningTeamName = data.winningTeamName || '';
            this.losingTeamEqId = data.losingTeamEqId || '';
            this.losingTeamScore = data.losingTeamScore || 0;
            this.losingTeamName = data.losingTeamName || '';
            this.scoreDiff = data.scoreDiff || null;
            this.aggregateScore = data.aggregateScore || null;
            this.matchKickOff = data.matchKickOff || null;
            this.city = data.city || '';
            this.venue = data.venue || '';
            this.tournamentStage = data.tournamentStage || '';
            this.tournamentStageGroup = data.tournamentStageGroup || '';
        }
    }
}

import Model from '@/models/model.js';
import constants from '@/constants';

/**
 * disciplinary model class
 */
export default class DisciplinaryModel extends Model {
    /**
     * id
     *
     * @type {int}
     */
    id = 0;

    /**
     * class constructor
     *
     * @param {object} [data = {}]
     */
    constructor(data = {}){
        super(data);

        if(data){
            this.actionTypeEqId = data.actionTypeEqId || '';
            this.actionTypeId = data.actionTypeId || 0;
            this.actionTypeGroupId = data.actionTypeGroupId || 0;
            this.actionTypeName = data.actionTypeName || '';
            this.appealsOfficer = data.appealsOfficer || data.appealsOfficerDisplayName || '';
            this.appealsOfficerPersonRoleEqId = data.appealsOfficerPersonRoleEqId || '';
            this.appealsOfficerPersonRoleId = data.appealsOfficerPersonRoleId || 0;
            this.citing = data.citing || '';
            this.citingCommissioner = data.citingCommissioner || data.citingCommissionerDisplayName || '';
            this.citingCommissionerPersonRoleEqId = data.citingCommissionerPersonRoleEqId || '';
            this.citingCommissionerPersonRoleId = data.citingCommissionerPersonRoleId || 0;
            this.eqId = data.eqId || data.matchTimelineEqId || '';
            this.id = data.id || data.matchTimelineId || 0;
            this.internalComments = data.internalComments || '';
            this.judicialOfficer = data.judicialOfficer || data.judicialOfficerDisplayName || '';
            this.judicialOfficerPersonRoleEqId = data.judicialOfficerPersonRoleEqId || '';
            this.judicialOfficerPersonRoleId = data.judicialOfficerPersonRoleId || 0;
            this.law = data.lawDescription || '';
            this.lawEqId = data.lawEqId || '';
            this.lawId = data.lawId || 0;
            this.matchEqId = data.matchEqId || '';
            this.matchId = data.matchId || 0;
            this.matchStatus = data.matchStatusName || '';
            this.matchStatusEqId = data.matchStatusEqId || '';
            this.matchStatusId = data.matchStatusId || 0;
            this.matchTeamEqId = data.matchTeamEqId || '';
            this.matchTeamId = data.matchTeamId || 0;
            this.matchTeamName = data.matchTeamName || '';
            this.matchTimelineDisciplinaryEqId = data.matchTimelineDisciplinaryEqId || '';
            this.matchTimelineDisciplinaryId = data.matchTimelineDisciplinaryId || 0;
            this.matchTimelineEqId = data.matchTimelineEqId || '';
            this.matchTimelineId = data.matchTimelineId || 0;
            this.offence = data.offence || '';
            this.playerEqId = data.matchTeamPlayerEqId || '';
            this.playerId = data.matchTeamPlayerId || 0;
            this.playerName = data.matchTeamPlayerDisplayName || '';
            this.referee = data.refereeDisplayName || '';
            this.refereePersonRoleEqId = data.refereePersonRoleEqId || '';
            this.timeInHalf = data.timeInHalf || data.time || 0;
            this.version = data.version ? BigInt(data.version) : 0 || 0;

            if (data.matchTimelineDisciplinary) {
                this.updateMatchTimelineDisciplinaryValues(data);
            }
        }
    }

    updateMatchTimelineDisciplinaryValues(data){
        this.matchTimelineDisciplinaryId = data.matchTimelineDisciplinary.id || 0;
        this.matchTimelineDisciplinaryEqId = data.matchTimelineDisciplinary.eqId || '';
        this.citing = data.matchTimelineDisciplinary.citing || '';
        this.internalComments = data.matchTimelineDisciplinary.internalComments || '';
        this.lawId = data.matchTimelineDisciplinary.lawId || 0;
        this.offence = data.matchTimelineDisciplinary.offence || '';
    }

    getActionTypeGroupByName(actionTypeGroupName) {
        for (var key in constants.actionTypeGroups) {
            if (constants.actionTypeGroups[key] === actionTypeGroupName) {
                return Number(key);
            }
        }
    }

    toJSON(){
        if (isNaN(this.timeInHalf)) {
            throw TypeError('Time in half must be a number');
        }
        return {
            actionTypeId: this.actionTypeId,
            actionTypeGroupId: this.actionTypeGroupId,
            appealsOfficerPersonRoleEqId: this.appealsOfficerPersonRoleEqId || this.appealsOfficer || null,
            citing: this.citing,
            citingCommissionerPersonRoleEqId: this.citingCommissionerPersonRoleEqId || this.citingCommissioner || null,
            internalComments: this.internalComments,
            judicialOfficerPersonRoleEqId: this.judicialOfficerPersonRoleEqId || this.judicialOfficer || null,
            lawId: this.lawId,
            matchId: this.matchId,
            matchStatusId: this.matchStatusId,
            matchTeamId: this.matchTeamId,
            matchTeamPlayerId: this.playerId,
            offence: this.offence,
            time: this.timeInHalf || null,
            version: this.version,
        };
    }

    toTimelineJSON() {
        if (isNaN(this.timeInHalf)) {
            throw TypeError('Time in half must be a number');
        }
        return {
            actionTypeId: this.actionTypeId,
            actionTypeGroupId: this.getActionTypeGroupByName('Card'),
            isOutcome: true,
            isValid: true,
            matchId: this.matchId,
            matchStatusId: this.matchStatusId,
            matchTeamId: this.matchTeamId,
            matchTeamPlayerId: this.playerId,
            time: this.timeInHalf || null,
            version: this.version ? BigInt(this.version).toString() : null,
        };
    }

    toDisciplinaryJSON() {
        return {
            appealsOfficerPersonRoleEqId: this.appealsOfficerPersonRoleEqId || this.appealsOfficer || null,
            citing: this.citing,
            citingCommissionerPersonRoleEqId: this.citingCommissionerPersonRoleEqId || this.citingCommissioner || null,
            internalComments: this.internalComments,
            judicialOfficerPersonRoleEqId: this.judicialOfficerPersonRoleEqId || this.judicialOfficer || null,
            lawId: this.lawId,
            offence: this.offence,
        };
    }

    toTimelineDisciplinaryJSON() {
        const toSave = this.toTimelineJSON();
        toSave.matchTimelineDisciplinary = this.toDisciplinaryJSON();

        return toSave;
    }

    /**
     * vselect option label
     *
     * @param {vue} [vue]
     *
     * @returns {string}
     */
    label(vue = null){
        return '';
    }
}

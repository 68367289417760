<template>
    <b-table-simple hover small caption-top responsive>
        <b-thead>
            <b-tr>
                <b-th class="p-x-10 table-top-header-sm text-uppercase text-left" colspan="2">Pool {{ poolName }}</b-th>
                <b-th class="table-top-header-sm text-uppercase text-center">Pts</b-th>
                <b-th class="table-top-header-sm text-uppercase text-center">P+/-</b-th>
                <b-th class="table-top-header-sm text-uppercase text-center">T+/-</b-th>
                <b-th class="table-top-header-sm text-uppercase text-center">F</b-th>
                <b-th class="table-top-header-sm text-uppercase text-center">A</b-th>
                <b-th class="table-top-header-sm text-uppercase text-center">T</b-th>
                <b-th class="table-top-header-sm text-uppercase text-center">W</b-th>
                <b-th class="table-top-header-sm text-uppercase text-center">D</b-th>
                <b-th class="table-top-header-sm text-uppercase text-center">L</b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="(team, index) in teams" v-bind:key="team.teamName">
                <b-td class="text-center">{{ index + 1 }}</b-td>
                <b-td class="text-left">{{ team.teamName }}</b-td>
                <b-td class="text-center">{{ team.pts }}</b-td>
                <b-td class="text-center">{{ team.pd }}</b-td>
                <b-td class="text-center">{{ team.td }}</b-td>
                <b-td class="text-center">{{ team.pf }}</b-td>
                <b-td class="text-center">{{ team.pa }}</b-td>
                <b-td class="text-center">{{ team.tf }}</b-td>
                <b-td class="text-center">{{ team.w }}</b-td>
                <b-td class="text-center">{{ team.d }}</b-td>
                <b-td class="text-center">{{ team.l }}</b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>

export default {
    name: 'poolTable',
    props: {
        teams: {
            type: Array,
            required: true
        },
        poolName: {
            type: String,
            required: true
        }
    },
}
</script>

import { camelCase, isEmpty } from 'lodash';

export const pascalCase = (str) => {
    if (!str || typeof str !== 'string') {
        return str; //return the original value if not a string
    }

    const res = camelCase(str);
    return ucFirstLetter(res);
};

export const ucFirstLetter = (str) => {
    if (!str || typeof str !== 'string') {
        return str; //return the original value if not a string
    }

    const firstLetter = str.charAt(0).toUpperCase();
    return firstLetter + str.slice(1);
};

export const empty = (value) => {
    if (typeof value === 'boolean') {
        return false;
    }
    if (typeof value === 'number') {
        return value === 0 || isNaN(value);
    }

    if (value instanceof Date) {
        return isNaN(value.getTime());
    }

    if (typeof value === 'string') {
        return value.length === 0;
    }
    if (value === null || value === undefined) {
        return true;
    }
    return isEmpty(value);
};

<template>
    <b-table-simple hover small caption-top responsive>
        <b-thead>
            <b-tr>
                <b-th colspan="3"><div class="d-flex justify-content-center text-uppercase">{{ title }}</div></b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="item in items" v-bind:key="`${item.value}-${item.personEqId}`">
                <b-td>{{ item.playerName }}</b-td>
                <b-td>{{ item.value }}</b-td>
                <b-td>{{ item.country }}</b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
import { empty } from '@/utils/miscUtils.js';

export default {
    name: 'bestPerformersTable',
    components: {},
    props: {
        title: {
            type: String,
            required: true,
        },
        stats: {
            type: Array,
            required: true
        }
    },
    data() {
        return {}
    },
    methods: {
        formatData() {
            if (empty(this.stats)) {
                return;
            }
            const items = [];
            this.stats.forEach((stat) => {
                items.push({
                    playerName: stat.displayName,
                    value: stat.value,
                    country: stat.countryCode
                })
            });
            return items;
        }
    },
    computed: {
        items() {
            return this.formatData();
        }
    },
}
</script>
